import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../CommonCssFiles/planstyle.css";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { changeDateFormat, getDate } from "../helper/common";
import "./HDFC.css";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, FormLabel } from "@mui/material";
import {
  selectProposal,
  updateHDFCQuestion,
  updateHDFCPersion,
  updateHDFCQAlist,
  selectHDFCcheckedQuestion,
  selectHDFCcheckedPersion,
} from "../redux/Slice";

const search = window.location.search;
const params = new URLSearchParams(search);
const unique_id = params.get("req_id");
const plan_id = params.get("plan_id");
const c_id = params.get("c_id");

export const HDFCQuestionAnswerIndividual = ({
  data,
  index,
  insuredData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
}) => {
  // const dispatch = useDispatch();
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  const updateHDFCQuestion = ({ val }) => {
    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      if (updatedState.hasOwnProperty(val)) {
        // If the key exists, delete it
        delete updatedState[val];
      } else {
        // Otherwise, add the key with an empty object
        updatedState[val] = {};
      }

      return updatedState;
    });
  };

  console.log("HDFCcheckedQuestion", HDFCcheckedQuestion);

  return (
    <>
      <div
        className="ppv10 col-md-12 "
        style={{ paddingLeft: "0px", minWidth: "250px" }}
      >
        <div className="col-md-12 col-sm-12 col-12 pmd">
          <FormControlLabel
            value={data.question_id}
            onChange={
              (e) => {
                updateHDFCQuestion({ val: e.target.value });
              }
              // dispatch(updateHDFCQuestion({ val: e.target.value }))
            }
            control={<Checkbox />}
            label={"Q." + data.question}
            checked={HDFCcheckedQuestion.hasOwnProperty(data.question_id)}
          />
        </div>
      </div>
    </>
  );
};

export const HDFCPersionIndividual = ({
  data,
  persion,
  pindex,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
}) => {
  // const proposalData = useSelector(selectProposal);
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  const updateHDFCPerson = (payload) => {
    const { question_id, pindex } = payload; // Destructure payload
    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      // Ensure the question exists in the state
      if (!updatedState[question_id]) {
        updatedState[question_id] = {};
      }

      const question = updatedState[question_id];

      // Toggle the pindex within the question
      if (question.hasOwnProperty(pindex)) {
        delete question[pindex];
      } else {
        question[pindex] = [];
      }

      return updatedState;
    });
  };

  const check = () => {
    if (HDFCcheckedQuestion.hasOwnProperty(data.question_id)) {
      if (HDFCcheckedQuestion[data.question_id].hasOwnProperty(pindex)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const dispatch = useDispatch();

  console.log("HDFCcheckedQuestion in person", HDFCcheckedQuestion);

  let genderType = localStorage.getItem("selectedType");

  let arr;
  if (genderType == "M") {
    arr = ["Mother", "Grand Mother", "Sister", "Wife", "Mother In Law"];
  } else if (genderType == "F") {
    arr = ["Mother", "Grand Mother", "Sister", "Wife", "Mother In Law", "Self"];
  }

  return (
    <>
      {/* <div className="same-section-med ">
        <div
          hidden={!HDFCcheckedQuestion.hasOwnProperty(data.question_id)}
          onClick={(e) =>{
         
            updateHDFCPerson({
                  pindex: pindex,
                  question_id: data.question_id,
                })
          }
            // dispatch(
            //   updateHDFCPersion({
            //     pindex: pindex,
            //     question_id: data.question_id,
            //   })
            // )
          }
          style={{ minWidth: "220px" , cursor:"pointer"}}
        >
          <div
            className={
              check() ? "sumbox hdfc-sum col-md-12" : "sumbox hdfc-addon"
            }
            style={{ justifyContent: "center", minWidth: "250px" }}
          >
            <div>
              <span className="para">
                {proposerData?.InsuredDetail[pindex]?.InsuredName} (
                {persion?.InsuredRelation}){" "}
              </span>
            </div>
          </div>
        </div>
     
        {data?.get_child_question?.map((child_data) => {
          return (
            <SubQuestionAnswer
              data={child_data}
              parentData={data}
              pindex={pindex}
              proposerData={proposerData} 
               HDFCcheckedQuestion={HDFCcheckedQuestion}
               setHDFCcheckedQuestion={setHDFCcheckedQuestion}
            />
          );
        })}
      </div> */}
      <div
        className="same-section-med"
        style={{
          display:
            data.question_id == 19 &&
            c_id == "hdfc" &&
            plan_id == "Family" &&
            !arr.includes(persion?.InsuredRelation)
              ? "none"
              : 
              (
                data.question_id == 6&&
                c_id == "hdfc" &&
                plan_id == "Individual" &&
                !arr.includes(persion?.InsuredRelation)
              )
              ?"none":
              
              "block",
        }}
      >
        <div
          hidden={!HDFCcheckedQuestion.hasOwnProperty(data.question_id)}
          onClick={(e) => {
            updateHDFCPerson({
              pindex: pindex,
              question_id: data.question_id,
            });
          }}
          style={{ minWidth: "220px", cursor: "pointer" }}
        >
          <div
            className={
              check() ? "sumbox hdfc-sum col-md-12" : "sumbox hdfc-addon"
            }
            style={{ justifyContent: "center", minWidth: "250px" }}
          >
            <div>
              <span className="para">
                {proposerData?.InsuredDetail[pindex]?.InsuredName} (
                {persion?.InsuredRelation}){" "}
              </span>
            </div>
          </div>
        </div>

        {data?.get_child_question?.map((child_data) => {
          return (
            <SubQuestionAnswer
              key={child_data.question_id} // Added key for optimization
              data={child_data}
              parentData={data}
              pindex={pindex}
              proposerData={proposerData}
              HDFCcheckedQuestion={HDFCcheckedQuestion}
              setHDFCcheckedQuestion={setHDFCcheckedQuestion}
            />
          );
        })}
      </div>
    </>
  );
};

export const SubQuestionAnswer = ({
  data,
  pindex,
  parentData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
}) => {
  const proposalData = useSelector(selectProposal);
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  const dispatch = useDispatch();

  const check = () => {
    if (HDFCcheckedQuestion.hasOwnProperty(parentData.question_id)) {
      if (HDFCcheckedQuestion[parentData.question_id].hasOwnProperty(pindex)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const [first, setFirst] = useState([]);
  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  useEffect(() => {
    // console.log('HDFCcheckedQuestion:', HDFCcheckedQuestion);
    const keysToCheck = [6, 6];
    const filteredObjects = keysToCheck
      .filter((key) => HDFCcheckedQuestion.hasOwnProperty(key))
      .map((key) => HDFCcheckedQuestion[key])
      .flat();

    // console.log('Filtered Objects:', filteredObjects);
    setFirst(filteredObjects);
  }, [HDFCcheckedQuestion]);

  const getDeliveryDate = () => {
    if (first.length > 0) {
      return first[0];
    }
  };

  const datadate = getDeliveryDate();

  // console.log("HDFCcheckedQuestion", first);
  // console.log("The Qustionset HDFC",HDFCcheckedQuestion)

  let deliveryDates = [];
  for (const key in datadate) {
    if (datadate.hasOwnProperty(key)) {
      const questions = datadate[key];

      questions.forEach((question) => {
        if (question.Options && question.Options.length > 0) {
          question.Options.forEach((option) => {
            if (option.DeliveryDate) {
              deliveryDates.push(option.DeliveryDate);
            }
          });
        }
      });
    }
  }

  // Output the delivery dates
  // console.log('The delivery date',deliveryDates);

  function HDFCquestionExist(qa, qid) {
    for (let i = 0; i < qa.length; i++) {
      if (qa[i].QuestionId == qid) {
        return true;
        break;
      }
    }
    return false;
  }
  function HDFCgetAnswerSet(qdata, name, value, parentQid) {
    console.log("the parentqid in hdfc", qdata);

    console.log("qdata.question_id",qdata.question_id)

    if (parentQid == "1") {
      return {
        OptionId: qdata.question_id,
        OptionText: qdata.question,
        ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
        ConsultationDate: name == "ConsultationDate" ? value : "",
        DiagnosisDate: name == "DiagnosisDate" ? value : "",
        CurrentStatus: name == "CurrentStatus" ? value : "",
        LineOfManagement: name == "LineOfManagement" ? value : "",
        TreatmentDetails: name == "TreatmentDetails" ? value : "",
      };
    }
    // else if (parentQid == "5") {
    //   return {
    //     OptionId: qdata.question_id,
    //     OptionText: "Yes",
    //     ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
    //     ConsultationDate: name == "ConsultationDate" ? value : "",
    //     DiagnosisDate: name == "DiagnosisDate" ? value : "",
    //     CurrentStatus: name == "CurrentStatus" ? value : "",
    //     LineOfManagement: name == "LineOfManagement" ? value : "",
    //     TreatmentDetails: name == "TreatmentDetails" ? value : "",
    //   };
    // }
    else if (parentQid == "2") {
      if (qdata.question_id == "17") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          TestFinding: name == "TestFinding" ? value : "",
          TestDate: name == "TestDate" ? value : "",
          TypeOfTest: name == "TypeOfTest" ? value : "",
        };
      } else if(qdata.question_id == "18"){
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
        };
      }
    } else if (parentQid == "3") {
      if (qdata.question_id == "19") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",

          TestFinding: name == "TestFinding" ? value : "",
          TestDate: name == "TestDate" ? value : "",
          TypeOfTest: name == "TypeOfTest" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          TestFinding: "",
          TestDate: "",
          TypeOfTest: "",
        };
      }
    }

    // else if (parentQid == "3") {
    //   return {
    //     OptionId: qdata.question_id,
    //     OptionText: "Yes",
    //     ExactDiagnosis: name == "ExactDiagnosis" ? value : "",
    //     ConsultationDate: name == "ConsultationDate" ? value : "",
    //     DiagnosisDate: name == "DiagnosisDate" ? value : "",
    //     CurrentStatus: name == "CurrentStatus" ? value : "",
    //     TreatmentDetails: name == "TreatmentDetails" ? value : "",
    //   };
    // }
    // else if (parentQid == "4") {
    //   return {
    //     OptionId: qdata.question_id,
    //     OptionText: "Yes",
    //     TestFinding: name == "TestFinding" ? value : "",
    //     TestDate: name == "TestDate" ? value : "",
    //     TypeOfTest: name == "TypeOfTest" ? value : "",
    //   };
    // }
    else if (parentQid == "4") {
      if (qdata.question_id == "21") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          TestFinding: name == "TestFinding" ? value : "",
          TestDate: name == "TestDate" ? value : "",
          TypeOfTest: name == "TypeOfTest" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          TestFinding: name == "",
          TestDate: name == "",
          TypeOfTest: name == "",
        };
      }
    } else if (parentQid == "5") {
      if (qdata.question_id == "23") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          TestFinding: name == "TestFinding" ? value : "",
          TestDate: name == "TestDate" ? value : "",
          TypeOfTest: name == "TypeOfTest" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          TestFinding: name == "",
          TestDate: name == "",
          TypeOfTest: name == "",
        };
      }
    } else if (parentQid == "6") {
      if (qdata.question_id == "25") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Yes",
          TestFinding: name == "TestFinding" ? value : "",
          TestDate: name == "TestDate" ? value : "",
          TypeOfTest: name == "TypeOfTest" ? value : "",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: "No",
          TestFinding: name == "",
          TestDate: name == "",
          TypeOfTest: name == "",
        };
      }
    }

    // else if (parentQid == "6") {
    //   // alert(parentQid)
    //   return {
    //     OptionId: qdata.question_id,
    //     OptionText: "Yes",
    //     DeliveryDate: name == "DeliveryDate" ? value : "",
    //   };
    // }
    else if (
      ["79", "80", "81", "82", "83", "84", "85", "86"].includes(parentQid)
    ) {
      // alert(parentQid)

      if (parentQid == "85") {
        return {
          OptionId: qdata.question_id,
          OptionText: "Visual / Hearing  disability",
        };
      } else {
        return {
          OptionId: qdata.question_id,
          OptionText: qdata.question,
        };
      }
    }
  }

  // const updateHDFCQAlist = (payload) => {
  //   const { parentqData, pindex, qdata, name, value } = payload;
  //   const parentQid = parentqData.question_id;

  //   console.log("payload", payload);

  //   setHDFCcheckedQuestion((prevState) => {
  //     const updatedState = { ...prevState };

  //     // Ensure parent question exists
  //     if (!updatedState[parentQid]) {
  //       updatedState[parentQid] = {};
  //     }

  //     const question = updatedState[parentQid];

  //     // Ensure pindex exists
  //     if (!question[pindex]) {
  //       question[pindex] = [];
  //     }

  //     const qa = question[pindex];

  //     const newObj = {
  //       QuestionId: parentqData.question_id,
  //       QuestionText: parentqData.question,
  //       Options: [
  //         HDFCgetAnswerSet(qdata, name, value, parentqData.question_id),
  //       ],
  //     };

  //     // Check if parentqData.question_id is "15"
  //     if (
  //       // parentqData.question_id === "15"

  //       ["15", "3" ,"4" ,"5" ,"6"].includes(parentqData.question_id)

  //     ) {
  //       // const existingIndex = qa.findIndex(item => item.QuestionId === "15");
  //       const existingIndex = qa.findIndex(item => ["15", "3" ,"4","5","6"].includes(item.QuestionId));

  //       if (existingIndex !== -1) {
  //         // Replace existing object
  //         qa[existingIndex] = newObj;
  //       } else {
  //         // Add new object only if not present
  //         qa.push(newObj);
  //       }
  //     } else {
  //       // Handle other questions normally
  //       if (!HDFCquestionExist(qa, parentqData.question_id)) {
  //         qa.push(newObj);
  //       } else {
  //         const objKey = qa.findIndex(
  //           (item) => item.QuestionId === parentqData.question_id
  //         );

  //         const options = qa[objKey]["Options"];
  //         const optionIndex = options.findIndex(
  //           (item) => item.OptionId === qdata.question_id
  //         );

  //         if (optionIndex === -1) {
  //           // Add new option
  //           qa[objKey]["Options"].push(
  //             HDFCgetAnswerSet(qdata, name, value, parentqData.question_id)
  //           );
  //         } else {
  //           // Update existing option
  //           qa[objKey]["Options"][optionIndex][name] = value;
  //         }
  //       }
  //     }

  //     return updatedState;
  //   });
  // };

  const updateHDFCQAlist = (payload) => {
    const { parentqData, pindex, qdata, name, value } = payload;
    const parentQid = parentqData.question_id;

    console.log("payload", payload);

    setHDFCcheckedQuestion((prevState) => {
      const updatedState = { ...prevState };

      // Ensure parent question exists
      if (!updatedState[parentQid]) {
        updatedState[parentQid] = {};
      }

      const question = updatedState[parentQid];

      // Ensure pindex exists
      if (!question[pindex]) {
        question[pindex] = [];
      }

      const qa = question[pindex];

      const newObj = {
        QuestionId: parentqData.question_id,
        QuestionText: parentqData.question,
        Options: [
          HDFCgetAnswerSet(qdata, name, value, parentqData.question_id),
        ],
      };

      // Special handling for parentqData.question_id === "14"
      // if (parentQid === "14") {
      //   const existingIndex = qa.findIndex(item => item.QuestionId === "14");

      //   if (existingIndex !== -1) {
      //     // Found existing parent question
      //     const existingOptions = qa[existingIndex].Options;
      //     const optionIndex = existingOptions.findIndex(
      //       (option) => option.OptionId === qdata.question_id
      //     );

      //     if (optionIndex !== -1) {
      //       // Option already exists
      //       if (value === false) {
      //         // Remove the option if value is false
      //         existingOptions.splice(optionIndex, 1);
      //       }
      //     } else if (value === true) {
      //       // Add new option if value is true
      //       existingOptions.push(HDFCgetAnswerSet(qdata, name, value, parentQid));
      //     }

      //     // Remove the question object if it has no options left
      //     if (existingOptions.length === 0) {
      //       qa.splice(existingIndex, 1);
      //     }
      //   } else if (value === true) {
      //     // Add new parent question if not present
      //     qa.push(newObj);
      //   }
      // }
      // Handling for question IDs "15" to "6"
      // else if (["6"].includes(parentQid)) {
      //   const existingIndex = qa.findIndex(item =>
      //     ["6"].includes(item.QuestionId)
      //   );

      //   if (existingIndex !== -1) {
      //     qa[existingIndex] = newObj;
      //   } else {
      //     qa.push(newObj);
      //   }
      // }
      // Normal handling for other question IDs
      {
        if (!HDFCquestionExist(qa, parentQid)) {
          qa.push(newObj);
        } else {
          const objKey = qa.findIndex((item) => item.QuestionId == parentQid);

          const options = qa[objKey]["Options"];
          const optionIndex = options.findIndex(
            (item) => item.OptionId == qdata.question_id
          );

          if (optionIndex == -1) {
            // Add new option
            options.push(HDFCgetAnswerSet(qdata, name, value, parentQid));
          } else {
            // Update existing option
            options[optionIndex][name] = value;
          }
        }
      }

      return updatedState;
    });
  };

  return (
    <>
      {!check() == false && data.question != "No" ? (
        <div
          className="ppv10 my-3 col-md-12"
          style={{
            flexWrap: "wrap",
            height: "auto",
            marginRight: "0px",
            minWidth: "248px",
            padding: "0px",
          }}
          hidden={!check()}
        >
          <div className={

            parentData.question_id != "1" ?
         
        (    !["79", "80", "81", "82", "83", "84", "85", "86"].includes(
                parentData.question_id 
              ) ?"col-md-12":"col-md-12 test-form-container addedClassHdfc") :

              "col-md-12 test-form-container"
              } style={{ marginRight: "7px" }}>
            <span
              className="nfh1 cash"
              style={{
                fontWeight: "600",
                display: data.question == "Yes" ? "none" : "block",
              }}
            >
              {data.question == "Yes" ? null : ["79", "80", "81", "82", "83", "84", "85", "86"].includes(
                parentData.question_id) ? `${data.question} added ` : 
              data.question}
            </span>
            {parentData.question_id == "1" ? (
              <AnswerSetOne
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
                // value={first}
              />
            ) : // ["15","3"].includes(parentData.question_id)
            parentData.question_id == "2" ? (
              <AnswerSetTwo
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "3" ? (
              <AnswerSetThree
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "4" ? (
              <AnswerSetFour
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "5" ? (
              <AnswerSetFive
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                deliverydate={deliveryDates}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : parentData.question_id == "6" ? (
              <AnswerSetSeven
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                deliverydate={deliveryDates}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : ["79", "80", "81", "82", "83", "84", "85", "86"].includes(
                parentData.question_id
              ) ? (
              <AnswerSetSix
                pindex={pindex}
                qdata={data}
                parentqData={parentData}
                deliverydate={deliveryDates}
                proposerData={proposerData}
                HDFCcheckedQuestion={HDFCcheckedQuestion}
                setHDFCcheckedQuestion={setHDFCcheckedQuestion}
                updateHDFCQAlist={updateHDFCQAlist}
              />
            ) : null}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export const AnswerSetOne = ({
  pindex,
  qdata,
  parentqData,
  value,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();
  //  console.log('The Anwsar Indexing',pindex,qdata)

  const [dataObj, setDataObj] = useState();

  console.log("the answer set one data", HDFCcheckedQuestion);

  console.log("the pindex", pindex);

  console.log("the q data in set one", qdata);
  console.log("the parentqdata in set1", parentqData);
  console.log("va;lue", value);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  // useEffect(() => {
  //   if (HDFCcheckedQuestion) {

  //     console.log("hdfc checked question",HDFCcheckedQuestion )
  //     const keys = Object.keys(HDFCcheckedQuestion);
  //     const numbersToCheck = [14];
  //     const anyNumberPresent = numbersToCheck.some((number) =>
  //       keys.includes(number.toString())
  //     );

  //     const resultObjects = numbersToCheck
  //       .filter((number) => keys.includes(number.toString()))
  //       .map((number) => HDFCcheckedQuestion[number.toString()]);

  //     console.log("the resultObjects", resultObjects)
  //     let val;

  //     for (let key in resultObjects) {
  //       if (resultObjects[key].hasOwnProperty(pindex))
  //       {
  //         let data_one = resultObjects[key][pindex];
  //         // console.log("data_one",qdata.question_id)
  //         let val = data_one.find(item => item.QuestionId == parentqData.question_id );
  //         if (val != undefined)
  //         {
  //            console.log("val in for",val)
  //            let newobject = val;
  //            console.log("the newObject", newobject);

  //            if (newobject) {

  //              newobject.Options.map((item , index)=>{
  //                if(item.OptionId == qdata.question_id){

  //                    setDataObj(item);
  //                    return
  //                  }
  //              })
  //            }
  //         }
  //       }
  //    }

  //     // let available = val.hasOwnProperty(pindex);

  //     // if (available) {
  //     //   console.log("pindex", pindex);

  //     //   console.log("resultObjects[0].pindex", val[pindex]);

  //     //   let data = val[pindex];

  //     //   console.log("the data here", data);

  //     //   if (data[0] == undefined) {
  //     //     return;
  //     //   }

  //       // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

  //   }
  // }, [HDFCcheckedQuestion]);

  // useEffect(() => {
  //   if (HDFCcheckedQuestion) {
  //     const keys = Object.keys(HDFCcheckedQuestion);
  //     const numbersToCheck = [14];
  //     const anyNumberPresent = numbersToCheck.some((number) =>
  //       keys.includes(number.toString())
  //     );

  //     const resultObjects = numbersToCheck
  //       .filter((number) => keys.includes(number.toString()))
  //       .map((number) => HDFCcheckedQuestion[number.toString()]);

  //     let available = resultObjects[0].hasOwnProperty(pindex);

  //     if (available) {
  //       console.log("pindex", pindex);

  //       console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

  //       let data = resultObjects[0][pindex];

  //       console.log("the data here", data);

  //       if (data[0] == undefined) {
  //         return;
  //       }

  //       // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

  //       let newobject = data[0];
  //       console.log("the newObject", newobject);

  //       if (newobject) {
  //         console.log("newobject data", newobject);
  //         setDataObj(newobject);
  //       }
  //     }
  //   }
  // }, [HDFCcheckedQuestion]);

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      console.log("hdfc checked question", HDFCcheckedQuestion);
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [1];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      console.log("the resultObjects", resultObjects);
      let val;

      for (let key in resultObjects) {
        if (resultObjects[key].hasOwnProperty(pindex)) {
          let data_one = resultObjects[key][pindex];
          // console.log("data_one",qdata.question_id)
          let val = data_one.find(
            (item) => item.QuestionId == parentqData.question_id
          );
          if (val != undefined) {
            console.log("val in for", val);
            let newobject = val;
            console.log("the newObject", newobject);

            if (newobject) {
              newobject.Options.map((item, index) => {
                if (item.OptionId == qdata.question_id) {
                  setDataObj(item);
                  return;
                }
              });
            }
          }
        }
      }

      // let available = val.hasOwnProperty(pindex);

      // if (available) {
      //   console.log("pindex", pindex);

      //   console.log("resultObjects[0].pindex", val[pindex]);

      //   let data = val[pindex];

      //   console.log("the data here", data);

      //   if (data[0] == undefined) {
      //     return;
      //   }

      //   // let newobject = findObjectByDeliveryDate(data[0] , parentqData);
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set one", dataObj);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      {/* <Checkbox     
      // checked={(dataObj?.Options[0]) && (dataObj?.Options[0].OptionId == qdata.question_id) ?true:false}
      checked={dataObj?.Options?.some(option => option.OptionId == qdata.question_id) ? true : false}
      // checked={checkedData}
      inputProps={{ 'aria-label': 'controlled' }}
      onChange={(e)=>{
          updateHDFCQAlist(
            {
                  name: qdata?.question,
                  value: e.target.checked,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                }
          )
        
      }}
     
    /> */}
      <>
        <div className="hdfc-question-set col-md-12">
          <div className="upper-question-para col-md-6 pr-0">
            <TextField
              id="standard-helperText"
              className="col-md-12 hdfc-disease"
              label="Disease Name"
              value={dataObj?.ExactDiagnosis || ""}
              variant="standard"
              name="ExactDiagnosis"
              onChange={(e) =>
                // dispatch(
                //   updateHDFCQAlist({
                //     name: "ExactDiagnosis",
                //     value: e.target.value,
                //     pindex: pindex,
                //     qdata: qdata,
                //     parentqData: parentqData,
                //   })
                // )
                {
                  updateHDFCQAlist({
                    name: "ExactDiagnosis",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }
              }
            />
            <div className="hdfc-date-one ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DesktopDatePicker"]}
                  className="col-md-12"
                >
                  <DesktopDatePicker
                    className="hdfc-date"
                    variant="standard"
                    id="standard-helperText"
                    label="Date of Diagnosis"
                    value={
                      dataObj?.DiagnosisDate
                        ? dayjs(normalizeDate(dataObj?.DiagnosisDate))
                        : ""
                    }
                    format="DD-MM-YYYY"
                    name="Date of Diagnosis"
                    style={{ fontSize: "13px" }}
                    onChange={(e) =>
                      // dispatch(
                      // updateHDFCQAlist({
                      //   name: "DiagnosisDate",
                      //   value: getDate(e),
                      //   pindex: pindex,
                      //   qdata: qdata,
                      //   parentqData: parentqData,
                      // })
                      {
                        updateHDFCQAlist({
                          name: "DiagnosisDate",
                          value: getDate(e),
                          pindex: pindex,
                          qdata: qdata,
                          parentqData: parentqData,
                        });
                      }
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="hdfc-date-one ">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                className="setting-overflow"
              >
                <DemoContainer
                  components={["DesktopDatePicker"]}
                  className="col-md-12"
                >
                  <DesktopDatePicker
                    variant="standard"
                    className="hdfc-date"
                    id="standard-helperText"
                    label="Last Consultation Date"
                    value={
                      dataObj?.ConsultationDate
                        ? dayjs(normalizeDate(dataObj?.ConsultationDate))
                        : ""
                    }
                    format="DD-MM-YYYY"
                    name="Last Consultation Date"
                    style={{ fontSize: "13px" }}
                    onChange={(e) =>
                      // dispatch(
                      //   updateHDFCQAlist({
                      //     name: "ConsultationDate",
                      //     value: getDate(e),
                      //     pindex: pindex,
                      //     qdata: qdata,
                      //     parentqData: parentqData,
                      //   })
                      // )
                      {
                        updateHDFCQAlist({
                          name: "ConsultationDate",
                          value: getDate(e),
                          pindex: pindex,
                          qdata: qdata,
                          parentqData: parentqData,
                        });
                      }
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className="lower-question-para col-md-6 pr-0">
            <TextField
              className="hdfc-treatment"
              id="standard-helperText"
              label="Details of Treatment given"
              defaultValue=""
              variant="standard"
              name="Details of Treatment given"
              value={dataObj?.TreatmentDetails ? dataObj?.TreatmentDetails : ""}
              onChange={(e) =>
                // dispatch(
                //   updateHDFCQAlist({
                //     name: "TreatmentDetails",
                //     value: e.target.value,
                //     pindex: pindex,
                //     qdata: qdata,
                //     parentqData: parentqData,
                //   })
                // )
                {
                  updateHDFCQAlist({
                    name: "TreatmentDetails",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }
              }
            />

            <TextField
              id="standard-select-currency-native"
              className="hdfc-select "
              select
              label=""
              SelectProps={{
                native: true,
              }}
              variant="standard"
              name="Current Status"
              value={dataObj?.CurrentStatus ? dataObj?.CurrentStatus : ""}
              onChange={(e) =>
                // dispatch(
                //   updateHDFCQAlist({
                //     name: "CurrentStatus",
                //     value: e.target.value,
                //     pindex: pindex,
                //     qdata: qdata,
                //     parentqData: parentqData,
                //   })
                // )
                {
                  updateHDFCQAlist({
                    name: "CurrentStatus",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }
              }
            >
              <option>Select Current Status</option>
              <option value="Cured">Cured</option>
              <option value="Not treated">Not treated</option>
              <option value="Ongoing">Ongoing</option>
            </TextField>
            <TextField
              id="standard-select-currency-native"
              className="hdfc-select"
              select
              label=""
              SelectProps={{
                native: true,
              }}
              // style={{
              //   maxHeight: "min-content",
              //   display: "flex",
              //   minWidth: "100% !important",
              //   maxWidth: "100% !important",
              //   textWrap: "wrap",
              //   fontSize: "13px"
              // }}
              variant="standard"
              name="Line of Management"
              value={dataObj?.LineOfManagement ? dataObj?.LineOfManagement : ""}
              onChange={(e) =>
                // dispatch(
                //   updateHDFCQAlist({
                //     name: "LineOfManagement",
                //     value: e.target.value,
                //     pindex: pindex,
                //     qdata: qdata,
                //     parentqData: parentqData,
                //   })
                // )
                {
                  updateHDFCQAlist({
                    name: "LineOfManagement",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  });
                }
              }
            >
              <option>Select Line of Management</option>
              <option value="Medical Management">Medical Management</option>
              <option value="Surgical Management">Surgical Management</option>
            </TextField>
          </div>
        </div>
      </>
    </>
  );
};

export const AnswerSetTwo = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [2];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set two", dataObj?.Options[0].OptionId);

  console.log("the parentqdata here", qdata.question_id);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      {/* <Checkbox     
      checked={(dataObj?.Options[0]) && (dataObj?.Options[0].OptionId == qdata.question_id) ?true:false}
      // checked={checkedData}
      inputProps={{ 'aria-label': 'controlled' }}
      onChange={(e)=>{
      
          updateHDFCQAlist(
            {
                  name: "data",
                  value: e.target.checked,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                }
          )
        
      }}
     
    /> */}

<>
        <div className="test-form-container">
          <TextField
            id="test-finding"
            label="Test Finding"
            value={dataObj?.Options[0]?.TestFinding || ""}
            variant="standard"
            name="Test Finding"
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TestFinding",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="test-date-container">
              <FormLabel className="custom-label">Test Date</FormLabel>
              <DesktopDatePicker
                id="test-date"
                value={
                  dataObj?.Options[0]?.TestDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Test Date"
                className="test-date-picker"
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true, // ✅ Fix: Removes default underline
                      className: "date-input-field",
                    },
                  },
                }}
                onChange={(e) =>
                  updateHDFCQAlist({
                    name: "TestDate",
                    value: getDate(e),
                    pindex,
                    qdata,
                    parentqData,
                  })
                }
              />
            </div>
          </LocalizationProvider>

          <TextField
            id="type-of-test"
            label="Type Of Test"
            variant="standard"
            name="Type Of Test"
            value={dataObj?.Options[0]?.TypeOfTest || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TypeOfTest",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};

export const AnswerSetThree = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);

  console.log("parentqdata in set three", parentqData);
  console.log("qdata", qdata);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [3];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set three", dataObj?.Options);

  console.log("the parentqdata here", qdata.question_id);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      {/* <Checkbox     
      checked={(dataObj?.Options[0]) && (dataObj?.Options[0].OptionId == qdata.question_id) ?true:false}
      // checked={checkedData}
      inputProps={{ 'aria-label': 'controlled' }}
      onChange={(e)=>{
      
          updateHDFCQAlist(
            {
                  name: "data",
                  value: e.target.checked,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                }
          )
        
      }}
     
    /> */}
      <>
        <div className="test-form-container">
          <TextField
            id="test-finding"
            label="Test Finding"
            value={dataObj?.Options[0]?.TestFinding || ""}
            variant="standard"
            name="Test Finding"
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TestFinding",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="test-date-container">
              <FormLabel className="custom-label">Test Date</FormLabel>
              <DesktopDatePicker
                id="test-date"
                value={
                  dataObj?.Options[0]?.TestDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Test Date"
                className="test-date-picker"
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true, // ✅ Fix: Removes default underline
                      className: "date-input-field",
                    },
                  },
                }}
                onChange={(e) =>
                  updateHDFCQAlist({
                    name: "TestDate",
                    value: getDate(e),
                    pindex,
                    qdata,
                    parentqData,
                  })
                }
              />
            </div>
          </LocalizationProvider>

          <TextField
            id="type-of-test"
            label="Type Of Test"
            variant="standard"
            name="Type Of Test"
            value={dataObj?.Options[0]?.TypeOfTest || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TypeOfTest",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};

export const AnswerSetFour = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [4];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set two", dataObj?.Options[0].OptionId);

  console.log("the parentqdata here", qdata.question_id);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      {/* <Checkbox     
      checked={(dataObj?.Options[0]) && (dataObj?.Options[0].OptionId == qdata.question_id) ?true:false}
      // checked={checkedData}
      inputProps={{ 'aria-label': 'controlled' }}
      onChange={(e)=>{
      
          updateHDFCQAlist(
            {
                  name: "data",
                  value: e.target.checked,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                }
          )
        
      }}
     
    /> */}
        <>
        <div className="test-form-container">
          <TextField
            id="test-finding"
            label="Test Finding"
            value={dataObj?.Options[0]?.TestFinding || ""}
            variant="standard"
            name="Test Finding"
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TestFinding",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="test-date-container">
              <FormLabel className="custom-label">Test Date</FormLabel>
              <DesktopDatePicker
                id="test-date"
                value={
                  dataObj?.Options[0]?.TestDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Test Date"
                className="test-date-picker"
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true, // ✅ Fix: Removes default underline
                      className: "date-input-field",
                    },
                  },
                }}
                onChange={(e) =>
                  updateHDFCQAlist({
                    name: "TestDate",
                    value: getDate(e),
                    pindex,
                    qdata,
                    parentqData,
                  })
                }
              />
            </div>
          </LocalizationProvider>

          <TextField
            id="type-of-test"
            label="Type Of Test"
            variant="standard"
            name="Type Of Test"
            value={dataObj?.Options[0]?.TypeOfTest || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TypeOfTest",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};
export const AnswerSetFive = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [5];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set two", dataObj?.Options[0].OptionId);

  console.log("the parentqdata here", qdata.question_id);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      {/* <Checkbox     
      checked={(dataObj?.Options[0]) && (dataObj?.Options[0].OptionId == qdata.question_id) ?true:false}
      // checked={checkedData}
      inputProps={{ 'aria-label': 'controlled' }}
      onChange={(e)=>{
      
          updateHDFCQAlist(
            {
                  name: "data",
                  value: e.target.checked,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                }
          )
        
      }}
     
    /> */}
      <>
        <div className="test-form-container">
          <TextField
            id="test-finding"
            label="Test Finding"
            value={dataObj?.Options[0]?.TestFinding || ""}
            variant="standard"
            name="Test Finding"
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TestFinding",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="test-date-container">
              <FormLabel className="custom-label">Test Date</FormLabel>
              <DesktopDatePicker
                id="test-date"
                value={
                  dataObj?.Options[0]?.TestDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Test Date"
                className="test-date-picker"
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true, // ✅ Fix: Removes default underline
                      className: "date-input-field",
                    },
                  },
                }}
                onChange={(e) =>
                  updateHDFCQAlist({
                    name: "TestDate",
                    value: getDate(e),
                    pindex,
                    qdata,
                    parentqData,
                  })
                }
              />
            </div>
          </LocalizationProvider>

          <TextField
            id="type-of-test"
            label="Type Of Test"
            variant="standard"
            name="Type Of Test"
            value={dataObj?.Options[0]?.TypeOfTest || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TypeOfTest",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};
export const AnswerSetSeven = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [6];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set two", dataObj?.Options[0].OptionId);

  console.log("the parentqdata here", qdata.question_id);

  const normalizeDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const paddedMonth = month.padStart(2, "0");
      const paddedDay = day.padStart(2, "0");
      return `${year}-${paddedMonth}-${paddedDay}`;
    }
  };

  return (
    <>
      {/* <Checkbox     
      checked={(dataObj?.Options[0]) && (dataObj?.Options[0].OptionId == qdata.question_id) ?true:false}
      // checked={checkedData}
      inputProps={{ 'aria-label': 'controlled' }}
      onChange={(e)=>{
      
          updateHDFCQAlist(
            {
                  name: "data",
                  value: e.target.checked,
                  pindex: pindex,
                  qdata: qdata,
                  parentqData: parentqData,
                }
          )
        
      }}
     
    /> */}

      {/* <>
    <TextField
        id="standard-helperText"
        label="Test Finding"
        value={dataObj?.Options[0]?.TestFinding || ""}
        variant="standard"
        name="Test Finding"
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "TestFinding",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          {
            updateHDFCQAlist({
              
                    name: "TestFinding",
                    value: e.target.value,
                    pindex: pindex,
                    qdata: qdata,
                    parentqData: parentqData,
                  
            })
          }
        }
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DesktopDatePicker"]}>
          <DesktopDatePicker
            variant="standard"
            id="standard-helperText"
            label="Test Date"
            value={dataObj?.Options[0]?.TestDate ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate)) : ""}
            format="DD-MM-YYYY"
            name="Test Date"
            onChange={(e) =>
              // dispatch(
              //   updateHDFCQAlist({
              //     name: "TestDate",
              //     value: getDate(e),
              //     pindex: pindex,
              //     qdata: qdata,
              //     parentqData: parentqData,
              //   })
              // )
              {
                updateHDFCQAlist({
                  
                        name: "TestDate",
                        value: getDate(e),
                        pindex: pindex,
                        qdata: qdata,
                        parentqData: parentqData,
                      
                })
              }
            }
          />
        </DemoContainer>
      </LocalizationProvider>

      <TextField
        id="standard-helperText"
        label="Type Of Test"
        variant="standard"
        name="Type Of Test"
        value={dataObj?.Options[0]?.TypeOfTest || ""}
        onChange={(e) =>
          // dispatch(
          //   updateHDFCQAlist({
          //     name: "TypeOfTest",
          //     value: e.target.value,
          //     pindex: pindex,
          //     qdata: qdata,
          //     parentqData: parentqData,
          //   })
          // )
          
            {
              updateHDFCQAlist({
                
                      name: "TypeOfTest",
                      value: e.target.value,
                      pindex: pindex,
                      qdata: qdata,
                      parentqData: parentqData,
                    
              })
            }
          
        }
      />
  </> */}

    <>
        <div className="test-form-container">
          <TextField
            id="test-finding"
            label="Test Finding"
            value={dataObj?.Options[0]?.TestFinding || ""}
            variant="standard"
            name="Test Finding"
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TestFinding",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="test-date-container">
              <FormLabel className="custom-label">Test Date</FormLabel>
              <DesktopDatePicker
                id="test-date"
                value={
                  dataObj?.Options[0]?.TestDate
                    ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate))
                    : ""
                }
                format="DD-MM-YYYY"
                name="Test Date"
                className="test-date-picker"
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true, // ✅ Fix: Removes default underline
                      className: "date-input-field",
                    },
                  },
                }}
                onChange={(e) =>
                  updateHDFCQAlist({
                    name: "TestDate",
                    value: getDate(e),
                    pindex,
                    qdata,
                    parentqData,
                  })
                }
              />
            </div>
          </LocalizationProvider>

          <TextField
            id="type-of-test"
            label="Type Of Test"
            variant="standard"
            name="Type Of Test"
            value={dataObj?.Options[0]?.TypeOfTest || ""}
            className="test-input-field"
            InputLabelProps={{ className: "custom-label" }}
            onChange={(e) =>
              updateHDFCQAlist({
                name: "TypeOfTest",
                value: e.target.value,
                pindex,
                qdata,
                parentqData,
              })
            }
          />
        </div>

        <style jsx>{`
          .test-form-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;

            // max-width: 400px;
            padding-bottom: 22px;
            margin: auto;

            border-radius: 6px;

            background: #fffbfb;

            box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
          }

          .test-input-field {
            width: 100%;
          }

          .test-date-container {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 7px;
            margin-bottom: -6px;
          }

          .test-date-container > div {
            border-bottom: 1px solid #837d7dd9;
            padding: 4px;
            border-radius: 0;
            padding-left: 0px;
          }

          .custom-label {
            font-size: 14px;
            color: #0357a6;
            font-weight: 500;
            margin-bottom: -9px;
          }

          .test-date-picker .MuiInputBase-input {
            padding: 6px;
            font-size: 14px;
            color: #575252;
            padding-left: 0px;
            padding-bottom: 0px;
          }

          .date-input-field {
            font-size: 14px !important;
            color: #575252 !important;
            // padding: 6px 0 6px 0 !important; /* No left padding */
            border-bottom: none !important; /* Removes default border */
            padding: 4px;
          }

          .test-date-picker .MuiInputBase-input::placeholder {
            text-align: left !important;
          }
        `}</style>
      </>
    </>
  );
};
export const AnswerSetEight = ({
  pindex,
  qdata,
  parentqData,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const dispatch = useDispatch();
  // console.log('The Anwsar Indexing',pindex,qdata)
  const [dataObj, setDataObj] = useState();
  const [checkedData, setCheckedData] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

  console.log("the pindex", pindex);

  function findObjectByDeliveryDate(data, qid) {
    // Loop through the data object
    // for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //         const questions = data[key];

    //         // Loop through each question
    //         for (const question of questions) {

    //           console.log("new question item", question , qid)

    //                 if (question.QuestionId === qid.question_id) {
    //               console.log("questions",question)

    //                     return question; // Return the entire question object
    //                 }

    //         }
    //     }
    // }

    if (data.QuestionId == qid) {
      return;
    }
    return null; // Return null if no match is found
  }

  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [6];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  console.log("the final data in set two", dataObj?.Options[0].OptionId);

  console.log("the parentqdata here", qdata.question_id);

  return (
    <>
      <Checkbox
        checked={
          dataObj?.Options[0] &&
          dataObj?.Options[0].OptionId == qdata.question_id
            ? true
            : false
        }
        // checked={checkedData}
        inputProps={{ "aria-label": "controlled" }}
        onChange={(e) => {
          updateHDFCQAlist({
            name: "data",
            value: e.target.checked,
            pindex: pindex,
            qdata: qdata,
            parentqData: parentqData,
          });
        }}
      />
    </>
  );
};
// export const AnswerSetThree = ({ pindex, qdata, parentqData ,proposerData ,
//   HDFCcheckedQuestion,
//   setHDFCcheckedQuestion, updateHDFCQAlist  }) => {
//   const dispatch = useDispatch();
//   // console.log('The Anwsar Indexing',pindex,qdata)

//   //  console.log('The Anwsar Indexing',pindex,qdata)

//   const [dataObj, setDataObj] = useState();

//   // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

//   console.log("the pindex", pindex);

//   function findObjectByDeliveryDate(data, qid) {
//     // Loop through the data object
//     // for (const key in data) {
//     //     if (data.hasOwnProperty(key)) {
//     //         const questions = data[key];

//     //         // Loop through each question
//     //         for (const question of questions) {

//     //           console.log("new question item", question , qid)

//     //                 if (question.QuestionId === qid.question_id) {
//     //               console.log("questions",question)

//     //                     return question; // Return the entire question object
//     //                 }

//     //         }
//     //     }
//     // }

//     if (data.QuestionId == qid) {
//       return;
//     }
//     return null; // Return null if no match is found
//   }

//   // useEffect(() => {
//   //   if (HDFCcheckedQuestion) {
//   //     const keys = Object.keys(HDFCcheckedQuestion);
//   //     const numbersToCheck = [3,3];
//   //     const anyNumberPresent = numbersToCheck.some((number) =>
//   //       keys.includes(number.toString())
//   //     );

//   //     const resultObjects = numbersToCheck
//   //       .filter((number) => keys.includes(number.toString()))
//   //       .map((number) => HDFCcheckedQuestion[number.toString()]);

//   //     let available = resultObjects[0].hasOwnProperty(pindex);

//   //     if (available) {
//   //       console.log("pindex", pindex);

//   //       console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

//   //       let data = resultObjects[0][pindex];

//   //       console.log("the data here", data);

//   //       if (data[0] == undefined) {
//   //         return;
//   //       }

//   //       // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

//   //       let newobject = data[0];
//   //       console.log("the newObject", newobject);

//   //       if (newobject) {
//   //         console.log("newobject data", newobject);
//   //         setDataObj(newobject);
//   //       }
//   //     }
//   //   }
//   // }, [HDFCcheckedQuestion]);

//   console.log("the final data in set three", dataObj);

//   const normalizeDate = (dateString) => {
//     if (dateString) {
//       const [year, month, day] = dateString.split("-");
//       const paddedMonth = month.padStart(2, "0");
//       const paddedDay = day.padStart(2, "0");
//       return `${year}-${paddedMonth}-${paddedDay}`;
//     }
//   };

//   return (
//     <>
//       <TextField
//         id="standard-helperText"
//         label="Disease Name"
//         value={dataObj?.Options[0]?.ExactDiagnosis || ""}
//         variant="standard"
//         name="Disease Name"
//         style={{
//           maxHeight: "min-content",
//           display: "flex",
//           minWidth: "100% !important",
//           maxWidth: "100% !important",
//           textWrap: "wrap",
//         }}
//         onChange={(e) =>
//           // dispatch(
//           //   updateHDFCQAlist({
//           //     name: "ExactDiagnosis",
//           //     value: e.target.value,
//           //     pindex: pindex,
//           //     qdata: qdata,
//           //     parentqData: parentqData,
//           //   })
//           // )
//           {
//             updateHDFCQAlist(
//               {
//                     name: "ExactDiagnosis",
//                     value: e.target.value,
//                     pindex: pindex,
//                     qdata: qdata,
//                     parentqData: parentqData,
//                   }
//             )
//           }
//         }
//       />
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DemoContainer components={["DesktopDatePicker"]}>
//           <DesktopDatePicker
//             variant="standard"
//             id="standard-helperText"
//             label="Date of Diagnosis"
//             value={dataObj?.Options[0]?.DiagnosisDate ? dayjs(normalizeDate(dataObj?.Options[0]?.DiagnosisDate)) : ""}
//             format="DD-MM-YYYY"
//             name="Date of Diagnosis"
//             onChange={(e) =>
//               // dispatch(
//               //   updateHDFCQAlist({
//               //     name: "DiagnosisDate",
//               //     value: getDate(e),
//               //     pindex: pindex,
//               //     qdata: qdata,
//               //     parentqData: parentqData,
//               //   })
//               // )
//               {
//                 updateHDFCQAlist(
//                   {
//                         name: "DiagnosisDate",
//                         value: getDate(e),
//                         pindex: pindex,
//                         qdata: qdata,
//                         parentqData: parentqData,
//                       }
//                 )
//               }
//             }
//           />
//         </DemoContainer>
//       </LocalizationProvider>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DemoContainer components={["DesktopDatePicker"]}>
//           <DesktopDatePicker
//             variant="standard"
//             id="standard-helperText"
//             label="Last Consultation Date"
//             value={dataObj?.Options[0]?.ConsultationDate ? dayjs(normalizeDate(dataObj?.Options[0]?.ConsultationDate)) : ""}
//             format="DD-MM-YYYY"
//             name="Last Consultation Date"
//             style={{
//               fontSize: "small !important",
//               minWidth: "auto!important",
//             }}
//             onChange={(e) =>
//               // dispatch(
//               //   updateHDFCQAlist({
//               //     name: "ConsultationDate",
//               //     value: getDate(e),
//               //     pindex: pindex,
//               //     qdata: qdata,
//               //     parentqData: parentqData,
//               //   })
//               // )

//                 {
//                   updateHDFCQAlist(
//                     {
//                           name: "ConsultationDate",
//                           value: getDate(e),
//                           pindex: pindex,
//                           qdata: qdata,
//                           parentqData: parentqData,
//                         }
//                   )
//                 }

//             }
//           />
//         </DemoContainer>
//       </LocalizationProvider>
//       <TextField
//         id="standard-helperText"
//         label="Details of Treatment given"
//             value={dataObj?.Options[0]?.TreatmentDetails || ""}
//         variant="standard"
//         style={{
//           maxHeight: "min-content",
//           display: "flex",
//           minWidth: "100% !important",
//           maxWidth: "100% !important",
//           textWrap: "wrap",
//         }}
//         name="Details of Treatment given"
//         onChange={(e) =>
//           // dispatch(
//           //   updateHDFCQAlist({
//           //     name: "TreatmentDetails",
//           //     value: e.target.value,
//           //     pindex: pindex,
//           //     qdata: qdata,
//           //     parentqData: parentqData,
//           //   })
//           // )
//           {
//             updateHDFCQAlist(
//               {
//                     name: "TreatmentDetails",
//                     value: e.target.value,
//                     pindex: pindex,
//                     qdata: qdata,
//                     parentqData: parentqData,
//                   }
//             )
//           }
//         }
//       />

//       <TextField
//         id="standard-select-currency-native"
//         select
//         label=""
//         SelectProps={{
//           native: true,
//         }}
//         value={dataObj?.Options[0]?.CurrentStatus}
//         style={{
//           maxHeight: "min-content",
//           display: "flex",
//           minWidth: "100% !important",
//           maxWidth: "100% !important",
//           textWrap: "wrap",
//         }}
//         variant="standard"
//         name="Current Status"
//         onChange={(e) =>
//           // dispatch(
//           //   updateHDFCQAlist({
//           //     name: "CurrentStatus",
//           //     value: e.target.value,
//           //     pindex: pindex,
//           //     qdata: qdata,
//           //     parentqData: parentqData,
//           //   })
//           // )
//           {
//             updateHDFCQAlist(
//               {
//                     name: "CurrentStatus",
//                     value: e.target.value,
//                     pindex: pindex,
//                     qdata: qdata,
//                     parentqData: parentqData,
//                   }
//             )
//           }
//         }
//       >
//         <option>Select Current Status</option>
//         <option value="Cured">Cured</option>
//         <option value="Not treated">Not treated</option>
//         <option value="Ongoing">Ongoing</option>
//       </TextField>
//     </>
//   );
// };

// export const AnswerSetFour = ({ pindex, qdata, parentqData, proposerData ,
//   HDFCcheckedQuestion,
//   setHDFCcheckedQuestion ,updateHDFCQAlist }) => {
//   const dispatch = useDispatch();
//   // console.log('The Anwsar Indexing',pindex,qdata)

//   //  console.log('The Anwsar Indexing',pindex,qdata)

//   const [dataObj, setDataObj] = useState();

//   // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

//   console.log("the pindex", pindex);

//   function findObjectByDeliveryDate(data, qid) {
//     // Loop through the data object
//     // for (const key in data) {
//     //     if (data.hasOwnProperty(key)) {
//     //         const questions = data[key];

//     //         // Loop through each question
//     //         for (const question of questions) {

//     //           console.log("new question item", question , qid)

//     //                 if (question.QuestionId === qid.question_id) {
//     //               console.log("questions",question)

//     //                     return question; // Return the entire question object
//     //                 }

//     //         }
//     //     }
//     // }

//     if (data.QuestionId == qid) {
//       return;
//     }
//     return null; // Return null if no match is found
//   }

//   useEffect(() => {
//     if (HDFCcheckedQuestion) {
//       const keys = Object.keys(HDFCcheckedQuestion);
//       const numbersToCheck = [4,4];
//       const anyNumberPresent = numbersToCheck.some((number) =>
//         keys.includes(number.toString())
//       );

//       const resultObjects = numbersToCheck
//         .filter((number) => keys.includes(number.toString()))
//         .map((number) => HDFCcheckedQuestion[number.toString()]);

//       let available = resultObjects[0].hasOwnProperty(pindex);

//       if (available) {
//         console.log("pindex", pindex);

//         console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

//         let data = resultObjects[0][pindex];

//         console.log("the data here", data);

//         if (data[0] == undefined) {
//           return;
//         }

//         // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

//         let newobject = data[0];
//         console.log("the newObject", newobject);

//         if (newobject) {
//           console.log("newobject data", newobject);
//           setDataObj(newobject);
//         }
//       }
//     }
//   }, [HDFCcheckedQuestion]);

//   console.log("the final data in set four", dataObj);

//   const normalizeDate = (dateString) => {
//     if (dateString) {
//       const [year, month, day] = dateString.split("-");
//       const paddedMonth = month.padStart(2, "0");
//       const paddedDay = day.padStart(2, "0");
//       return `${year}-${paddedMonth}-${paddedDay}`;
//     }
//   };

//   return (
//     <>
//       <TextField
//         id="standard-helperText"
//         label="Test Finding"
//         value={dataObj?.Options[0]?.TestFinding || ""}
//         variant="standard"
//         name="Test Finding"
//         onChange={(e) =>
//           // dispatch(
//           //   updateHDFCQAlist({
//           //     name: "TestFinding",
//           //     value: e.target.value,
//           //     pindex: pindex,
//           //     qdata: qdata,
//           //     parentqData: parentqData,
//           //   })
//           // )
//           {
//             updateHDFCQAlist({

//                     name: "TestFinding",
//                     value: e.target.value,
//                     pindex: pindex,
//                     qdata: qdata,
//                     parentqData: parentqData,

//             })
//           }
//         }
//       />
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DemoContainer components={["DesktopDatePicker"]}>
//           <DesktopDatePicker
//             variant="standard"
//             id="standard-helperText"
//             label="Test Date"
//             value={dataObj?.Options[0]?.TestDate ? dayjs(normalizeDate(dataObj?.Options[0]?.TestDate)) : ""}
//             format="DD-MM-YYYY"
//             name="Test Date"
//             onChange={(e) =>
//               // dispatch(
//               //   updateHDFCQAlist({
//               //     name: "TestDate",
//               //     value: getDate(e),
//               //     pindex: pindex,
//               //     qdata: qdata,
//               //     parentqData: parentqData,
//               //   })
//               // )
//               {
//                 updateHDFCQAlist({

//                         name: "TestDate",
//                         value: getDate(e),
//                         pindex: pindex,
//                         qdata: qdata,
//                         parentqData: parentqData,

//                 })
//               }
//             }
//           />
//         </DemoContainer>
//       </LocalizationProvider>

//       <TextField
//         id="standard-helperText"
//         label="Type Of Test"
//         variant="standard"
//         name="Type Of Test"
//         value={dataObj?.Options[0]?.TypeOfTest || ""}
//         onChange={(e) =>
//           // dispatch(
//           //   updateHDFCQAlist({
//           //     name: "TypeOfTest",
//           //     value: e.target.value,
//           //     pindex: pindex,
//           //     qdata: qdata,
//           //     parentqData: parentqData,
//           //   })
//           // )

//             {
//               updateHDFCQAlist({

//                       name: "TypeOfTest",
//                       value: e.target.value,
//                       pindex: pindex,
//                       qdata: qdata,
//                       parentqData: parentqData,

//               })
//             }

//         }
//       />
//     </>
//   );
// };

// export const AnswerSetFive = ({ pindex, qdata, parentqData, deliverydate , proposerData ,
//   HDFCcheckedQuestion,
//   setHDFCcheckedQuestion , updateHDFCQAlist }) => {
//   const [dataObj, setDataObj] = useState();

//   // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);

//   console.log("the pindex", pindex);

//   const dispatch = useDispatch();

//   const currentDateStr = deliverydate[pindex];

//   console.log("the Date of pregnency", currentDateStr);

//   function findObjectByDeliveryDate(data, qid) {
//     // Loop through the data object
//     // for (const key in data) {
//     //     if (data.hasOwnProperty(key)) {
//     //         const questions = data[key];

//     //         // Loop through each question
//     //         for (const question of questions) {

//     //           console.log("new question item", question , qid)

//     //                 if (question.QuestionId === qid.question_id) {
//     //               console.log("questions",question)

//     //                     return question; // Return the entire question object
//     //                 }

//     //         }
//     //     }
//     // }

//     if (data.QuestionId == qid) {
//       return;
//     }
//     return null; // Return null if no match is found
//   }

//   useEffect(() => {
//     if (HDFCcheckedQuestion) {
//       const keys = Object.keys(HDFCcheckedQuestion);
//       const numbersToCheck = [6, 6];
//       const anyNumberPresent = numbersToCheck.some((number) =>
//         keys.includes(number.toString())
//       );

//       const resultObjects = numbersToCheck
//         .filter((number) => keys.includes(number.toString()))
//         .map((number) => HDFCcheckedQuestion[number.toString()]);

//       let available = resultObjects[0].hasOwnProperty(pindex);

//       if (available) {
//         console.log("pindex", pindex);

//         console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

//         let data = resultObjects[0][pindex];

//         console.log("the data here", data);

//         if (data[0] == undefined) {
//           return;
//         }

//         // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

//         let newobject = data[0];
//         console.log("the newObject", newobject);

//         if (newobject) {
//           console.log("newobject data", newobject);
//           setDataObj(newobject);
//         }
//       }
//     }
//   }, [HDFCcheckedQuestion]);

//   console.log("the final data ", dataObj);

//   const normalizeDate = (dateString) => {
//     if (dateString) {
//       const [year, month, day] = dateString.split("-");
//       const paddedMonth = month.padStart(2, "0");
//       const paddedDay = day.padStart(2, "0");
//       return `${year}-${paddedMonth}-${paddedDay}`;
//     }
//   };

//   return (
//     <>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DemoContainer components={["DesktopDatePicker"]}>
//           <DesktopDatePicker
//             variant="standard"
//             id="standard-helperText"
//             label="Delivery Date"
//             value={
//               dataObj?.Options[0]?.DeliveryDate ? dayjs(normalizeDate(dataObj?.Options[0]?.DeliveryDate)) : ""
//             }
//             format="DD-MM-YYYY"
//             name="Delivery Date"
//             onChange={(e) =>
//               // dispatch(
//               //   updateHDFCQAlist({
//               //     name: "DeliveryDate",
//               //     value: getDate(e),
//               //     pindex: pindex,
//               //     qdata: qdata,
//               //     parentqData: parentqData,
//               //   })
//               // )
//               {
//                 updateHDFCQAlist({
//                       name: "DeliveryDate",
//                       value: getDate(e),
//                       pindex: pindex,
//                       qdata: qdata,
//                       parentqData: parentqData,
//                     })
//               }
//             }
//           />
//         </DemoContainer>
//       </LocalizationProvider>
//     </>
//   );
// };

export const AnswerSetSix = ({
  pindex,
  qdata,
  parentqData,
  deliverydate,
  proposerData,
  HDFCcheckedQuestion,
  setHDFCcheckedQuestion,
  updateHDFCQAlist,
}) => {
  const [dataObj, setDataObj] = useState();

  // const HDFCcheckedQuestion = useSelector(selectHDFCcheckedQuestion);
  console.log("the pindex in set 6", parentqData.question_id);
  useEffect(() => {
    if (HDFCcheckedQuestion) {
      const keys = Object.keys(HDFCcheckedQuestion);
      const numbersToCheck = [79, 80, 81, 82, 83, 84, 85, 86];
      const anyNumberPresent = numbersToCheck.some((number) =>
        keys.includes(number.toString())
      );

      const resultObjects = numbersToCheck
        .filter((number) => keys.includes(number.toString()))
        .map((number) => HDFCcheckedQuestion[number.toString()]);

      let available = resultObjects[0].hasOwnProperty(pindex);

      if (available) {
        console.log("pindex", pindex);

        console.log("resultObjects[0].pindex", resultObjects[0][pindex]);

        let data = resultObjects[0][pindex];

        console.log("the data here", data);

        if (data[0] == undefined) {
          return;
        }

        // let newobject = findObjectByDeliveryDate(data[0] , parentqData);

        let newobject = data[0];
        console.log("the newObject", newobject);

        if (newobject) {
          console.log("newobject data", newobject);
          setDataObj(newobject);
        }
      }
    }
  }, [HDFCcheckedQuestion]);

  useEffect(() => {
    if (
      ["79", "80", "81", "82", "83", "84", "85", "86"].includes(
        parentqData.question_id
      )
    ) {
      updateHDFCQAlist({
        name: "data",
        value: true,
        pindex: pindex,
        qdata: qdata,
        parentqData: parentqData,
      });
    }
  }, []);

  return (
    <>
   
      <Checkbox
        checked={dataObj?.Options[0] ? true : false}
        inputProps={{ "aria-label": "controlled" }}
      />
    </>
  );
};
