import React, { useState, useEffect } from "react";
import '../ProposalPage/Proposal.css'
import { Skeleton, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Proposal, ProposerForm } from "./Proposal";
import { findSelf , parseData } from "../../helper/common";
import Summary from "../../commonComponents/Summary";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import calculateAge from "../AfterPlanFetchPage/AgeModalHelper";
import Loader from "../../../AllPageComponents/Loader";
import Swal from "sweetalert2";
import dayjs from "dayjs";


import Redirect from "../../../AllPageComponents/Rederict";
import {
  afterPlanFetchApi,
  AddonApi,
  getReduxData,
  settingReduxData,
} from "../../Api/Api";
import { Helmet } from "react-helmet-async";
import { updateDatabase } from "../../Api/Api";

const Proposalfrom = ({ socketManager }) => {
  const history = useNavigate();

  const [proposerData, setProposerData] = useState({});

  const [apiResponse, setApiResponse] = useState();
  const [addonDataList, setAddonDataList] = useState([]);
  const [addonArr, setAddonArr] = useState([]);
  const [addonList, setAddonList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [ageDifference, setAgeDifference] = useState();
  const [ageSame, setAgeSame] = useState();
  const [showModal, setShowModal] = useState();
  const [previousPremium , setPreviousPremium] = useState();
  const [extData , setExtData] = useState();


  const [finalAddonList, setFinalAddonList] = useState();


  const [proposalCorrect, setProposalCorrect] = useState();
  const [proposerCorrect, setProposerCorrect] = useState();

  const [summaryClick, setSummaryClick] = useState(false);
  

  const [saluteErrorProposer, setSaluteErrorProposer] = useState("");
  const [nameErrorProposer, setNameErrorProposer] = useState("");
  const [occuErrorProposer, setOccuErrorProposer] = useState("");
  const [dobErrorProposer, setDobErrorProposer] = useState("");
  const [weightErrorProposer, setWeightErrorProposer] = useState("");
  const [heightErrorProposer, setHeightErrorProposer] = useState("");
  const [maritalErrorProposer, setMaritalErrorProposer] = useState("");
  const [eduErrorProposer, setEduErrorProposer] = useState("");
  const [annualErrorProposer, setAnnualErrorProposer] = useState("");

  const [errorsProposal, setErrorsProposal] = useState({});

  let combinestates = {
    saluteErrorProposer,
    nameErrorProposer,
    occuErrorProposer,
    dobErrorProposer,
    weightErrorProposer,
    heightErrorProposer,
    maritalErrorProposer,
    eduErrorProposer,
    annualErrorProposer,
  };

  // valiadtion check functions here --------------------------------------
  const setErrorProposalByIndex = (proposalIndex, errorType, errorStr) => {
    setErrorsProposal(previousErrorsProposal=>{
      if(!previousErrorsProposal[proposalIndex])
      previousErrorsProposal[proposalIndex] = {};
      previousErrorsProposal[proposalIndex][errorType] = errorStr;
      return {...previousErrorsProposal}
    });
  }
  // ----------------------------------------

  const validateSalutation = (salutation, person, proposalIndex) => {
    if (person == "proposer") {
      if (!salutation) {
        setSaluteErrorProposer("Salutation is required.");
        return false;
      } else {
        setSaluteErrorProposer("");
        return true;
      }
    } else {
      if (!salutation) {
        setErrorProposalByIndex(proposalIndex,"saluteErrorProposal","Salutation is required.");
        return false;
      } else {
        setErrorProposalByIndex(proposalIndex,"saluteErrorProposal","");
        return true;
      }
    }
  };
  const validateFullName = (fullName, person, proposalIndex) => {
    if (person == "proposer") {
      const namePattern = /^[a-zA-Z\s.'-]+$/;

      if (!fullName?.trim()) {
        setNameErrorProposer("Full Name is required.");
        return false;
      } else if (!namePattern.test(fullName)) {
        setNameErrorProposer("Full Name contains invalid characters.");
        return false;
      } else {
        setNameErrorProposer("");
        return true;
      }
    } else {
      const namePattern = /^[a-zA-Z\s.'-]+$/;

      if (!fullName?.trim()) {
        setErrorProposalByIndex(proposalIndex,"nameErrorProposal","Full Name is required.");
        return false;
      } else if (!namePattern.test(fullName)) {
        setErrorProposalByIndex(proposalIndex,"nameErrorProposal","Full Name contains invalid characters.");
        return false;
      } else {
        setErrorProposalByIndex(proposalIndex,"nameErrorProposal","");
        return true;
      }
    }
  };
  const validateOccupation = (occupation, person, proposalIndex) => {
    if (person == "proposer") {
      if (!occupation) {
        setOccuErrorProposer("Please select an occupation.");
        return false;
      } else {
        setOccuErrorProposer("");
        return true;
      }
    } else {
      if (!occupation) {
        setErrorProposalByIndex(proposalIndex,"Occu","Please select an occupation.");
        return false;
      } else {
        setErrorProposalByIndex(proposalIndex,"Occu","");
        return true;
      }
    }
  };
  const validateDob = (value, person, proposalIndex) => {
    if (person == "proposer") {
      let ca = calculateAge(value);

      if (!value) {
        setDobErrorProposer("Date of birth cannot be blank");
        return false;
      }
      // const dateOfBirth = dayjs(value, "DD-MM-YYYY", true);
      // if (!dateOfBirth.isValid()) {

      //   setDobErrorProposer("Invalid Date of Birth format.");
      //   return false;

      // }

      if (ca > 99) {
        setDobErrorProposer(
          "Please select a valid date of birth (age must be at most 99)"
        );
        return false;
      } else {
        setDobErrorProposer("");
        return true;
      }
    } else {
      let ca = calculateAge(value);

      if (!value) {
        setErrorProposalByIndex(proposalIndex,"dobErrorProposal","Date of birth cannot be blank");
        return false;
      }
      // const dateOfBirth = dayjs(value, "DD-MM-YYYY", true);
      // if (!dateOfBirth.isValid()) {

      //   setDobErrorProposal("Invalid Date of Birth format.");
      //   return false;

      // }

      if (ca > 99) {
        setErrorProposalByIndex(proposalIndex,"dobErrorProposal",
          "Please select a valid date of birth (age must be at most 99)"
        );
        return false;
      } else {
        setErrorProposalByIndex(proposalIndex,"dobErrorProposal","");

        return true;
      }
    }
  };
  const validateWeight = (value, person, proposalIndex) => {
    if (person == "proposer") {
      if (!value) {
        setWeightErrorProposer("Weight can not be blank.");
        return false;
      } else if (!/^\d{1,3}$/.test(value)) {
        setWeightErrorProposer("Invalid weight (up to 3 digits)");
        return false;
      } else {
        setWeightErrorProposer("");
        return true;
      }
    } else {
      if (!value) {
        setErrorProposalByIndex(proposalIndex,"weightErrorProposal","Weight can not be blank.");
        return false;
      } else if (!/^\d{1,3}$/.test(value)) {
        setErrorProposalByIndex(proposalIndex,"weightErrorProposal","Invalid weight (up to 3 digits)");
        return false;
      } else {
        setErrorProposalByIndex(proposalIndex,"weightErrorProposal","");
        return true;
      }
    }
  };
  // const validateHeight = (value, person, proposalIndex) => {
  //   if (person == "proposer") {
  //     const heightPattern = /^[1-9]\d{0,1}(\.\d{1})?$/; // Matches numbers with up to 2 digits before the decimal and 1 digit after

  //     if (!value) {
  //       setHeightErrorProposer("Height cannot be blank");
  //       return false;
  //     }

  //     if (!heightPattern.test(value)) {
  //       setHeightErrorProposer("Invalid height format.");
  //       return false;
  //     }

  //     setHeightErrorProposer("");
  //     return true;
  //   } else {
  //     const heightPattern = /^[1-9]\d{0,1}(\.\d{1})?$/; // Matches numbers with up to 2 digits before the decimal and 1 digit after

  //     if (!value) {
  //       setErrorProposalByIndex(proposalIndex,"heightErrorProposal","Height cannot be blank");
  //       return false;
  //     }

  //     if (!heightPattern.test(value)) {
  //       setErrorProposalByIndex(proposalIndex,"heightErrorProposal","Invalid height format.");
  //       return false;
  //     }

  //     setErrorProposalByIndex(proposalIndex,"heightErrorProposal","");
  //     return true;
  //   }
  // };


  const validateHeight = (value, person, proposalIndex) => {
    // Updated regex to allow up to 2 digits after the decimal
    const heightPattern = /^[1-9]\d{0,1}(\.\d{1,2})?$/;
  
    if (person === "proposer") {
      if (!value) {
        setHeightErrorProposer("Height cannot be blank");
        return false;
      }
  
      if (!heightPattern.test(value)) {
        setHeightErrorProposer("Invalid height format.");
        return false;
      }
  
      setHeightErrorProposer("");
      return true;
    } else {
      if (!value) {
        setErrorProposalByIndex(
          proposalIndex,
          "heightErrorProposal",
          "Height cannot be blank"
        );
        return false;
      }
  
      if (!heightPattern.test(value)) {
        setErrorProposalByIndex(
          proposalIndex,
          "heightErrorProposal",
          "Invalid height format."
        );
        return false;
      }
  
      setErrorProposalByIndex(proposalIndex, "heightErrorProposal", "");
      return true;
    }
  };
  





  const validateMaritalStatus = (value, person, proposalIndex) => {
    if (person == "proposer") {
      if (!value) {
        setMaritalErrorProposer("Marital Status cannot be blank.'");
        return false;
      } else {
        setMaritalErrorProposer(""); // No errors
        return true;
      }
    } else {
      if (!value) {
        setErrorProposalByIndex(proposalIndex,"maritalErrorProposal","Marital Status cannot be blank.'");
        return false;
      } else {
        setErrorProposalByIndex(proposalIndex,"maritalErrorProposal",""); // No errors
        return true;
      }
    }
  };
  const validateEducation = (value, person, proposalIndex) => {
    if (person == "proposer") {
      if (!value) {
        setEduErrorProposer("Education cannot be blank");
        return false;
      }

      setEduErrorProposer(""); // No errors
      return true;
    } else {
      if (!value) {
        setErrorProposalByIndex(proposalIndex,"eduErrorProposal","Education cannot be blank");
        return false;
      }

      setErrorProposalByIndex(proposalIndex,"eduErrorProposal",""); // No errors
      return true;
    }
  };
  const validateAnnual = (value, person, proposalIndex) => {
    // console.log("the value in annual income", value);
    if (person == "proposer") {
      if (!value) {
        setAnnualErrorProposer("Annual Income  cannot be blank");
        return false;
      } else if (!/^\d{1,10}$/.test(value)) {
        setAnnualErrorProposer("Invalid annual income.");
        return false;
      } else {
        setAnnualErrorProposer("");
        return true;
      }
    } else {
      if (!value) {
        setErrorProposalByIndex(proposalIndex,"annualErrorProposal","Annual Income  cannot be blank");
        return false;
      } else if (!/^\d{1,10}$/.test(value)) {
        setErrorProposalByIndex(proposalIndex,"annualErrorProposal","Invalid annual income.");
        return false;
      } else {
        setErrorProposalByIndex(proposalIndex,"annualErrorProposal","");
        return true;
      }
    }
  };

  const goBack = () => {
    history(-1);
  };

  let data = localStorage.getItem("InsuredDetail");

  // let insuredData = JSON.parse(data);
  let insuredData = parseData(data)

  console.log("insureddata", insuredData)

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");



  const settingPrefieldData = (request) => {
    try {
      console.log("request in proposer", request)
        let genderDetect = request.Gender;
        let insuredData = request.InsuredDetail

        let spousePresent = insuredData.some(item =>
            item.InsuredRelation === "Husband" || item.InsuredRelation === "Wife"
        );

        // Update state only when proposerData exists
        setProposerData((prevData) => ({
            ...prevData,
            Salutation: genderDetect === "M" ? "1" : spousePresent ? "2" : "3",
            MaritalStatus: spousePresent ? "Married" : "Unmarried"
        }));
    } catch (error) {
        console.error("Error in setting prefilled data:", error);
    }
};


const updateInsuredDetailKey = (prevData, key, value, index) => {
  const updatedInsuredDetails = prevData?.InsuredDetail
    ? [...prevData?.InsuredDetail]
    : [];

  if (updatedInsuredDetails[index]) {
    updatedInsuredDetails[index] = {
      ...updatedInsuredDetails[index],
      [key]: value,
    };
  }

  return {
    ...prevData,
    InsuredDetail: updatedInsuredDetails,
  };
};

const settingMaritalData = (request)=>{
  try {

    console.log("proposerData in settingMaritalData", proposerData);
    console.log("the request :", request)

    let namedata = localStorage.getItem('ProposalName');
    let genderDetect = request.Gender;
    let insuredData = request.InsuredDetail;

    let spousePresent = insuredData.some(item =>
        item.InsuredRelation === "Husband" || item.InsuredRelation === "Wife"
    );

    insuredData.map((data,index)=>{

      if (data.InsuredRelation === "Self") {
      
        // updateInsuredData({
        //   index: index,
        //   data: [{ key: "Salutation", value: "3" }],
        // })
        // setProposerData((prevData) =>
        //   updateInsuredDetailKey(
        //     prevData,
        //     "Salutation",
        //     "2",
        //     index
        //   )
        // );

        setProposerData((prevData) =>
         ({
          ...prevData,
          ProposalName : namedata
         })
        );

        setProposerData((prevData) =>
          updateInsuredDetailKey(
            prevData,
            "InsuredName",
            namedata,
            index
          )
        );

        setProposerData((prevData) =>
          updateInsuredDetailKey(
            prevData,
            "Salutation",
            (genderDetect === "M" ? "1" : spousePresent ? "2" : "3"),
            index
          )
        );
      
    }
  //   if (data.InsuredRelation === "Self") {
      
  //     // updateInsuredData({
  //     //   index: index,
  //     //   data: [{ key: "Salutation", value: "3" }],

  //     // })

      

   
  //     setProposerData((prevData) =>
  //       updateInsuredDetailKey(
  //         prevData,
  //         "Salutation",
  //         "2",
  //         index
  //       )
  //     );
    
  // }

    if (data.InsuredRelation === "Wife") {
      
        // updateInsuredData({
        //   index: index,
        //   data: [{ key: "Salutation", value: "3" }],
        // })
        setProposerData((prevData) =>
          updateInsuredDetailKey(
            prevData,
            "Salutation",
            "2",
            index
          )
        );
      
    }
    if (data.InsuredRelation === "Husband") {
    
        // updateInsuredData({
        //   index: index,
        //   data: [{ key: "Salutation", value: "1" }],
        // })

        setProposerData((prevData) =>
          updateInsuredDetailKey(
            prevData,
            "Salutation",
            "1",
            index
          )
        );
  
    }
    if (data.InsuredRelation === "Mother") {
    
        // updateInsuredData({
        //   index: index,
        //   data: [{ key: "Salutation", value: "3" }],
        // })

        setProposerData((prevData) =>
          updateInsuredDetailKey(
            prevData,
            "Salutation",
            "2",
            index
          )
        );
  
    }
    if (data.InsuredRelation === "Father") {
    
        // updateInsuredData({
        //   index: index,
        //   data: [{ key: "Salutation", value: "1" }],
        // })
        setProposerData((prevData) =>
          updateInsuredDetailKey(
            prevData,
            "Salutation",
            "1",
            index
          )
        );
    
    }
    if (data.InsuredRelation === "Son") {
    
      // updateInsuredData({
      //   index: index,
      //   data: [{ key: "Salutation", value: "1" }],
      // })
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "Salutation",
          "1",
          index
        )
      );
  
    }
  if (data.InsuredRelation === "Daughter") {
    
    // updateInsuredData({
    //   index: index,
    //   data: [{ key: "Salutation", value: "1" }],
    // })
    setProposerData((prevData) =>
      updateInsuredDetailKey(
        prevData,
        "Salutation",
        "3",
        index
      )
    );

    }
    // Marridial Status Start Hear From Thair /////////////////////////////////////////////////

    if (data.InsuredRelation === "Self") {
     
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "MaritalStatus",
         spousePresent ? "Married" : "Unmarried",
          index
        )
      );
   
    }

    if (data.InsuredRelation === "Wife") {
     
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "MaritalStatus",
          "Married",
          index
        )
      );
   
    }
    if (data.InsuredRelation === "Husband") {
     
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "MaritalStatus",
          "Married",
          index
        )
      );
  
    }
    if (data.InsuredRelation === "Father") {
     
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "MaritalStatus",
          "Married",
          index
        )
      );
    }
    if (data.InsuredRelation === "Mother") {
    
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "MaritalStatus",
          "Married",
          index
        )
      );
 
    }
    if (data.InsuredRelation === "Daughter") {
    
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "MaritalStatus",
          "Unmarried",
          index
        )
      );
 
    }
    if (data.InsuredRelation === "Son") {
    
      setProposerData((prevData) =>
        updateInsuredDetailKey(
          prevData,
          "MaritalStatus",
          "Unmarried",
          index
        )
      );
 
    }
  })

 
    
  } catch (error) {
    
  }
}






  const getData = async (requestdata) => {
    try {
      console.log("requestdata in getdata", requestdata)
      setLoading(true);
      let response = await getReduxData(unique_id).then((response) => {
        // console.log("the response in get reduxdata", response.data.data[0].proposal_data=={})

        if(!(response.data.data[0].proposal_data).InsuredDetail){
          settingPrefieldData(requestdata);
          settingMaritalData(requestdata)
        }

        if (response?.data?.data[0]?.proposal_data) {
          // setProposerData(response.data.data[0].proposal_data);

          setProposerData((prevData) => ({
            ...prevData, // Spread the previous state
            ...response.data.data[0].proposal_data, // Overwrite or add new fields from the response
          }));

          setExtData(response?.data?.data[0]?.extra_data)
        }
        // if(response?.data?.data[0]?.proposal_data){
        //   if(!proposerData.AnnualIncome){
        //     return(
        //       <div>
        //       <Loader />
        //     </div>
        //     )
        //   }
        // }

        let pinCode = localStorage.getItem("PinCode");
        let InsuredDetail = localStorage.getItem('InsuredDetail');

        setProposerData((prevData) => ({
          ...prevData,
          PinCode: pinCode?.trim(),
        

        }));
        setLoading(false)
      });
    } catch (error) {
      console.log("the error", error);
    }
  };

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    // setStopLoading(true);
  };

  const fetchPlan = async () => {
    try {
      setLoading(true);
      // Start loading
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id).then(
        (response) => {
          console.log("the response in proposalForm", response);
          if (response.data.response == null) {
            // setStopLoading(true);

            handleError("Oops there is an Error");
          }

          getData(response.data.request)
          setApiResponse(response.data);
          setFinalAddonList(response.data.request.addonPrice)
          setAddonDataList(response.data.request.addonPrice);

          if(response.data.response){
            console.log("the previous premium", response.data.response);
            setPreviousPremium(response.data.response.premiumBreakup.totalPremium)
          }

          // alert(JSON.stringify(userdata));
          // setDeductibleVal(response.data.request.deductible ? response.data.request.deductible : 25000);
          // setHdcVal(response.data.request.HDCRiderSI?response.data.request.HDCRiderSI:1000)

          if (response.data.request.addons) {
            let currentAddons = response.data.request.addons
              ? response.data.request.addons.split(",")
              : [];

            setAddonArr(currentAddons);
          }
          setLoading(false);
        }
      );
    } catch (error) {
      console.log("the error in afterfetchplan", error);
    } finally {
      // setLoading(false); // Stop loading
    }
  };

  const getAddon = async () => {
    try {
      const response = await AddonApi(c_id, plan_id).then((response) => {
        setAddonList(response.addon_data.data);
      });
    } catch (error) {
      console.log("the error in addonlist");
    }
  };

  const settingData = async () => {
    try {
      console.log("the proposerData", proposerData);

      let phNo = localStorage.getItem("Proposalphonenumber");

      let payloadData = {
        proposal_data: proposerData,
        phone_no: proposerData?.Proposalphonenumber,
        
      };

      console.log("the payloaddata in settingdata", payloadData);

      let response = await settingReduxData(unique_id, payloadData).then(
        (response) => {
          console.log("the response in set reduxdata", response);
        }
      );
    } catch (error) {
      console.log("the error", error);
    }
  };

  console.log("the proposerData in proposalform", proposerData);

  const getSelfKey = (insuredData) => {
    for (let i = 0; i < insuredData.length; i++) {
      if (insuredData[i].InsuredRelation == "Self") {
        return i;
        break;
      }
    }
    return null;
  };

  useEffect(() => {
    fetchPlan();
    // getData();
    getAddon();
  }, []);

  if (!apiResponse) {
    return (
      <div>
            <>

<Redirect />
<div className="container">
  <div className="row">
  <div className="col-lg-8 ">

<div className="propodsal_formsklition">
<div className="headersklition_propsal">
<Skeleton variant="text" animation="wave" className="skallitonform1"/>
</div>
<div className="propsal_frobody">
<Skeleton variant="text" animation="wave" className="skallitonform2"/>
<Skeleton variant="text" animation="wave" className="skallitonform3"/>
<Skeleton variant="text" animation="wave" className="skallitonform4"/>
</div>
<div className="propsal_frobody">
<Skeleton variant="text" animation="wave" className="skallitonform2"/>
<Skeleton variant="text" animation="wave" className="skallitonform3"/>
<Skeleton variant="text" animation="wave" className="skallitonform4"/>
</div>
<div className="propsal_frobody">
<Skeleton variant="text" animation="wave" className="skallitonform2"/>
<Skeleton variant="text" animation="wave" className="skallitonform3"/>
<Skeleton variant="text" animation="wave" className="skallitonform4"/>
</div>
</div>



  </div>
  <div className="col-lg-4">
<div className="summerySklition">
  <div className="headerSklition">
  <Skeleton variant="rectangular" animation="wave" className="summeryheadtext"/>
  </div>
  <div className="summerysklitonbody">
  <Skeleton variant="circular" animation="wave"  className="summeryAfterFetchimages" />
  <Skeleton variant="rectangular" animation="wave" className="priceandtanureSummerySkalition"/>
  </div>
  <div className="sklitionbody2">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>

  </div>

  <div className="summeryfooterbody">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText3"/>

  </div>
</div>
  </div>
  </div>
  </div>
  </>
      </div>
    );
  }

 



  

  //  let validators = {

  //   validateSalutation,
  //   validateFullName,
  //   validateOccupation,
  //   validateDob,
  //   validateWeight,
  //   validateHeight,
  //   validateMaritalStatus,
  //   validateEducation,
  //   validateAnnual

  //  }

  console.log("proposerdata in parent",proposerData)

  return (
    <>

      <Helmet>
        <title>Proposal Form</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta
          name="keywords"
          content="Insurance Plan, Insurance Quotes, buy insurance"
        />
      </Helmet>

      {
         !loading ?
        <>
          <Redirect />
          <div className="container">
            <h1 className="mainheading">
              We Sell Happyness* <span className="middelheading">We Buy</span>
              <span className="endheading">Your Fear</span>{" "}
            </h1>

            <div className="row">
              <div className="col-lg-8 proposaldataform">
                <div className="nextsection ">
                  <h1 className="Proposalheader">
                    Proposal form (step 1 of 3)
                  </h1>

                  <ProposerForm
                    proposerData={proposerData}
                    setProposerData={setProposerData}
                    data={findSelf(insuredData)}
                    SelfKey={getSelfKey(insuredData)}
                    validateSalutation={validateSalutation}
                    validateFullName={validateFullName}
                    validateOccupation={validateOccupation}
                    validateDob={validateDob}
                    validateWeight={validateWeight}
                    validateHeight={validateHeight}
                    validateMaritalStatus={validateMaritalStatus}
                    validateEducation={validateEducation}
                    validateAnnual={validateAnnual}
                    validationstate={combinestates}
                  />
                  {insuredData.map((data, index) => (
                    <>
                      {data.InsuredRelation != "Self" ? (
                        <Proposal
                          // selfData={userdata}
                          data={data}
                          index={index}
                          key={index}
                          proposerData={proposerData}
                          setProposerData={setProposerData}
                          validateSalutation={validateSalutation}
                          validateFullName={validateFullName}
                          validateOccupation={validateOccupation}
                          validateDob={validateDob}
                          validateWeight={validateWeight}
                          validateHeight={validateHeight}
                          validateMaritalStatus={validateMaritalStatus}
                          validateEducation={validateEducation}
                          validateAnnual={validateAnnual}
                          validationsState={errorsProposal[index]}
                        />
                      ) : null}
                    </>
                  ))}
                </div>
                <div></div>
              </div>

              <div className="col-lg-4">
                {/* {//console.log("showmodal in proposal", showModal)} */}
                <Summary
                  apiResponse={apiResponse}
                  setApiResponse={setApiResponse}
                  ageDifference={ageDifference}
                  setAgeDifference={setAgeDifference}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  ageSame={ageSame}
                  setAgeSame={setAgeSame}
                  nextPage="medicaldetails"
                  planInfo={apiResponse}
                  addonsArray={addonArr.length != 0 ? addonArr : ""}
                  setAddonArr={setAddonArr}
                  addondataList={addonDataList}
                  setAddonDataList={setAddonDataList}
                  settingData={settingData}
                  proposerData={proposerData}
                  setProposerData={setProposerData}
                  // validators={validators}

                  errorsProposal = {errorsProposal}
                  validateSalutation={validateSalutation}
                  validateFullName={validateFullName}
                  validateOccupation={validateOccupation}
                  validateDob={validateDob}
                  validateWeight={validateWeight}
                  validateHeight={validateHeight}
                  validateMaritalStatus={validateMaritalStatus}
                  validateEducation={validateEducation}
                  validateAnnual={validateAnnual}

                  previousPremium={previousPremium}
                  socketManager={socketManager}
                  finalAddonList={finalAddonList}
                  setFinalAddonList={setFinalAddonList}
                  extData={extData}
                  setExtData={setExtData}
                />
              </div>
            </div>
          </div>
        </>:
        <Loader/>
      }
    </>
  );
};

export default Proposalfrom;