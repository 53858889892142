import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchPlanApi,
  UpdatePlanApi,
  updateTenure,
  updateSum_insured,
  updateFilter,
  updateOldSumTen,
  updatedPlanInfo,
  updateHdfcPremium,
  updateFullHdfcPremium,
  shortPrimium,
} from "../../redux/Slice";

import Loader from "../../../AllPageComponents/Loader";
import "./Filter.css";
// import { updateQuickQuote } from "../../redux/Slice";
import { AddAddOnApi, updateCoverAmount } from "../../redux/Slice";
import { GrFormDown } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { fetchQuickQuote, updateQuickQuote } from "../../Api/Api";





const Filter = ({
  apiResponse,
  setApiResponse,
  planData,
  setPlanData,
  onloadingData,
  companyData,
  setCompanyData,
  totalSkeletons,
  setTotalSkeletons,
  selectedCompanies,
  setSelectedCompanies,
  isConnected
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [CoverAmountData, setCoverAmountData] = useState("");
  const [tenureData, setTenureData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedCompanies, setSelectedCompanies] = useState("");
  const [sortingMethod, setSortingMethod] = useState();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const handleItemClick = (event, data) => {
    event.stopPropagation();

    console.log("the data", data);
    setCoverAmountData(data);
  };

  const handleTenureClick = (event, data) => {
    event.stopPropagation();

    console.log("the data", data);
    setTenureData(data);
  };

  const HandelShortingMethod = (event,data)=>{
    event.stopPropagation()
    setSortingMethod(data);
  }

  const handleMenuClick = (menuName) => {
    setOpenMenu((prevMenu) => (prevMenu === menuName ? null : menuName));
  };
  const crashinsurence = () => {
    // onDataFromChild("");
    setOpenMenu(false);
  };

  const Covaragedata = [
    {
      id: 5,
      name: "Recommended",
    },
    {
      id: 3,
      name: "Below ₹5 Lakh",
    },
    {
      id: 5,
      name: "₹5 - ₹9 Lakh",
    },
    {
      id: 10,
      name: "₹10 - ₹24 Lakh",
    },
    {
      id: 25,
      name: "₹25 - ₹99 Lakh",
    },
    {
      id: 100,
      name: "Above ₹1 Cr",
    },
  ];
  
  const ShortbyArray = [
    { id: 0, name: "Low to High", value: "asc" },
    { id: 1, name: "High to Low", value: "desc" },
  ];

  const TanureArray = [
    { id: 1, name: "1 Year" },
    { id: 2, name: "2 Year" },
    { id: 3, name: "3 Year" },
  ];

  const updateData = async (value) => {
    setTotalSkeletons(5);
    setPlanData({})
    try {
      // setIsLoading(true);
      let payload = {
        ...apiResponse.request,
        sum_insured: value,
      };
      let data = isConnected();
      console.log("the data in isconnected", data)
      if(data){
        const response = await updateQuickQuote(payload, unique_id).then(
          (response) => {
            setIsLoading(false);
            setApiResponse(response.data);
            // setPlanData(response.data.response);
            setCoverAmountData(response.data.request.sum_insured);
            console.log("the response in coverage", response);
            setSelectedCompanies([]) ; 
            resetCompany()
          }
        );
        return response;
      }
      else{
        return
      }
     
    } catch (error) {
      console.log("the error", error);
    }
  };

  const updateTenure = async (value) => {
    setPlanData({})
    setTotalSkeletons(5)
    try {
      // setIsLoading(true);
      let payload = {
        ...apiResponse.request,
        tenure: value,
      };
      let data = isConnected();
      console.log("the data in isconnected", data)

      if(data){
        const response = await updateQuickQuote(payload, unique_id).then(
          (response) => {
            setIsLoading(false);
            setApiResponse(response.data);
            // setPlanData(response.data.response);
  
            setCoverAmountData(response.data.request.sum_insured);
            setSelectedCompanies([]) ; 
            resetCompany()
  
            console.log("the response in coverage", response);
          }
        );
        return response;
      }
      else{
        return 
      }
    
    } catch (error) {
      console.log("the error", error);
    }
  };

  useEffect(() => {
    setCoverAmountData(apiResponse ? apiResponse.request.sum_insured : "");
    setTenureData(apiResponse ? apiResponse.request.tenure : "");
  }, []);

  const handleCompanySelect = (event, key) => {
    event.stopPropagation();
    if (selectedCompanies.includes(key)) {
      setSelectedCompanies((prev) => prev.filter((company) => company !== key));
    } else {
      setSelectedCompanies((prev) => [...prev, key]);
    }
  };

  // const applyFilter = () => {
  //   const newFilteredResponse = Object.keys(apiResponse.response)
  //     .filter((key) => selectedCompanies.includes(key))
  //     .reduce((obj, key) => {
  //       obj[key] = apiResponse.response[key];
  //       return obj;
  //     }, {});
  //   setPlanData(newFilteredResponse);
  // };



  const resetSorting = () =>{
    if(sortingMethod == 0 || 1 ){
      if(selectedCompanies.length != 0){
        const newFilteredResponse = Object.keys(companyData)
        .filter((key) => selectedCompanies.includes(key))
        .reduce((obj, key) => {
          obj[key] = companyData[key];
          return obj;
        }, {});
        setPlanData(newFilteredResponse)
        setSortingMethod('')
      }
      else{
        const newFilteredResponse = Object.keys(companyData).reduce((obj, key) => {
          obj[key] = companyData[key];
          return obj;
        }, {});
        setPlanData(newFilteredResponse)
        setSortingMethod('')
      }
     
    }
    else{
      return
    }
  }







  const resetCompany = () =>{
    if(selectedCompanies.length != 0){
      if(sortingMethod == 0 || sortingMethod == 1){
        if(sortingMethod == 0 ){
          let data = sortAndMergePlans(companyData , "asc" )

          setPlanData(data)

        }
        else{
          let data = sortAndMergePlans(companyData , "desc" )

          setPlanData(data)
        }
      }
      else{
        setPlanData(companyData);

      }
    }
    else{
      return
    }
  }

  const applyFilter = () => {
    if (selectedCompanies.length === 0) {
      // setPlanData(onloadingData);
      if(sortingMethod == 0 || sortingMethod == 1){
        if(sortingMethod == 0 ){
          let data = sortAndMergePlans(companyData , "asc" )

          setPlanData(data)

        }
        else{
          let data = sortAndMergePlans(companyData , "desc" )

          setPlanData(data)
        }
      }
      else{
        setPlanData(companyData);

      }

    } else {
      setTotalSkeletons(selectedCompanies.length);
      const newFilteredResponse = Object.keys(companyData)
        .filter((key) => selectedCompanies.includes(key))
        .reduce((obj, key) => {
          obj[key] = companyData[key];
          return obj;
        }, {});

        

        if(sortingMethod == 0 || sortingMethod == 1){
          if(sortingMethod == 0 ){
            let data = sortAndMergePlans(newFilteredResponse , "asc" )

            setPlanData(data)

          }
          else{
            let data = sortAndMergePlans(newFilteredResponse , "desc" )

            setPlanData(data)
          }
        }
        else{
          setPlanData(newFilteredResponse);

        }


    }
  };

  ///////////////// sorting filter function //////////////

  function sortCompaniesByFirstPlanPremium(planData, order) {
    // Convert the planData object into an array of entries (key-value pairs)
    const entries = Object.entries(planData);

    // Sort the array based on the totalPremium of the first plan in each company's array
    entries.sort(([, plansA], [, plansB]) => {
      // Get the totalPremium of the first plan in each company's array
      const premiumA =
        plansA.length > 0
          ? parseFloat(plansA[0].premiumBreakup.totalPremium)
          : Infinity;
      const premiumB =
        plansB.length > 0
          ? parseFloat(plansB[0].premiumBreakup.totalPremium)
          : Infinity;

      if (order === "asc") {
        return premiumA - premiumB;
      } else if (order === "desc") {
        return premiumB - premiumA;
      } else {
        throw new Error(
          "Invalid order specified. Use 'ascending' or 'descending'."
        );
      }
    });

    // Convert the sorted array back into an object
    return Object.fromEntries(entries);
  }

  // function sortInsurancePlansByPremium(data, isAscending ) {
  //   const sortedData = {};

  //   // Helper function to parse premium value
  //   const parsePremium = (premium) => parseFloat(premium.replace(/,/g, ''));

  //   // Sort each company's plans by totalPremium
  //   Object.keys(data).forEach(company => {
  //     sortedData[company] = data[company].sort((a, b) => {
  //       const totalPremiumA = parsePremium(a.premiumBreakup.totalPremium);
  //       const totalPremiumB = parsePremium(b.premiumBreakup.totalPremium);

  //       return isAscending ? totalPremiumA - totalPremiumB : totalPremiumB - totalPremiumA;
  //     });
  //   });

  //   // Sort companies based on the totalPremium of the first plan
  //   const sortedCompanies = Object.keys(sortedData).sort((a, b) => {
  //     const totalPremiumA = sortedData[a].length > 0 ? parsePremium(sortedData[a][0].premiumBreakup.totalPremium) : 0;
  //     const totalPremiumB = sortedData[b].length > 0 ? parsePremium(sortedData[b][0].premiumBreakup.totalPremium) : 0;

  //     return isAscending ? totalPremiumA - totalPremiumB : totalPremiumB - totalPremiumA;
  //   });

  //   // Rebuild the sorted data object based on sorted companies
  //   const finalSortedData = {};
  //   sortedCompanies.forEach(company => {
  //     finalSortedData[company] = sortedData[company];
  //   });

  //   alert(JSON.stringify(finalSortedData))
  //   return finalSortedData;
  // }

  function sortAndMergePlans(planData, order) {
    // Sort plans based on totalPremium value
    function sortArray(plans, order) {
      return plans.sort((a, b) => {
        const totalPremiumA = parseFloat(
          a.premiumBreakup.totalPremium.toString().replace(/,/g, "")
        );
        const totalPremiumB = parseFloat(
          b.premiumBreakup.totalPremium.toString().replace(/,/g, "")
        );
        return order === "asc" ? totalPremiumA - totalPremiumB : totalPremiumB - totalPremiumA;
      });
    }
  
    // Sort plans within each specific company
    const sortedData = JSON.parse(JSON.stringify(planData)); // Deep clone planData
    Object.keys(sortedData).forEach((company) => {
      sortedData[company] = sortArray([...sortedData[company]], order); // Sort each company's plans
    });
  
    // Flatten all plans into one array, sort, and then reconstruct the original format
    const allPlans = Object.values(planData).flat(); // Flatten to a single array of all plans
    const globallySorted = sortArray(allPlans, order); // Sort all plans globally
  
    // Merge sorted data with globally sorted plans to avoid duplicates and ensure correct order
    const mergedData = { ...sortedData };
  
    // Iterate over globally sorted plans and add them to the mergedData
    globallySorted.forEach((plan) => {
      if (!mergedData[plan.insurerName]) {
        mergedData[plan.insurerName] = [];
      }
  
      const existingPlans = mergedData[plan.insurerName].map((p) => p.planCode);
      if (!existingPlans.includes(plan.planCode)) {
        mergedData[plan.insurerName].push(plan);
      }
    });
  
    Object.keys(mergedData).forEach((company) => {
      mergedData[company] = sortArray(mergedData[company], order); // Sort each company's plans again
    });
  
    const sortCompaniesByFirstPlanPremium = (data, order) => {
      const sortedCompanies = Object.keys(data).sort((a, b) => {
        const premiumA = parseFloat(data[a][0]?.premiumBreakup?.totalPremium.toString().replace(/,/g, "") || 0);
        const premiumB = parseFloat(data[b][0]?.premiumBreakup?.totalPremium.toString().replace(/,/g, "") || 0);
        return order === "asc" ? premiumA - premiumB : premiumB - premiumA;
      });
  
      const sortedData = {};
      sortedCompanies.forEach((company) => {
        sortedData[company] = data[company];
      });
      return sortedData;
    };
  
    // Sort companies based on the first plan's premium
    let newData = sortCompaniesByFirstPlanPremium(mergedData, order);
  
    console.log("the newData", newData);
  
    return newData;
  }
  

  const applyshort = () => {
    try {
      if (sortingMethod === 0) {
        let data = sortAndMergePlans(planData, "asc");
        // let data = sortInsurancePlansByPremium(planData , true)
        console.log("asc dat", data);
        setPlanData(data);
      } else if (sortingMethod === 1) {
        let data = sortAndMergePlans(planData, "desc");
        // let data = sortInsurancePlansByPremium(planData , false)

        console.log("desc data", data);
        setPlanData(data);
      } else {
        return;
      }
    } catch (error) {}
  };

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpenMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

// Hamburger menu bar

const [rightOpen, setRightOpen] = React.useState(false);

const toggleDrawer = (open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }
  setRightOpen(open);
};




const [initialCoverAmount, setInitialCoverAmount] = React.useState('');
const [initialTenure, setInitialTenure] = React.useState('');
const [innitialsort, setInnitialsort] = React.useState('');
const [innitialselect, setInnitialselect] = React.useState('');


React.useEffect(() => {
  setInitialCoverAmount(CoverAmountData);
  setInitialTenure(tenureData);
  setInnitialsort(sortingMethod)
  setInnitialselect(selectedCompanies)
}, []);

const handleApply = () => {
  let hasCoverAmountChanged = CoverAmountData !== initialCoverAmount;
  let hasTenureChanged = tenureData !== initialTenure;
  let hasSortChnage = sortingMethod !== innitialsort;
  let hasSelectedCompanyCnage = selectedCompanies !== innitialselect

  if (hasCoverAmountChanged) {
    updateData(CoverAmountData);
    setInitialCoverAmount(CoverAmountData);
  }
  if (hasTenureChanged) {
    updateTenure(tenureData);
    setInitialTenure(tenureData);
  }
  if(hasSortChnage){
    applyshort()
    setInnitialsort(sortingMethod)
  }
  if(hasSelectedCompanyCnage){
    setInnitialselect(selectedCompanies)
    applyFilter()
  }
  setRightOpen(false)
}


const list = () => (
  <Box
      sx={{
        width: 340,
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        alignItems: 'center',
        height: '100vh',
        padding: 2,
        boxSizing: 'border-box'
      }}
      role="presentation"
      onClick={() => {}}
      onKeyDown={() => {}}
    >
      <List sx={{ width: '100%', padding: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Cover Amount */}
        <Box
          sx={{
            width: '100%',
            maxWidth: 300,
          }}
        >
          <InputLabel id="demo-simple-select-label" sx={{ backgroundColor: 'white' }}>
            Cover Amount
          </InputLabel>
          <FormControl
            sx={{
              border: '1px solid gray',
              borderRadius: 2,
              width: '100%',
              padding: 1,
              boxSizing: 'border-box'
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={CoverAmountData}
              label="CoverAmount"
              onChange={(event) => handleItemClick(event, event.target.value)}
            >
              {Covaragedata.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Tenure Amount */}
        <Box
          sx={{
            width: '100%',
            maxWidth: 300,
          }}
        >
          <InputLabel id="demo-simple-select-label" sx={{ backgroundColor: 'white' }}>
            Tenure Amount
          </InputLabel>
          <FormControl
            sx={{
              border: '1px solid gray',
              borderRadius: 2,
              width: '100%',
              padding: 1,
              boxSizing: 'border-box',
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tenureData}
              label="TenureAmount"
              onChange={(event) => handleTenureClick(event, event.target.value)}
            >
              {TanureArray.map((data) => (
                <MenuItem key={data.id} value={data.name}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Short by Data */}
        <Box
          sx={{
            width: '100%',
            maxWidth: 300,
          }}
        >
          <InputLabel id="demo-simple-select-label" sx={{ backgroundColor: 'white' }}>
          Short By
          </InputLabel>
          <FormControl
            sx={{
              border: '1px solid gray',
              borderRadius: 2,
              width: '100%',
              padding: 1,
              boxSizing: 'border-box',
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortingMethod === 0
                  ? "Low to High"
                  : "High to Low"}
              label="TenureAmount"
            >
              {ShortbyArray.map((data) => (
                <MenuItem key={data.id} value={data.name}   onClick={() => {
                  setSortingMethod(data.id);
                }}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Select Company array */}

        {Object.keys(apiResponse.response)
                    .filter((key) => apiResponse.response[key].length > 0)
                    .map((key) => (
                      <li
                        key={key}
                        className={
                          selectedCompanies.includes(key)
                            ? "listborder"
                            : "listborderdeactivate"
                        }
                        onClick={(event) => handleCompanySelect(event, key)}
                      >
                        <div
                          className={
                            selectedCompanies.includes(key)
                              ? "roundcireclactive"
                              : "roundcirecldeactive"
                          }
                          onClick={(event) => handleCompanySelect(event, key)}
                        ></div>
                        {key}
                      </li>
                    ))}



      </List>

      {/* Apply Button */}

      <div className="bottomactionfilter">
      <button className="Applybtnfilter" onClick={handleApply}>Apply</button>
      <button className="candelldbtnfilter"    onClick={() =>{ 
                      setSelectedCompanies([]) ; 
                      resetCompany()
                      setRightOpen(false)
                    }}
                      
                      >Cancelld</button>
      </div>



    </Box>
);










useEffect(()=>{

},[tenureData,CoverAmountData])










console.log("selected companies", selectedCompanies)


  return (
    <>
      {isLoading && <Loader />}
      <div className={`filterdies container ${false ? "fixed" : ""}`}>
        {/* {loadingUpdatedPlan && <Loader />} */}

        <button className="filterbtn" onClick={toggleDrawer(true)}>
          <CiFilter style={{fontWeight: "800",fontSize:'20pt'}}/> Filter
        </button>

        <Drawer
        anchor='right'
        open={rightOpen}
        onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>



        <div className="underfilterdiv">
          <span>Covarage</span>
          <div
            className="selectdives"
            onClick={() => handleMenuClick("coverage")}
          >
            {apiResponse.request.sum_insured} lakh
            <GrFormDown />
            {openMenu === "coverage" ? (
              <div className="menubar" ref={popupRef}>
                <div className="overflowmenubar">
                  {Covaragedata.map((data, index) => (
                    <li
                      key={data.id}
                      className={
                        "listborder"
                        //  "listborderdeactivate"
                      }
                      onClick={(event) => {
                        handleItemClick(event, data.id);
                      }}
                    >
                      <div
                        className={
                          CoverAmountData == data.id
                            ? "roundcireclactive"
                            : "roundcirecldeactive"
                        }
                      ></div>
                      {data.name}
                    </li>
                  ))}
                </div>

                <div className="buttondivs">
                  <button
                    className="Applybtn"
                    onClick={() => {
                      updateData(CoverAmountData);
                    }}
                  >
                    Apply
                  </button>
                  <button className="cancelld">Cancelld</button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="underfilterdiv">
          <span>Insure Company</span>
          <div
            className="selectdives"
            onClick={() => handleMenuClick("insurecompany")}
          >
            <p style={{ marginBottom: "0px" }}>
              {selectedCompanies.length >0 ? `${selectedCompanies.length} Company` :"Insure Company" }
          
            </p>{" "}
            <GrFormDown />
            {openMenu === "insurecompany" ? (
              <div className="menubar" ref={popupRef}>
                <div className="overflowmenubar">
                  {Object.keys(companyData)
                    .filter((key) => companyData[key].length > 0)
                    .map((key) => (
                      <li
                        key={key}
                        className={
                          selectedCompanies.includes(key)
                            ? "listborder"
                            : "listborderdeactivate"
                        }
                        onClick={(event) => handleCompanySelect(event, key)}
                      >
                        <div
                          className={
                            selectedCompanies.includes(key)
                              ? "roundcireclactive"
                              : "roundcirecldeactive"
                          }
                          onClick={(event) => handleCompanySelect(event, key)}
                        ></div>
                        {key}
                      </li>
                    ))}
                </div>
                <div className="buttondivs">
                  <button
                    // className={`${
                    //   selectedCompanies.length === 0
                    //     ? "dbuttonfilter"
                    //     : "Applybtn"
                    // }`}
                    className="Applybtn"
                    onClick={applyFilter}
                   // disabled={selectedCompanies.length === 0} 
                  >
                    Apply
                  </button>
                  {selectedCompanies.length == 0 && (
                    <button
                      className="cancelld"
                      onClick={() => setSelectedCompanies([])}
                    >
                      Cancel
                    </button>
                  )}
                  {selectedCompanies.length > 0 && (
                    <button
                      className="cancelld"
                      onClick={() =>{ 
                        setSelectedCompanies([]) ; 
                      resetCompany()}}
                    >
                      Reset
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="underfilterdiv">
          <span>Tenure</span>
          <div
            className="selectdives"
            onClick={() => handleMenuClick("tanure")}
          >
            {tenureData}
            <GrFormDown />
            {openMenu === "tanure" ? (
              <div className="menubar" ref={popupRef}>
                <div className="overflowmenubar">
                  {TanureArray.map((data) => {
                    const Dataid = data.name;
                    return (
                      <li
                        key={data.id}
                        className={
                          "listborder"
                          // "listborderdeactivate"
                        }
                        onClick={(event) => {
                          handleTenureClick(event, data.name);
                        }}
                      >
                        <div
                          className={
                            tenureData == data.name
                              ? "roundcireclactive"
                              : "roundcirecldeactive"
                          }
                        ></div>
                        {data.name}
                      </li>
                    );
                  })}
                </div>
                <div className="buttondivs">
                  <button
                    className="Applybtn"
                    onClick={() => {
                      updateTenure(tenureData);
                    }}
                  >
                    Apply
                  </button>
                  <button className="cancelld">Cancel</button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

       
<div className="underfilterdiv">
  <span>Sort By</span>
  <div className="selectdives" onClick={() => handleMenuClick("shortby")}>
    {sortingMethod
      ? sortingMethod === 0
        ? "Low to High"
        : "High to Low"
      : "Sort By"}
    &nbsp;
    <GrFormDown />
    {openMenu === "shortby" && (
      <div className="menubar" ref={popupRef}>
        <div className="overflowmenubar">
          {ShortbyArray.map((data) => (
            <li
              key={data.id}
              className="listborder"
              onClick={(event) => {HandelShortingMethod(event,data.id)}}>
              <div
                className={
                  sortingMethod === data.id
                    ? "roundcireclactive"
                    : "roundcirecldeactive"
                }
              ></div>
              {data.name}
            </li>
          ))}
        </div>
        <div className="buttondivs">
          <button className="Applybtn" onClick={applyshort}>
            Apply
          </button>

          {/* Display the Reset button when sortingMethod is either 0 (low to high) or 1 (high to low) */}
          {(sortingMethod === 0 || sortingMethod === 1) && (
            <button className="cancelld" onClick={resetSorting}>
              Reset
            </button>
          )}

          {/* Display the Cancel button only when sortingMethod is empty and Reset button is not visible */}
          {!sortingMethod && sortingMethod !== 0 && sortingMethod !== 1 && (
            <button className="cancelld" onClick={() => setSortingMethod('')}>
              Cancel
            </button>
          )}
        </div>
      </div>
    )}
  </div>
</div>

      </div>
    </>
  );
};

export default Filter;
