import React,{useState,useEffect} from 'react'
import '../TermSummeryPage/TermsummeryPage.css'
import { useSelector } from 'react-redux'
import Redirect from '../../AllPageComponents/Rederict';
import arrow from '../TermImages/arrow-animation.gif'
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import { CreateQuoteApiTerm, CreateQuotetionPayment } from '../TermApi/TermApi';
import { Helmet } from 'react-helmet-async';
import { formatToINR } from '../../HomePage/MoneyHealper/Moneyhelper';


const TermsummeryPage = () => {


  const { userData } = useSelector((state) => state.terms);
  const CalculteGst = (data) =>{
    return data * 0.18
  }
 



const Gender = localStorage.getItem('Gender');
const Name = localStorage.getItem('ProposalName');
const phone = localStorage.getItem('Proposalphonenumber');
const dob = localStorage.getItem('dateofbirth');
const smoke = localStorage.getItem('Smoke');
const education = localStorage.getItem('Education');
const occupation = localStorage.getItem('Occupation');
const AnnualIncome = localStorage.getItem('AnnualIncome');
const pinCode = localStorage.getItem('PinCode');
const State = localStorage.getItem('State');
const district = localStorage.getItem('District');
const userage = localStorage.getItem('ageadded');



const [isChecked, setIsChecked] = useState(true);

const handleCheckboxChange = () => {
  setIsChecked(!isChecked);
};

const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  const timer = setTimeout(() => {
    setIsLoading(false);
  }, 2000); // 2 seconds

  return () => clearTimeout(timer); // Cleanup timer on unmount
}, []);
const nav = useNavigate()

if(userData.userName === ''){
  nav('/term-insurance/termstep1')
}
console.log(userData)



const search = window.location.search;
const params = new URLSearchParams(search);
const unique_id = params.get("req_id");
const PlaneID = params.get('planeId')
const InsureCompany =params.get('company')
const LowercaseInsureCompany = InsureCompany.toLowerCase()

console.log(unique_id)
console.log(PlaneID)
console.log(InsureCompany)


const [planeData, setPlaneData] = useState({});
const [RequestState, setRequestState] = useState({})

const fetchQuotes = async () => {

  try {
    const data = await CreateQuoteApiTerm(unique_id);
    setPlaneData(data.data.response);
    setRequestState(data.data.request)
  } catch (err) {
   
  } finally {
 
  }
};

useEffect(()=>{
  fetchQuotes()
},[unique_id])

console.log("The Upcomeing responce",RequestState)


const [BasedPrimium, setBasedPremium] = useState(0)
useEffect(() => {
  // Calculate basedPremium from the fetched data
  const calculateBasedPremium = () => {
    let maxPremium = 0;
    Object.keys(planeData).forEach(key => {
      planeData[key].forEach(plan => {
        if (plan.premiumBreakup && plan.premiumBreakup.totalPremium > maxPremium) {
          maxPremium = plan.premiumBreakup.totalPremium;
        }
      });
    });
    setBasedPremium(maxPremium);
  };

  calculateBasedPremium();
}, [planeData]);
const CalculateGstPrice = CalculteGst(BasedPrimium)


const [planDetails, setPlanDetails] = useState([]);
useEffect(()=>{


const extractedPlans = Object.keys(planeData).flatMap((key) =>
  planeData[key].map((plan) => ({
    plan_code: plan.plan_code,
    plan_name: plan.plan_name
  }))
);

setPlanDetails(extractedPlans);

},[planeData])

console.log("PlaneArray",planDetails[0])
 







const userArray = 
  {
    gender: RequestState.gender,
    name:   RequestState.name,
    phone_no: RequestState.phone_no,
    dob: RequestState.dob,
    smoke: RequestState.smoke,
    education: RequestState.education,
    occupation: RequestState.occupation,
    annual_income: RequestState.annual_income,
    pin_code: pinCode,
    total_cover_amount: RequestState.total_cover_amount,
    policy_term: RequestState.policy_term,
    payment_frequency: RequestState.payment_frequency,
    plan_type: RequestState.plan_type,
    premium_paying_term: RequestState.premium_paying_term,
    pay_premium_as: RequestState.pay_premium_as,
    plan_code: planDetails[0] ? planDetails[0].plan_code : "",
    plan_name: planDetails[0] ? planDetails[0].plan_name : "",
    state: State,
    district: district,
    member: {
      name: RequestState.name,
      dob: RequestState.dob,
      gender: RequestState.gender,
      phone_no: RequestState.phone_no,
      relationWithLa: "Self"
    }
  }

console.log("The Width",userArray)






const [buyLink, setBuyLink] = useState('');
const [hasFetchedBuyLink, setHasFetchedBuyLink] = useState(false);

const handleApiCall = async () => {
  try {
    const response = await CreateQuotetionPayment(LowercaseInsureCompany, unique_id, userArray);
    if(response){

 
    setBuyLink(response.data.data.URL);
    setHasFetchedBuyLink(true); 
  }
  } catch (err) {
    console.log('The', err);
  }
};

useEffect(() => {
  if (!hasFetchedBuyLink) {
    handleApiCall();
  }
}, [planDetails[0]]);



// console.log('Buy Link',buyLink)



const handlpayment = () => {
  if (buyLink) {
    window.open(buyLink, '_blank'); // Open buyLink in a new tab
  } else {
    console.log('No link available');
  }
}


  return (
    <React.Fragment>
                  <Helmet>
        <title>Terminsurence summery</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
      <div className="colordivs">

  
      <Redirect/>

      <div className="container">
      <h5 className='colorflexdiv'>Confirm Your Details to Proceed with KYC</h5>
      </div>


<div className="userdataabddetails container">

<div className="userdetailsterm">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <span className='labelterm'>Gender</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={RequestState.gender} />
              )}
            </div>
            <div className="col-md-6">
              <span className='labelterm'>Date of Birth</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={RequestState.dob} />
              )}
            </div>
          </div>

          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <span className='labelterm'>Name</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={RequestState.name} />
              )}
            </div>
            <div className="col-md-6">
              <span className='labelterm'>User Age</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={userage} />
              )}
            </div>
          </div>

          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <span className='labelterm'>Annual Income</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={RequestState.annual_income} />
              )}
            </div>
            <div className="col-md-6">
              <span className='labelterm'>Smoke</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={RequestState.smoke === 'true' ? "Yes" : "No"} />
              )}
            </div>
          </div>

          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <span className='labelterm'>Qualification</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={RequestState.education } />
              )}
            </div>
            <div className="col-md-6">
              <span className='labelterm'>Occupation Type</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={RequestState.occupation} />
              )}
            </div>
          </div>

          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <span className='labelterm'>PinCode</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={pinCode} />
              )}
            </div>
            <div className="col-md-6">
              <span className='labelterm'>District</span>
              {isLoading ? (
                <Skeleton variant="text" sx={{ fontSize: '1rem', height: '45px' }} />
              ) : (
                <input type="text" className='inputcontainer' disabled value={district} />
              )}
            </div>
          </div>

          <div className="col-md-12 usermargin">
            <div className="col-md-6">
              <input 
                type='checkbox' 
                className='checkboxinput' 
                checked={isChecked} 
                onChange={handleCheckboxChange} 
              /> 
              <span className='labelterm'>I acknowledge that my KYC details will be fetched and may be used for eligibility purposes.</span>
            </div>
          </div>

          <div className="col-md-12 d-flex">
            <div className="col-md-6">

            {/* {buyLink ? (<a href={buyLink}>Buy</a>) : null}   */}
            <button
        className={isChecked ? 'nextstpebutton' : 'nextstpebuttondeactive'}
        disabled={!isChecked || !buyLink} // Disable button if not checked or if buyLink is not available
        onClick={handlpayment}
      >
        {buyLink ? (
          <>
            Next Step <img src={arrow} alt="Arrow" className='arrowimg' />
          </>
        ) : (
          "Please Wait"
        )}
      </button>
            </div>
          </div>
        </div>
      </div>
    </div>


<div className="ttermprimiumcover">

<div className="Brochure">
  <div className="borcunder">
<img src="https://static.insurancedekho.com/pwa/img/know-plan.svg" alt="" />
<span className='brouch'>Know Your Plan</span>

</div>
<img src="https://static.insurancedekho.com/pwa/img/ic_download.svg" alt="" />
</div>

<div className="Brochure">
  <div className="borcunder">
<img src="https://www.insurancedekho.com/investment-b2c-static/pwa/img/plan_brochure.svg" alt="" />
<span className='brouch'>Plan Brochure</span>

</div>
<img src="https://static.insurancedekho.com/pwa/img/ic_download.svg" alt="" />
</div>

<div>
      {Object.keys(planeData).length > 0 ? (
        Object.keys(planeData).map((key) => (
          <div key={key}>
            {planeData[key].map((plan, index) => {
           
          return(

              <div key={index} className="policydetailsterm">
                <div className="logoandname">
                  <img src={plan.insurerLogo} alt={plan.insurerName} />



                  
                  <p>{plan.plan_name}</p>
                  <span className='descriptiontermplnae'>{plan.planDescription}</span>
                </div>
                <hr />
                <div className="coverandcoveruptoterm">
                  <div className="termsubdiv">
                    <p>Cover</p>
                    <span>₹{plan.total_cover_amount} L</span>
                  </div>
                  <div className="termsubdiv">
                    <p>Cover upto</p>
                    <span>{plan.policy_term} Years</span>
                  </div>
                </div>
                <div className="coverandcoveruptoterm">
                  <div className="termsubdiv">
                    <p>Premium Frequency</p>
                    <span>Annually</span> {/* Adjust based on your actual data if available */}
                  </div>
                  <div className="termsubdiv">
                    <p>Premium Payment</p>
                    <span>{formatToINR(plan.premiumBreakup.totalPremium)}/-</span>
                  </div>
                </div>
              </div>
              )
          }
            
            
            
            
            )}
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>




<div className="termcovers">
  <div className="moneycovers">
  <span className='termcovertext'>Base Premium &nbsp;</span>
<span className='moneyamount'>
  {formatToINR(new Intl.NumberFormat('en-IN').format(BasedPrimium))}
</span>

  </div>
 
  <br />
  <div className="moneycovers">
    <div className="blockdivs">
    <span className='termcovertext'>Payable Amount &nbsp; </span>
 
    <br />
    <span className='gstspan'>Prices are inclusive of GST
</span>
    </div>
   <span className='moneyamount'>{formatToINR(new Intl.NumberFormat('en-IN').format((BasedPrimium)+CalculateGstPrice))}</span>

  </div>
</div>
</div>

</div>
</div>
</React.Fragment>  
  )
}

export default TermsummeryPage