import React, { useEffect } from "react";
import "../SummaryPage/Summeryinputbox.css";
import { useState } from "react";
import { afterPlanFetchApi } from "../../Api/Api";
import Loader from "../../../AllPageComponents/Loader";
// import { payload as icicipayload } from "../../helper/icici";
import axios from "axios";
import { sha512 } from "../../helper/common";
import { getReduxData } from "../../Api/Api";
// import { payload } from "../../helper/godigit";
// import { payload as carepayload } from "../../helper/carehealth";
import Redirect from "../../../AllPageComponents/Rederict";
import Summary from "../../commonComponents/Summary";
import { IoMdPerson } from "react-icons/io";
import Caremedical from "./MediacalSet/Caremedical";
import Icicimedical from "./MediacalSet/Icicimedical";
import Digitmedical from "./MediacalSet/Digitmedical";
import { MdOutlineEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { MdError } from "react-icons/md";
import { payload } from '../../helper/CreateQuotePayload';
import Relincemedical from './MediacalSet/Relincemedical';
import { settingReduxData } from '../../Api/Api';
import { Skeleton } from '@mui/material';
import { useNavigate } from "react-router-dom";

const Summarypage = ({ socketManager }) => {
  const [apiResponse, setApiResponse] = useState();
  const [beforeAddon, setBeforeAddon] = useState();
  const [addonArr, setAddonArr] = useState();
  const [addonPrev, setAddonPrev] = useState();
  const [addonDataList, setAddonDataList] = useState();
  const [addonsArray, setAddonsArray] = useState();
  const [proposerData, setProposerData] = useState();
  const [kycData, setKycData] = useState();

  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ICICIcheckedQuestion, setICICICheckedQuestion] = useState();
  const [godigitMedque, setGodigitMedque] = useState();
  const [careMedque, setCareMedque] = useState();
  const [reliancecheckedQuestion, setRelianceCheckedQuestion] = useState();
  const [HDFCcheckedQuestion, setHDFCcheckedQuestion] = useState([]);

  const [errormessage, setErrorMessage] = useState();

  const [isEmpty, setisEmpty] = useState(0);
  const [hdfcPremium, setHdfcPremium] = useState("");
  const [finalAddonList, setFinalAddonList] = useState();
  const [goGreenCheck, setGoGreenCheck] = useState(true);

  const [kycdoc, setKycDoc] = useState();
  const [kycPresent, setKycPresent] = useState();
  const [kycDocUpload, setKycDocUpload] = useState();
  const [urldata, setUrlData] = useState();
  const [extraData , setExtraData] = useState();
  const [extData , setExtData] = useState()

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  console.log("gogreen", goGreenCheck);

  const nav = useNavigate();

  const fetchPlan = async () => {
    try {
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id);

      setAddonsArray(response.data.request.addons);
      setBeforeAddon(response.data.response);
      setApiResponse(response.data);
      // setApiData(response.data)

      if (response) {
        setLoader(false);
      }
      setHdfcPremium(response.data.response.hdfc_premium);

      if (response.data.request.addons) {
        let currentAddons = response.data.request.addons
          ? response.data.request.addons.split(",")
          : [];

        setAddonArr(currentAddons);
        setAddonPrev(currentAddons);
      }
      if (response.data.request) {
        setFinalAddonList(response.data.request.addonPrice);

        setAddonDataList(response.data.request.addonPrice);
      }
    } catch (error) {
      console.log("the error in afterfetchplan", error);
    } finally {
    }
  };
  // This Use state is Used for fatching Proposar details
  const [ProposarData, setProposarData] = useState([]);

  // const getData = async () => {
  //   try {
  //     let proposalData;
  //     let response = await getReduxData(unique_id).then((response) => {
  //       setProposarData(response.data.data[0])
  //       if(response?.data?.data[0]?.kyc_upload_data) {
  //         setKycDoc(response?.data?.data[0]?.kyc_upload_data)
  //       }
  //       if(response?.data?.data[0]?.kyc_upload) {
  //         setKycPresent(response?.data?.data[0]?.kyc_upload)
  //       }

  //       if (response?.data?.data[0]?.proposal_data) {
  //         setProposerData(response.data.data[0].proposal_data);
  //         proposalData = response?.data?.data[0]?.proposal_data;
  //         setLoader(false)

  //       }

  //       if(c_id == "icici" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
  //         setICICICheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
  //       }
  //       if(c_id == 'godigit' && response?.data?.data[0]?.medical_question[c_id][plan_id] ){
  //         setGodigitMedque(response?.data?.data[0]?.medical_question[c_id][plan_id])
  //       }
  //       if(c_id == "care" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
  //         setCareMedque(response?.data?.data[0]?.medical_question[c_id][plan_id])
  //       }
  //       if(c_id == "reliance" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
  //         setRelianceCheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
  //       }
  //       if(c_id == "hdfc" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
  //         setHDFCcheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
  //       }
  //       if (response?.data?.data[0]?.kyc_data) {
  //         setKycData(response.data.data[0].kyc_data);
  //       }

  //      if(response?.data?.data[0]?.kyc_data?.kyc_photo && response?.data?.data[0]?.kyc_data?.kyc_photo_path &&
  //       response?.data?.data[0]?.kyc_data?.kyc_extension

  //       ){

  //         let photodata = response?.data?.data[0]?.kyc_data?.kyc_photo;
  //         let extdata = response?.data?.data[0]?.kyc_data?.kyc_extension;
  //         let pathdata = response?.data?.data[0]?.kyc_data?.kyc_photo_path ;

  //         console.log("photodata",photodata);
  //         console.log("extdata",extdata);
  //         console.log("pathdata",pathdata)

  //       const kycUploadData = {
  //         address_front: {
  //           document_data: await convertImageToBase64(photodata?.docfront),
  //           file_extension: extdata?.docfrontext,
  //           kyc_file_upload_path:pathdata?.docfrontpath
  //         },
  //         address_back: {
  //           document_data: await convertImageToBase64(photodata?.docback),
  //           file_extension: extdata?.docbackext,
  //           kyc_file_upload_path: pathdata?.docbackpath
  //         },
  //         identity_front: {
  //           document_data: convertImageToBase64(photodata?.idfront),
  //           file_extension: extdata?.idfrontext,
  //           kyc_file_upload_path:pathdata?.idfrontpath
  //         },
  //         identity_back: {
  //           document_data: convertImageToBase64(photodata?.idback) ,
  //           file_extension: extdata?.idbackext,
  //           kyc_file_upload_path: pathdata?.idbackpath
  //         }
  //       };
  //      console.log("kycUploadData", kycUploadData)

  //      }

  //     });
  //   } catch (error) {
  //     console.log("the error", error);
  //   }
  // };

  const getData = async () => {
    try {
      let proposalData;
      let response = await getReduxData(unique_id);

      setProposarData(response.data.data[0]);
      


        if(c_id == "icici" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
          setICICICheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
        }
        if(c_id == 'godigit' && response?.data?.data[0]?.medical_question[c_id][plan_id] ){
          setGodigitMedque(response?.data?.data[0]?.medical_question[c_id][plan_id])
        }
        if(c_id == "care" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
          setCareMedque(response?.data?.data[0]?.medical_question[c_id][plan_id])
        }
        if(c_id == "reliance" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
          setRelianceCheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
        }
        if(c_id == "hdfc" && response?.data?.data[0]?.medical_question[c_id][plan_id]){
          setHDFCcheckedQuestion(response?.data?.data[0]?.medical_question[c_id][plan_id])
        }

      if (response?.data?.data[0]?.kyc_upload_data) {
        setKycDoc(response?.data?.data[0]?.kyc_upload_data);
      }
      if (response?.data?.data[0]?.kyc_upload) {
        setKycPresent(response?.data?.data[0]?.kyc_upload);
      }
      if (response?.data?.data[0]?.extra_data) {
        setExtData(response.data.data[0].extra_data)
         
        }

      if (response?.data?.data[0]?.proposal_data) {
        setProposerData(response.data.data[0].proposal_data);
        proposalData = response?.data?.data[0]?.proposal_data;
        setLoader(false);
      }
      if (response?.data?.data[0]?.extra_data) {
        setExtraData(response.data.data[0].extra_data);
      
        setLoader(false);
      }
      
      if (response?.data?.data[0]?.kyc_data) {
        setKycData(response.data.data[0].kyc_data);
      }


      // If all required KYC data is available
      if (
        response?.data?.data[0]?.kyc_data?.kyc_photo &&
        response?.data?.data[0]?.kyc_data?.kyc_photo_path &&
        response?.data?.data[0]?.kyc_data?.kyc_extension
      ) {
        let photodata = response?.data?.data[0]?.kyc_data?.kyc_photo;
        let extdata = response?.data?.data[0]?.kyc_data?.kyc_extension;
        let pathdata = response?.data?.data[0]?.kyc_data?.kyc_photo_path;

        console.log("photodata", photodata);
        console.log("extdata", extdata);
        console.log("pathdata", pathdata);

        // Await all promises for Base64 conversion
        const kycUploadData = {
          // addressDocType : response?.data?.data[0]?.kyc_data?.addressDocType,
          // identityDocType : response?.data?.data[0]?.kyc_data?.identityDocType,
          address_front: {
            // document_data: await convertImageToBase64(photodata?.docfront), // Await Base64 conversion
            file_extension: extdata?.docfrontext,
            kyc_file_upload_path: pathdata?.docfrontpath,
            doc_type: response?.data?.data[0]?.kyc_data?.addressDocType,
          },
          address_back: {
            // document_data: await convertImageToBase64(photodata?.docback),  // Await Base64 conversion
            file_extension: extdata?.docbackext,
            kyc_file_upload_path: pathdata?.docbackpath,
            doc_type: response?.data?.data[0]?.kyc_data?.addressDocType,
          },
          identity_front: {
            // document_data: await convertImageToBase64(photodata?.idfront),  // Await Base64 conversion
            file_extension: extdata?.idfrontext,
            kyc_file_upload_path: pathdata?.idfrontpath,
            doc_type: response?.data?.data[0]?.kyc_data?.identityDocType,
          },
          identity_back: {
            // document_data: await convertImageToBase64(photodata?.idback),  // Await Base64 conversion
            file_extension: extdata?.idbackext,
            kyc_file_upload_path: pathdata?.idbackpath,
            doc_type: response?.data?.data[0]?.kyc_data?.identityDocType,
          },
        };

        console.log("kycUploadData", kycUploadData);
        setKycDocUpload(kycUploadData);
      }
    } catch (error) {
      console.log("Error loading data:", error);
    }
  };

  useEffect(() => {
    fetchPlan();
    getData();
  }, []);

  const [matchingData, setMatchingData] = useState([]);

  useEffect(() => {
    const medicalQuestions = ProposarData?.medical_question;

    if (medicalQuestions && medicalQuestions.hasOwnProperty(c_id)) {
      setMatchingData((prev) => [...prev, medicalQuestions[c_id]]);
    } else {
      console.log(`No matching key found for c_id: ${c_id}`);
    }
  }, [ProposarData, c_id]);

  console.log("C_id response", matchingData);

  // if (c_id === 'godigit') {
  //   const digitkey = matchingData[0];  // Assuming this is where it's coming from

  //   function getNestedKeys(obj) {
  //     let result = {};
  //     for (let key in obj) {
  //       if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null) {
  //         const nested = obj[key];
  //         for (let nestedKey in nested) {
  //           if (nested.hasOwnProperty(nestedKey) && typeof nested[nestedKey] === 'object') {
  //             result[nestedKey] = nested[nestedKey];
  //           }
  //         }
  //       }
  //     }

  //     return result;
  //   }
  //   const medicalData = medicaldetails.medicaldetails[0];
  //   const nestedKeys = getNestedKeys(medicalData);
  //   const InsureDetailsList = medicaldetails?.userfetchdata?.proposal_data?.InsuredDetail;
  // }

  useEffect(() => {
    function getNestedKeys(obj) {
      const result = {};
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          typeof obj[key] === "object" &&
          obj[key] !== null
        ) {
          const nested = obj[key];
          for (const nestedKey in nested) {
            if (
              nested.hasOwnProperty(nestedKey) &&
              typeof nested[nestedKey] === "object"
            ) {
              result[nestedKey] = nested[nestedKey];
            }
          }
        }
      }
      return result;
    }

    if (matchingData?.length > 0) {
      const medicalData = matchingData[0];
      let nestedKeys;
      let mappedData;

      if (c_id === "reliance") {
        nestedKeys = getNestedKeys(medicalData);
        mappedData = Object.values(nestedKeys).flat();
      } else if (c_id === "godigit") {
        nestedKeys = getNestedKeys(medicalData);
        mappedData = Object.values(nestedKeys).flat();
      } else if (c_id === "care") {
        nestedKeys = getNestedKeys(medicalData);
        mappedData = Object.values(nestedKeys).flat();
      } else if (c_id === "icici") {
        nestedKeys = getNestedKeys(medicalData);
        mappedData = Object.values(nestedKeys).flat();
      }

      if (mappedData) {
        setisEmpty(mappedData.length < 1 ? 1 : 0);
      }
    } else {
      setisEmpty(1);
    }
  }, [c_id, matchingData]);

  console.log("the care", isEmpty);



if(!apiResponse){
  return(
    <>
<Redirect />
<div className="container">
  <div className="row">
  <div className="col-lg-8 ">

<div className="propodsal_formsklition">
<div className="headersklition_propsal">
<Skeleton variant="text" animation="wave" className="skallitonform1"/>
</div>
<div className="propsal_frobody">
<Skeleton variant="text" animation="wave" className="skallitonform2"/>
<Skeleton variant="text" animation="wave" className="skallitonform3"/>
<Skeleton variant="text" animation="wave" className="skallitonform4"/>
</div>
<div className="propsal_frobody">
<Skeleton variant="text" animation="wave" className="skallitonform2"/>
<Skeleton variant="text" animation="wave" className="skallitonform3"/>
<Skeleton variant="text" animation="wave" className="skallitonform4"/>
</div>
<div className="propsal_frobody">
<Skeleton variant="text" animation="wave" className="skallitonform2"/>
<Skeleton variant="text" animation="wave" className="skallitonform3"/>
<Skeleton variant="text" animation="wave" className="skallitonform4"/>
</div>
</div>



  </div>
  <div className="col-lg-4">
<div className="summerySklition">
  <div className="headerSklition">
  <Skeleton variant="rectangular" animation="wave" className="summeryheadtext"/>
  </div>
  <div className="summerysklitonbody">
  <Skeleton variant="circular" animation="wave"  className="summeryAfterFetchimages" />
  <Skeleton variant="rectangular" animation="wave" className="priceandtanureSummerySkalition"/>
  </div>
  <div className="sklitionbody2">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>

  </div>

  <div className="summeryfooterbody">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText3"/>

  </div>
</div>
  </div>
  </div>
  </div>    </>
  )
}



  const callApi = async () => {
    setLoader(true);
    setLoading(true);

    let dataVal = await settingData().then((response) => {
      //console.log("fetchPlandata", fetchPlandata);
      //console.log("updatedPlandata", updatedPlandata);
      // //console.log('proposalData',proposalData);
      let final_payload = {};
      let url;
      if (c_id == "care") {
        final_payload = payload(
          // proposalData,
          proposerData,
          apiResponse,
          kycData,
          addonsArray,
          careMedque,
          c_id,
          kycdoc,
          kycPresent,
          kycDocUpload
        );
        console.log("the final care", final_payload);
        url =
          process.env.REACT_APP_BASE_URL +
          "health/createqoute/care/" +
          plan_id +
          "/" +
          unique_id;
        // url = "http://localhost/backend_api/api/v1/health/createqoute/care"
      } else if (c_id == "godigit") {
        final_payload = payload(
          // proposalData,
          proposerData,
          apiResponse,
          kycData,
          // proposalDataKyc,
          addonsArray,
          godigitMedque,
          c_id,
          kycdoc,
          kycPresent,
          kycDocUpload
        );
        url =
          process.env.REACT_APP_BASE_URL +
          "health/createqoute/godigit/" +
          plan_id +
          "/" +
          unique_id;
      } else if (c_id == "icici") {
        final_payload = payload(
          // proposalData,
          proposerData,
          apiResponse,
          kycData,
          addonsArray,
          ICICIcheckedQuestion,
          c_id,
          kycdoc,
          kycPresent,
          kycDocUpload
        );
        // data,PlanData,userdata,addonsArray,ICICIcheckedQuestion

        console.log("final payload icici", final_payload);
        url =
          process.env.REACT_APP_BASE_URL +
          "health/createqoute/icici/" +
          plan_id +
          "/" +
          unique_id;
      } else if (c_id == "hdfc") {
        final_payload = payload(
          proposerData,
          apiResponse,
          kycData,
          addonsArray,
          HDFCcheckedQuestion,
          c_id,
          kycdoc,
          kycPresent,
          kycDocUpload,
          goGreenCheck,
          extraData
        );

        console.log("final Payload", final_payload);
        url =
          process.env.REACT_APP_BASE_URL +
          "health/createqoute/hdfc/" +
          plan_id +
          "/" +
          unique_id;
      } else if (c_id == "reliance") {
        final_payload = payload(
          // proposalData,
          proposerData,
          apiResponse,
          kycData,
          addonsArray,
          reliancecheckedQuestion,
          c_id,
          kycdoc,
          kycPresent,
          kycDocUpload,
          goGreenCheck

          // proposalDataKyc,
          // addonsArray
          // ReliancecheckedQuestion
        );
        console.log("the final payload reliance", final_payload);
        url =
          process.env.REACT_APP_BASE_URL +
          "health/createqoute/reliance/" +
          plan_id +
          "/" +
          unique_id;
      }

      console.log("final_payload : ", final_payload);

      let data = JSON.stringify(final_payload);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log("the main kyc response", response.data);
          // alert(JSON.stringify(response.data))
          setLoader(false);
          // set_error(true, response.data.message);

          // setLoader(false)
          if (c_id == "care") {
            // window.location.href = "http://localhost/backend_api/api/v1/health/payment/care/"+response.data.intPolicyDataIO.policy.proposalNum;

            if (response.data.status == true) {
              window.location.href =
                process.env.REACT_APP_BASE_URL +
                "health/payment/care/" +
                response.data.data.intPolicyDataIO.policy.proposalNum +
                "/" +
                unique_id;
            } else if (response.data.status == false) {
              if(response.data.error_type == "kyc"){
                nav(`/kyc?req_id=${unique_id}&c_id=${c_id }&plan_id=${plan_id}&retry_kyc=${true}`)

              }
              else{
                setErrorMessage(response.data.message);

              }
              // set_error(true, response.data.message);
            } else {
              // alert(response.data.message)
              // set_error(true, response.data.message);
            }
          } else if (c_id == "godigit") {
            let resData = response.data.data;

            if (response.data.status == true) {
              console.log(
                "GoDigit paymentLink",
                resData["Retail Health-Create Quote"].paymentLink
              );

              if (resData.hasOwnProperty("Retail Health-Create Quote")) {
                window.location.href =
                  resData["Retail Health-Create Quote"].paymentLink;
              }
            } else if (response.data.status == false) {


              if(response.data.error_type == "kyc"){
                nav(`/kyc?req_id=${unique_id}&c_id=${c_id }&plan_id=${plan_id}&retry_kyc=${true}`)
  
               }
               else{
                setErrorMessage(response.data.message);
               
               }

              // setErrorMessage(response.data.message);
              // set_error(true, response.data.message);
            } else {
              // alert(resData.message)
              // set_error(true, response.data.message);
            }
          } else if (c_id == "icici") {
            if (response.data.status == true) {
              const correlationId = response.data.data.correlationId;
              // alert(correlationId)
              window.location.href =
                process.env.REACT_APP_BASE_URL +
                "health/payment/icici/" +
                correlationId +
                "/" +
                final_payload.Premium +
                "/" +
                response.data.data.proposalNumber +
                "/" +
                response.data.data.customerId +
                "/" +
                unique_id +
                "/" +
                plan_id;
            } else if (response.data.status == false) {
              // setErrorMessage(response.data.message);
              // set_error(true, response.data.message);
              if(response.data.error_type == "kyc"){
                nav(`/kyc?req_id=${unique_id}&c_id=${c_id }&plan_id=${plan_id}&retry_kyc=${true}`)

              }
              else{
                setErrorMessage(response.data.message);

              }
              // nav(`/kyc?req_id=${unique_id}&c_id=${c_id }&plan_id=${plan_id}&retry_kyc=${true}`)
            } else {
              // set_error(true, response.data.message);
            }
          } else if (c_id == "hdfc") {
            if (response.data.status == true) {
              // console.log(
              //   "HDFC PaymentTransactionNo",
              //   response.data.data.Data.PaymentTransactionNo
              // );
              const transactionNo =
                response.data.data.data.PaymentTransactionNo;
              const inputString =
                "GTOPHDFC|" + transactionNo + "|GTOP@7583|S001";
              sha512(inputString).then((hashedString) => {
                window.location.href =
                  process.env.REACT_APP_BASE_URL +
                  "health/payment/hdfc/" +
                  transactionNo +
                  "/S001/" +
                  hashedString +
                  "/" +
                  unique_id +
                  "/" +
                  plan_id;
              });
            } else if (response.data.status == false) {
              setErrorMessage(response.data.message);
              // set_error(true, response.data.message);
            } else {
              // alert(response.data.message)
              // set_error(true, response.data.message);
              setErrorMessage(response.data.message);
            }
          } else if (c_id == "reliance") {
            if (response.data.status == true) {
              console.log("reliance createqoute", response.data);
              let proposalAmount = response.data.data.Premium.FinalPremium;
              let kyc = response.data.data.ckyc;
              let proposalNo = response.data.data.Policy.ProposalNo;

              let url =
                process.env.REACT_APP_BASE_URL +
                "health/payment/reliance/" +
                proposalNo +
                "/" +
                proposalAmount +
                "/" +
                kyc +
                "/" +
                plan_id +
                "/" +
                unique_id;
              // alert(url);

              window.location.href = url;
            } else if (response.data.status == false) {

              // "/" +
              // nextPage +
              // "?req_id=" +
              // unique_id +
              // "&c_id=" +
              // c_id +
              // "&plan_id=" +
              // plan_id
              
            
             if(response.data.data.url && response.data.error_type == "kyc"){
              nav(`/kyc?req_id=${unique_id}&c_id=${c_id }&plan_id=${plan_id}&retry_kyc=${true}&url_data=${response.data.data.url}`)

             }
             else{
              setErrorMessage(response.data.message);
              setUrlData(response.data.data.url);
             }
             
              // set_error(true, response.data.message);
            } else {
              // alert(response.data.message)
              // set_error(true, response.data.message);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    });
  };


  function maskEmail(email) {
    const [localPart, domain] = email.split("@"); // Split into local and domain
    const maskedLocalPart =
      localPart[0] +
      "#".repeat(localPart.length - 2) +
      localPart[localPart.length - 1];
    return `${maskedLocalPart}@${domain}`;
  }

  const maskCardNumber = (cardNum) => {
    if (!cardNum || cardNum.length < 3) {
      return cardNum; // Return as is if the string is too short to mask
    }
    const firstLetter = cardNum[0]; // First letter
    const lastTwoLetters = cardNum.slice(-2); // Last two letters
    const maskedPart = "*".repeat(cardNum.length - 3); // Mask the rest
    return `${firstLetter}${maskedPart}${lastTwoLetters}`;
  };

  const getGender = (relation) => {
    const output = {
      Mother: "Female",
      Father: "Male",
      "Grand Father": "Male",
      "Grand Mother": "Female",
      Brother: "Male",
      Sister: "Female",
      Husband: "Male",
      Wife: "Female",
      "Father In Law": "Male",
      "Mother In Law": "Female",
      Uncle: "Male",
      Aunt: "Female",
      "Grand Daughter": "Female",
      "Grand Son": "Male",
      Son: "Male",
      Daughter: "Female",
    };
    return output[relation];
  };

  console.log("proposerData", proposerData);

  const settingData = async () => {
    try {
      console.log("the proposerData", proposerData);

      let phNo = proposerData.Proposalphonenumber;

      let dataPayload = {
        ...proposerData,
        physical_dispatch: goGreenCheck,
      };

      console.log("proposerdata physical", proposerData.physical_dispatch);
      console.log("datapayload", dataPayload);

      let payloadData = {
        proposal_data: dataPayload,
        phone_no: phNo,
      };

      let response = await settingReduxData(unique_id, payloadData).then(
        (response) => {
          console.log("the response in set reduxdata kycpage", response);
        }
      );
    } catch (error) {
      console.log("the error", error);
    }
  };

  return (
    <>
      <Redirect />

      {ProposarData && !loading ? (
        <div className="container">
          <div className="row blockinmobile">
            <div className="planesummery">
            {errormessage &&
  typeof errormessage === "string" &&
  (errormessage === "url data" ? (
    <span className="kyc-error-url">
      There is an issue with your online KYC. Kindly complete your KYC manually by{" "}
      <a href={urldata} className="kyc-error-click" target="_blank" rel="noopener noreferrer">
        clicking here
      </a>
      .
    </span>
  ) : (
    <div className="alert alert-danger d-flex align-items-center" role="alert">
      <MdError
        style={{
          width: "50px",
          height: "30px",
          marginRight: "15px",
        }}
      />
      <div>
        <span>{errormessage}</span>
      </div>
    </div>
  ))}

              <div className="label">
                Almost there! Please review your information before payment
              </div>

              {/* <img src={`${process.env.REACT_APP_SOCKET_URL}public/uploads/kyc_doc/1736847080096.png`}/> */}
              {/* Proposer Details */}
              <div className="planeshowpage">
                <div className="pagehader_everyone">
                  <h4 className="proposarlable">Proposer Details</h4>
                  <Link to="/healthStep1" className="editlink">
                    <MdOutlineEdit className="penicon" /> Edit
                  </Link>
                </div>
                <span className="proposaremail">
                  We have shared these details on your email{" "}
                  {ProposarData?.kyc_data?.Email
                    ? maskEmail(ProposarData.kyc_data.Email)
                    : "Fetching data"}
                </span>

                <div className="row py-3 justify-content-start">
                  {ProposarData?.proposal_data && (
                    <>
                      <div className="col-md-4 col-6">
                        <label className="proposerlabel">Name</label>
                        <p className="proposarvalue">
                          {ProposarData.proposal_data.ProposalName}
                        </p>
                      </div>
                      <div className="col-md-4 col-6">
                        <label className="proposerlabel">Weight</label>
                        <p className="proposarvalue">
                          {ProposarData.proposal_data.weight}
                        </p>
                      </div>
                      <div className="col-md-4 col-6">
                        <label className="proposerlabel">Gender</label>
                        <p className="proposarvalue">
                          {ProposarData.proposal_data.Gender === "M"
                            ? "Male"
                            : "Female"}
                        </p>
                      </div>
                      <div className="col-md-4 col-6">
                        <label className="proposerlabel">Date of Birth</label>
                        <p className="proposarvalue">
                          {ProposarData.proposal_data.dateOfbirth}
                        </p>
                      </div>
                      <div className="col-md-4 col-6">
                        <label className="proposerlabel">Height</label>
                        <p className="proposarvalue">
                          {ProposarData.proposal_data.height_feet} feet{" "}
                          {ProposarData.proposal_data.height_inch} inches
                        </p>
                      </div>
                      <div className="col-md-4 col-6">
                        <label className="proposerlabel">Phone Number</label>
                        <p className="proposarvalue">{ProposarData.phone_no}</p>
                      </div>
                      <div className="col-md-4 col-6">
                        <label className="proposerlabel">Doc Number</label>
                        <p className="proposarvalue">
                          {ProposarData.kyc_data
                            ? maskCardNumber(ProposarData.kyc_data.cardNum)
                            : "Loading..."}
                        </p>
                      </div>
                      <div className="col-md-12 col-12">
                        <label className="proposerlabel">Address</label>
                        <p className="proposarvalue">
                          {ProposarData.kyc_data.street}-
                          {ProposarData.proposal_data.District},{" "}
                          {ProposarData.proposal_data.State ||
                            ProposarData.proposal_data.Sate}{" "}
                          - {ProposarData.proposal_data.PinCode}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Insured Members */}
              <div className="planeshowpage">
                <div className="pagehader_everyone">
                  <h4 className="proposarlable">Insured Members</h4>
                  <Link
                    to={`/proposal?req_id=${unique_id}&c_id=${c_id}&plan_id=${plan_id}`}
                    className="editlink"
                  >
                    <MdOutlineEdit className="penicon" /> Edit
                  </Link>
                </div>

                {ProposarData?.proposal_data?.InsuredDetail?.map(
                  (data, index) => {
                    console.log("genderdata", data);
                    return (
                      <div key={index}>
                        <h6 className="insuredmember col-md-12">
                          <IoMdPerson style={{ marginRight: "5px" }} />

                          {data.InsuredName}
                          <span className="relation_tag">
                            ({data.InsuredRelation})
                          </span>
                        </h6>
                        <div className="row py-2 customeline">
                          <div className="col-md-3 col-6">
                            <label className="proposerlabel">
                              Date of Birth
                            </label>
                            <p className="proposarvalue">{data.dateOfbirth}</p>
                          </div>
                          <div className="col-md-3 col-6">
                            <label className="proposerlabel">Height</label>
                            {data.InsuredRelation === "Self" ? (
                              <>
                                <p className="proposarvalue">
                                  {ProposarData.proposal_data.height_feet}.
                                  {ProposarData.proposal_data.height_inch}{" "}
                                  inches
                                </p>
                              </>
                            ) : (
                              <p className="proposarvalue">
                                {data.height} inches
                              </p>
                            )}
                          </div>

                          <div className="col-md-3 col-6">
                            <label className="proposerlabel">Gender</label>
                            <p className="proposarvalue">
                              {data.InsuredRelation === "Self"
                                ? ProposarData.proposal_data.Gender === "M"
                                  ? "Male"
                                  : "Female"
                                : getGender(data.InsuredRelation)}
                            </p>
                          </div>
                          <div className="col-md-3 col-6">
                            <label className="proposerlabel">Weight (kg)</label>
                            <p className="proposarvalue">{data.weight}</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>

              {/* Nominee Section */}
              <div className="planeshowpage">
                <div className="pagehader_everyone">
                  <h4 className="proposarlable">Nominee</h4>
                  <Link
                    to={`/kyc?req_id=${unique_id}&c_id=${c_id}&plan_id=${plan_id}`}
                    className="editlink"
                  >
                    <MdOutlineEdit className="penicon" /> Edit
                  </Link>
                </div>
                <h6 className="insuredmember">
                  {ProposarData.kyc_data
                    ? ProposarData.kyc_data.nomineeName
                    : "Loading"}
                  <span className="relation_tag">
                    (
                    {ProposarData.kyc_data
                      ? ProposarData.kyc_data.nomineerelationship
                      : "loading"}
                    )
                  </span>
                </h6>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <label className="proposerlabel">Date of Birth</label>
                    <p className="proposarvalue">
                      {ProposarData.kyc_data
                        ? ProposarData.kyc_data.nomineeDateOfbirth
                        : "Loading"}
                    </p>
                  </div>
                  <div className="col-md-6 col-6">
                    <label className="proposerlabel">Address</label>
                    <p className="proposarvalue">
                      {ProposarData.kyc_data
                        ? ProposarData.kyc_data.nomineeAddress
                        : "Loading"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="planeshowpage" hidden={isEmpty === 1}>
                <div className="pagehader_everyone">
                  <h4 className="proposarlable">Medical Details</h4>
                  <Link
                    to={`/medicaldetails?req_id=${unique_id}&c_id=${c_id}&plan_id=${plan_id}`}
                    className="editlink"
                  >
                    <MdOutlineEdit className="penicon" /> Edit
                  </Link>
                </div>
                {/* Render the appropriate component based on `c_id` */}
                {c_id === "care" && (
                  <Caremedical
                    medicaldetails={matchingData}
                    userfetchdata={ProposarData}
                  />
                )}
                {c_id === "icici" && (
                  <Icicimedical
                    medicaldetails={matchingData}
                    userfetchdata={ProposarData}
                  />
                )}
                {c_id === "godigit" && (
                  <Digitmedical
                    medicaldetails={matchingData}
                    userfetchdata={ProposarData}
                  />
                )}
                {c_id === "reliance" && (
                  <Relincemedical
                    medicaldetails={matchingData}
                    userfetchdata={ProposarData}
                  />
                )}
              </div>
              {c_id === "care" && (
                <div className="planeshowpage">
                  <div className="pagehader_everyone">
                    <h4 className="proposarlable">Bank Details</h4>
                    <Link
                      to={`/medicaldetails?req_id=${unique_id}&c_id=${c_id}&plan_id=${plan_id}`}
                      className="editlink"
                    >
                      <MdOutlineEdit className="penicon" /> Edit
                    </Link>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <label className="proposerlabel">
                        Bank Account Number
                      </label>
                      <p className="proposarvalue">
                        {ProposarData.kyc_data
                          ? ProposarData.kyc_data.accountNumber
                          : "Loading"}
                      </p>
                    </div>

                    <div className="col-md-6 col-6">
                      <label className="proposerlabel">IFSC Code</label>
                      <p className="proposarvalue">
                        {ProposarData.kyc_data
                          ? ProposarData.kyc_data.ifscCode
                          : "Loading"}
                      </p>
                    </div>
                    <div className="col-md-12 col-12">
                      <label className="proposerlabel">Bank Name</label>
                      <p className="proposarvalue">
                        {ProposarData.kyc_data
                          ? ProposarData.kyc_data.bankName
                          : "Loading"}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Sidebar */}
            <div className="sidebarplane">
              <Summary
                apiResponse={apiResponse}
                planInfo={apiResponse}
                nextPage={"final"}
                callBack={callApi}
                finalAddonList={finalAddonList}
                setFinalAddonList={setFinalAddonList}
                setProposerData={setProposerData}
                proposerData={proposerData}
                settingData={settingData}
                goGreenCheck={goGreenCheck}
                setGoGreenCheck={setGoGreenCheck}
                extData={extData}
                setExtData={setExtData}
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
  };

export default Summarypage;
