import React, { useDebugValue, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  AddAddOnApi,
  AddOnApi,
  FetchPlanApi,
  selectProposal,
  updateProposalClick,
  updateInsuredData,
  updateInsuredMembers,
  updateTotalAmount,
  updatePremium,
  updateSumInsuredData,
  healthInsurePortData,
  updateFullHdfcPremium,
  updateInsuredArray,
  selectReliancecheckedQuestion,
  updateData,
} from "../redux/Slice";

import { Checkbox, FormControlLabel } from "@mui/material";
import { UpdatePlanAgeApi } from "../redux/Slice";
import Loader from "../../AllPageComponents/Loader";
import ProposalPop from "./ProposalPop";
import Swal from "sweetalert2";
import {
  proposerFormValidation,
  proposalFormValidation,
  kycFormValidation,
  getMaxAge,
} from "../helper/common";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import "react-toastify/dist/ReactToastify.css";
import "./Summery.css";
import calculateAge from "../pages/AfterPlanFetchPage/AgeModalHelper";
import AgeModal from "../pages/AfterPlanFetchPage/AgeModal";
import { getDefaultNormalizer } from "@testing-library/react";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { afterPlanFetchApi, AddonApi, updateDatabase } from "../Api/Api";
import { updateAddonApi } from "../Api/Api";
import { filterCompany, replaceCompany } from "../helper/common";
import { formatToINR } from "../../HomePage/MoneyHealper/Moneyhelper";
import { FaAngleDown } from "react-icons/fa6";
import { premiumDetails } from "../helper/common";
import { updatedLoginPopup, selectLoginForm } from "../../Health/redux/Slice";
import Login from "../../AllPageComponents/Login";

const Summary = ({
  setApiData,
  set_error,
  showModal,
  kycData,
  validateKycForm,
  goKyc,
  nextPage,
  callBack = null,
  setShowModal,
  setProceedClick,
  ageDifference,
  setAgeDifference,
  ageSame,
  setAgeSame,
  proposerCorrect,
  proposalCorrect,
  setProposerCorrect,
  setProposalCorrect,
  hdcData,
  planInfo,
  setApiResponse,
  apiResponse,
  setAddonArr,
  addonsArray,
  setOneData,
  setTwoData,
  setThreeData,
  hdcVal,
  deductibleVal,
  beforeAddon,
  setBeforeAddon,
  addonPrev,
  setAddonPrev,
  addondataList,
  setAddonDataList,
  addonlist,
  settingData,
  validators,
  proposerData,

  errorsProposal,
  validateSalutation,
  validateFullName,
  validateOccupation,
  validateDob,
  validateWeight,
  validateHeight,
  validateMaritalStatus,
  validateEducation,
  validateAnnual,
  socketManager,
  loading,
  setLoading,
  finalAddonList,
  setFinalAddonList,
  setAddonJson,
  apidata,
  setProposerData,

  previousPremium,
  proposerDataAfter,

  setPreviousAddon,
  previousAddon,
  addonJson,
  settingDataAfter,
  goGreenCheck,
  setGoGreenCheck,
  extData,
  setExtData
}) => {
  const [showpopup, setshowpopup] = useState(false);
  const [cisFileName, setCisFileName] = useState(null);
  const [afterAddon, setAfterAddon] = useState();
  const [bmiData, setBmiData] = useState([]);

  // -------------------------------------------------------------------

  const proposalData = useSelector(selectProposal);

  // const { validateSalutation , validateFullName , validateOccupation, validateDob , validateWeight , validateHeight, validateMaritalStatus , validateEducation , validateAnnual} = validators

  const ReliancecheckedQuestion = useSelector(selectReliancecheckedQuestion);

  const quickquote = useSelector((state) => state.FetchPlan.quickquote);

  const loginPopup = useSelector(selectLoginForm);
  ////console.log("the quickquote", quickquote)

  ////console.log("the proposalData in summary", proposalData);

  // const handelshowpopup = () => {
  //   // switch (datas) {
  //   //   case "Open":
  //   //     setshowpopup(true);
  //   //     break;
  //   //   case "Close":
  //   //     setshowpopup(false);
  //   //     break;
  //   //   default:
  //   //     setshowpopup("");
  //   // }
  //   setshowpopup(!showpopup)

  // };

  const [showPopup, setShowPopup] = useState(false);
  const handleShowPopup = () => {
    setShowPopup(true);
    document.body.classList.add("no-scroll"); // Add no-scroll class
    window.scrollTo(0, 0);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    document.body.classList.remove("no-scroll"); // Remove no-scroll class
  };

  useEffect(() => {
    return () => {
      // Clean up: Remove no-scroll class on unmount
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const [incomeTaxSlab80D, setIncomeTaxSlab80D] = useState(30);

  const [showprimium, setshowprimium] = useState(true);
  const [removeLoader, setRemoveLoaderer] = useState(false);
  const [newUserData, setNewUserData] = useState("");
  const [modalResponse, setModalResponse] = useState("");
  const [tAndC, setTAndC] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [showBmiPop, setShowBmiPop] = useState(false);

  // const [addonlist, setAddonList] = useState();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  let path = window.location.pathname;

  let bmidata;

  const proposalClick = useSelector((state) => state.FetchPlan.proposalClick);
  const sumInsuredData = useSelector((state) => state.FetchPlan.sumInsuredData);

  let HDCRiderPremium = useSelector((state) => {
    return state.FetchPlan.HDCRiderPremium;
  });

  const updateInsuredData = () => {
    let insuredArr = proposalData.InsuredDetail;
  };

  // const redirectIfCorrect = async () => {
  //   if (proposerCorrect && proposalCorrect) {
  //     setShowPop(true)
  //     setLoading(true); // Set loading to true when premiumChange is called
  //     try {
  //       // let changedPremium = await premiumChange(c_id, plan_id);

  //       // //console.log(
  //       //   "the changed cpremium in summary",
  //       //   changedPremium.payload.data[0].premiumBreakup.totalPremium
  //       // );

  //       // let changedPremiumData =
  //       //   changedPremium.payload.data[0].premiumBreakup.totalPremium;
  //       if (
  //         ageDifference &&
  //         ageDifference.length !== 0
  //         // && changedPremiumData !== totalPremium
  //       ) {
  //         setShowModal(true);
  //       } else {
  //         updateInsuredNormal();
  //         redirect(nextPage, unique_id, c_id, plan_id);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching premium:", error);
  //     } finally {
  //       setLoading(false); // Set loading to false when premiumChange is completed
  //     }
  //   }
  // };

  const checkLogin = (message) => {
    Swal.fire({
      title: "Warning!",
      text: message || "You must be logged in to continue.",
      icon: "warning", // Warning icon
    }).then((result) => {
      if (result.isDismissed) {
        // // If the popup is closed (cross button clicked), redirect to the login page
        // window.location.href = "/login"; // Replace with the appropriate login URL
      }
    });
  };

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    // setLoading(true);
  };

  // const redirectIfCorrect = async (callback) => {
  //   if (proposerCorrect && proposalCorrect) {
  //     // Set loading to true when premiumChange is called

  //     try {
  //       // let changedPremium = await premiumChange(c_id, plan_id);

  //       // let changedPremiumData = changedPremium.payload.data[0].premiumBreakup.totalPremium;
  //       if (ageDifference && ageDifference.length !== 0) {
  //         setShowModal(true);
  //       } else {
  //         setShowPop(true);
  //         setTimeout(() => {
  //           updateInsuredNormal();
  //           // Call the callback function for redirection if conditions are met
  //           redirect(nextPage, unique_id, c_id, plan_id);
  //         }, 3000);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching premium:", error);
  //     } finally {
  //       setLoading(false); // Set loading to false when premiumChange is completed
  //     }
  //   }
  // };

  // useEffect(() => {
  //   redirectIfCorrect();
  // }, [proposerCorrect]);

  // useEffect(() => {
  //   redirectIfCorrect();
  // }, [proposalCorrect]);

  // extra ------------------

  const removenum = (num) => {
    const numStr = num.toString();
    const numStrNoZeros = numStr.replace(/0/g, "");

    return numStrNoZeros || "0";
  };

  useEffect(() => {
    //console.log("the apiresponse in kyc data download", apiResponse);

    if (path.split("/").pop() == "summarypage") {
      if (c_id == "hdfc") {
        let data = JSON.stringify({
          InsuredNum: apiResponse?.request?.InsuredDetail.length,
          SumInsured: apiResponse?.response?.sumInsured
            ? apiResponse.response.sumInsured / 100000
            : 0,
          unique_id: unique_id,
          PlanCode: plan_id,
          addons: apiResponse?.request?.addons
            ? apiResponse.request.addons
            : "",
          HDCRiderSI: apiResponse?.request?.HDCRiderSI
            ? apiResponse.request.HDCRiderSI
            : 0,

          //  pass plancode  plancode ------------------------------------------------------------------------------------
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_BASE_URL + "health/hdfc_cis_download",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            ////console.log(JSON.stringify(response.data));

            if (response.data.status == true) {
              setCisFileName(response.data.data.file_name);
            } else {
            }
          })
          .catch((error) => {
            ////console.log(error);
          });
      }
    }
  }, []);

  useEffect(() => {
    // fetchPlan();
    // getAddon();
  }, []);
  //  redux work here ------------------------------------------

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { coverAmount, addondata } = useSelector((state) => {
    return state.FetchPlan;
  });
  const tenure = useSelector((state) => state.FetchPlan.tenure);

  const proposalError = useSelector(
    (state) => state.FetchPlan.proposalErrorResult
  );
  const proposerError = useSelector((state) => state.FetchPlan.errorResult);

  const totalAmount = useSelector(
    (state) => state.FetchPlan.financialData.totalAmount
  );

  const { addAddOnsdata, addAddOnsRequestdata, userdata, updateduserdata } =
    useSelector((state) => {
      return state.FetchPlan;
    });

  const { fetchPlandata } = useSelector((state) => {
    return state.FetchPlan;
  });

  let planData = planInfo.response;

  ////console.log("the plandata ", planData);

  const validCheckProposer = useSelector(
    (state) => state.FetchPlan.validCheckProposer
  );

  const portYourData = () => {
    dispatch(healthInsurePortData({ planData }));
  };

  // redux work end _________________________________________

  //  default form validation here before my work ----------------------------------------------

  const pFormValidation = () => {
    const result = proposerFormValidation(proposalData);
    const result_one = proposalFormValidation(proposalData.InsuredDetail);

    if (result != true) {
      return;
    }

    if (result_one != true) {
      return;
    }

    return true;
  };

  // const KYCformValidation = () => {
  //   const result = kycFormValidation(proposalData, c_id);

  //   let validKyc = validateKycForm();

  //   if (result == true && validKyc == true) {
  //     return true;
  //   } else {
  //     if (result != true) {
  //       toast.error(result + " is required");
  //     }

  //     return false;
  //   }
  // };

  // default end here ----------------------------------------------------------------

  // navigation to next page for all page  function -------------------------

  const redirect = (nextPage, unique_id, c_id, plan_id) => {
    // let arr = [];
    let isError = false;
    let seenInsuredRelations = new Set();

    if (nextPage == "summarypage") {
      // const validate = KYCformValidation();
      // setProceedClick(true);
      // if (validate != true) {
      //   isError = true;
      //   return;
      // }
      // if (tAndC == false) {
      //   return;
      // }
    }

    if (!isError) {
      // if (nextPage == "summarypage") {
      //   callBack();
      // } else {
      navigate(
        "/" +
          nextPage +
          "?req_id=" +
          unique_id +
          "&c_id=" +
          c_id +
          "&plan_id=" +
          plan_id
      );
      // }
    }
  };

  // navigation to next page function end ------------------------

  const { filters } = useSelector((state) => {
    return state.FetchPlan;
  });

  if (!c_id || !addAddOnsdata) {
    return null;
  }

  const totalPremium = addAddOnsdata.premiumBreakup
    ? addAddOnsdata.premiumBreakup.totalPremium || 0
    : totalAmount;

  let mainamount = planInfo.response.premiumBreakup.totalPremium;

  try {
    mainamount = mainamount.replace(/,/g, "");
  } catch {}

  const gst = parseInt(mainamount * 0.18);

  ////console.log("GST AMOUNT IS", gst);

  const OrigenalAmount = parseInt(mainamount);
  ////console.log(OrigenalAmount);

  const reduceamount = OrigenalAmount - gst;
  ////console.log(reduceamount);

  const sumInsured =
    coverAmount[c_id] != undefined
      ? coverAmount[c_id]
      : filters.sum_insured != 0
      ? filters.sum_insured
      : planData.si[0];

  //  some default work ends here -------------------------------------

  // addon remove function here -------------------------------------------

  const handleRemoveAddon = async (addon) => {
    try {
      let data = socketManager.isConnected();
      console.log("the data in isconnected", data);

      if (data) {
        let addonApiArray = apiResponse.request.addons.split(",");
        setLoading(true);
        let updatedAddonsArray = addonApiArray.filter(
          (code) => code !== addon.addonCode
        );
        let updatedAddons = updatedAddonsArray.join(",");

        console.log("updatedAddonsArr", updatedAddonsArray);

        //console.log("addondataList", addondataList);

        let updatedPriceAddon = finalAddonList?.addonlist.filter(
          (code) => code.addonCode !== addon.addonCode
        );
        console.log("updatedPriceAddon", updatedPriceAddon);

        let newAddonPrice = {
          ...finalAddonList,
          addonlist: updatedPriceAddon,
        };

        let payload;

        if (addon.addonCode == "deductible") {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            deductible: 0,
          };
        } else if (addon.addonCode == "HDCRiderSI") {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,

            HDCRiderSI: 0,
          };
        } else if (addon.addonName == "AD05" && c_id == "reliance") {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            voluntarydeductiblelimit: null,
          };
        } else if (addon.addonName == "AD03" && c_id == "reliance") {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            roomrent_type: null,
          };
        } else if (
          addon.addonName == "AD11" &&
          c_id == "reliance" &&
          ["28241", "28242", "28243"].includes(plan_id)
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            roomrent_type: null,
          };
        } else if (
          addon.addonName == "AD10" &&
          c_id == "reliance" &&
          ["28241", "28242", "28243"].includes(plan_id)
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            voluntarydeductiblelimit: null,
          };
        } else if (
          addon.addonName == "AD07" &&
          c_id == "reliance" &&
          plan_id == 2868
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            cdb_limit: null,
          };
        } else if (
          addon.addonName == "AD08" &&
          c_id == "reliance" &&
          plan_id != 2868
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            ped_waiting_period: null,
          };
        } else if (
          addon.addonName == "AD09" &&
          c_id == "reliance" &&
          plan_id != 2868
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            reductioninwaitingperiodlimit: null,
          };
        } else if (
          addon.addonName == "AD04" &&
          c_id == "reliance" &&
          plan_id == 2868
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            reductioninwaitingperiodlimit: null,
          };
        } else if (
          addon.addonName == "AD04" &&
          c_id == "reliance" &&
          plan_id != 2868
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            opd_sum_insured: null,
            opd_plan: null,
          };
        } else if (
          addon.addonName == "AD02" &&
          c_id == "reliance" &&
          plan_id != 2868
        ) {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
            super_charger_option: null,
          };
        } else {
          payload = {
            ...apiResponse.request,
            addons: updatedAddons,
            addonPrice: newAddonPrice,
          };
        }

        await updateAddonApi(unique_id, payload).then(async (responseData) => {
          // setLoading(false);

          //console.log("the responseData", responseData);

          // if(responseData.response == null){
          //   handleError("Opps there is an Error");
          //   return
          // }
          let val = {
            "c_id" : c_id,
            "plan_id" : plan_id,
            "show_price" : responseData?.request?.addons != "" ?false : true
          }
          setExtData(val)
          setAddonJson([...updatedPriceAddon]);
          // setPreviousAddon([...updatedAddonsArray]);
          setFinalAddonList((prevData) => ({
            ...prevData,
            addonlist: updatedPriceAddon,
          }));

          let newArr = addonsArray.filter((code) => code !== addon.addonName);

          //console.log("yhe newArrAddon", newArr);

          setAddonArr(newArr);

          //console.log("the data", responseData.response, c_id, plan_id);

          // setApiResponse(responseData);

          setApiResponse((prevState) => ({
            ...prevState,
            request: responseData.request,
          }));
          setApiData((prevState) => ({
            ...prevState,
            request: responseData.request,
          }));
          // setApiData({
          //   request: responseData.request,
          //   response: apiResponse.response,
          // });

          setAddonDataList(responseData.request.addonPrice);

          // setBeforeAddon(responseData.response);
          setAddonPrev(responseData.request.addons);

          let payloadData = {
            ...payload,
            addonPrice: newAddonPrice,
          };
          // await updateDatabase(unique_id, payloadData);

          // setLoading(false);

          if (responseData.request.tenure == "1 Year") {
            setOneData(responseData.response.premiumBreakup.totalPremium);
          } else if (responseData.request.tenure == "2 Year") {
            setTwoData(responseData.response.premiumBreakup.totalPremium);
          } else if (responseData.request.tenure == "3 Year") {
            setThreeData(responseData.response.premiumBreakup.totalPremium);
          }
        });
      } else {
        return;
      }
    } catch (error) {}
  };
  // const maxAge = getMaxAge(userdata.InsuredDetail);

  //console.log("planData in summary", planData);
  const maxAge = getMaxAge(planInfo.request.InsuredDetail);

  // Function to compare two objects for equality
  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  // function to check any agedifference present or not and open the modal ---------------------

  const checkAge = () => {
    setAgeDifference([]);
    setAgeSame([]);

    ageDifference = [];
    ageSame = [];

    proposerData.InsuredDetail.map((item, index) => {
      let ageNow = calculateAge(item.dateOfbirth);
      ////console.log("the ageNow", ageNow);

      ////console.log("the agedifference is ", age_gap);

      const isDuplicate = ageDifference.some((ageItem) =>
        isEqual(ageItem, item)
      );
      ////console.log("duplicate element is present or not ", isDuplicate);

      if (ageNow != item.InsuredAge && !isDuplicate) {
        ageDifference.push({ item: item, newAge: ageNow, index: index });
      } else {
        //todo
        ageSame.push(item);
      }

      //console.log("the agediffernce array - ", ageDifference);
      //console.log("the agesame array", ageSame);
      if (ageDifference.length != 0) {
        setAgeDifference(ageDifference);
      }

      if (ageSame.length != 0) {
        setAgeSame(ageSame);
      }
    });

    if (ageDifference.length != 0) {
      setShowModal(true);
    } else {
      settingData();
      redirect(nextPage, unique_id, c_id, plan_id);
    }
  };

  // --------------------------

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  // premium change function ----------------------------------------

  const updateAllInsuredAges = (insuredDetails, newAges) => {
    let updatedInsuredDetails = [];

    let insuredArr = JSON.parse(insuredDetails);
    insuredArr.forEach((insured, index) => {
      let ageChangeFound = false;
      for (var i = 0; i < newAges.length; i++) {
        let newAgeItem = newAges[i];

        //if index of current relation was found in the new ages list
        if (newAgeItem.index == index) {
          ageChangeFound = true;

          // Check if the age in the insuredDetails array is different from the corresponding newAge
          if (insured.InsuredAge !== newAgeItem.newAge) {
            // If ages are different, create a new object with the updated age and push it to the updatedInsuredDetails array
            updatedInsuredDetails.push({
              ...insured,
              InsuredAge: newAgeItem.newAge,
            });
          } else {
            // If ages are the same, push the original insured object to the updatedInsuredDetails array
            updatedInsuredDetails.push(insured);
          }
        }

        if (ageChangeFound) break;
      }
      if (!ageChangeFound) {
        updatedInsuredDetails.push(insured);
      }
    });

    return updatedInsuredDetails;
  };

  const premiumChange = async (companyName, planCode) => {
    // getting the new insuredage array from localstorage ---------------

    const storedInsuredDetail = localStorage.getItem("InsuredDetail");
    let parsedInsuredDetail = null;
    if (storedInsuredDetail) {
      parsedInsuredDetail = JSON.parse(storedInsuredDetail);
    }

    let updatedInsuredDetails = updateAllInsuredAges(
      storedInsuredDetail,
      ageDifference
    );

    //////console.log("the arr in useEffect", arr);
    ////console.log("the updated insured", updatedInsuredDetails);

    const updatedAddons = [...addonsArray];

    const updatedUserdata = {
      ...userdata,
      CompanyName: c_id,
      PlanCode: plan_id,
      InsuredDetail: updatedInsuredDetails,
      sum_insured: sumInsured,
      addons: updatedAddons.join(),
      deductible: updatedAddons.includes("deductible")
        ? proposalData.deductible
          ? proposalData.deductible
          : 0
        : 0,
      HDCRiderSI: updatedAddons.includes("HDCRiderSI")
        ? proposalData.HDCRiderSI
          ? proposalData.HDCRiderSI
          : 0
        : 0,
    };

    ////console.log("the updated userData", updatedUserdata);

    let responseData = await dispatch(UpdatePlanAgeApi(updatedUserdata)).then(
      (data) => {
        ////console.log("the new premium response", data);

        return data;
      }
    );

    return responseData;
  };

  // update the InsuredDetail in normal redirection clicking on proceed ----------

  const updateInsuredNormal = async () => {
    // getting the new insuredage array from localstorage ---------------
    const storedInsuredDetail = proposalData.InsuredDetail;
    let parsedInsuredDetail = null;

    const updatedUserdata = {
      ...proposalData,
      CompanyName: c_id,
      PlanCode: plan_id,
      InsuredDetail: proposalData.InsuredDetail,
    };

    ////console.log("the updated userData", updatedUserdata);

    updatedUserdata &&
      localStorage.setItem(
        "InsuredDetail",
        JSON.stringify(updatedUserdata.InsuredDetail)
      );

    // reducers to update insureddetail and totalpremium -------------------
    dispatch(updateInsuredArray(updatedUserdata.InsuredDetail));
    // dispatch(updateTotalAmount(changedPremiumData))
    // dispatch(updatePremium(changedPremiumData))
  };

  const cisDownload = () => {
    if (tAndC) {
      setTAndC(false);
    } else {
      setTAndC(true);

      if (cisFileName != null) {
        cisDownloadFile(cisFileName);
      }
    }
  };

  const cisDownloadFile = (file) => {
    let fileUrl =
      process.env.REACT_APP_MAIN_URL + "/healthpdf/HDFC/cis/" + file;

    // The name you want to give to the downloaded file
    const fileName = file + ".pdf";

    // Create a link element
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    a.target = "_blank";

    // Append the link to the body
    document.body.appendChild(a);

    // Simulate a click on the link
    a.click();

    // Remove the link from the body
    document.body.removeChild(a);
  };

  const showdata = () => {
    setshowprimium(!showprimium);
  };

  // const fetchPlan = async () => {
  //   try {
  //     // setLoading(true);
  //     // Start loading
  //     const response = await afterPlanFetchApi(unique_id, c_id, plan_id).then(
  //       (response) => {
  //         setLoading(false);
  //         // setBeforeAddon(response.data.response);

  //         //console.log("the response in proposalForm", response);
  //         setModalResponse(response.data);
  //       }
  //     );
  //   } catch (error) {
  //     //console.log("the error in afterfetchplan", error);
  //   } finally {
  //     // setLoading(false); // Stop loading
  //   }
  // };

  function checkAndRedirect(data, nextPage, unique_id, c_id, plan_id) {
    // Check if key "2" exists in the object
    if (data == []) {
      redirect(nextPage, unique_id, c_id, plan_id);
    } else {
      if (data["2"]) {
        // Check if the inner array of key "2" is empty
        if (data["2"]["0"]?.length > 0) {
          // If key "1" is present, check if its inner array is empty
          if (data["1"]) {
            if (data["1"]["0"]?.length === 0) {
              // If key "1" inner array is empty, show an alert
              // alert("Please fill the required details for key 1.");
              set_error(true, "all fields are required of question 1");

              return; // Do not call redirect
            }
          } else if (data["1"] == undefined) {
            // alert("Please fill the required details for key 1.");
            set_error(true, "Please fill up question 1");

            return;
          }
          // If key "1" is not present or its inner array is not empty, call the redirect function
          redirect(nextPage, unique_id, c_id, plan_id);
        } else {
          // If key "2" inner array is empty, call redirect directly
          redirect(nextPage, unique_id, c_id, plan_id);
        }
      } else {
        // If key "2" is not present, just call redirect directly
        redirect(nextPage, unique_id, c_id, plan_id);
      }
    }
  }

  // const handleButtonClick = () => {

  //   if (nextPage === "medicaldetails") {

  //     //console.log("proposerclick , proposalClick", proposerCorrect, proposalCorrect)

  //       setShowPop(true);

  //   } else {
  //     if (nextPage !== "medicaldetails") {
  //       if (nextPage === "kyc" && c_id === "reliance") {
  //         checkAndRedirect(
  //           ReliancecheckedQuestion,
  //           nextPage,
  //           unique_id,
  //           c_id,
  //           plan_id
  //         );
  //       } else {
  //         redirect(nextPage, unique_id, c_id, plan_id);
  //       }
  //     } else {
  //       checkAge();
  //       redirectIfCorrect();
  //       dispatch(updateProposalClick(true));
  //     }
  //   }
  // };

  const handlePopupClose = () => {
    setShowPop(false);

    // Proceed with the next actions after popup closes
    if (nextPage === "medicaldetails") {
      checkAge();
      // redirectIfCorrect();
      // dispatch(updateProposalClick(true));
    }
  };

  const truncateText = (text) => {
    return text.slice(0, 30) + "....";
  };

  const Taxbenifitslab = [
    {
      id: 1,
      value: 30,
      name: "30%",
    },
    {
      id: 2,
      value: 20,
      name: "20%",
    },
    {
      id: 3,
      value: 10,
      name: "10%",
    },
    {
      id: 4,
      value: 5,
      name: "5%",
    },
    {
      id: 5,
      value: 0,
      name: "0%",
    },
  ];

  const updateIncomeTaxSlab80D = (event) => {
    setIncomeTaxSlab80D(event.target.value);
  };

  const amount = planData.premiumBreakup.totalPremium;

  const cleanedAmount =
    typeof amount === "string" ? amount.replace(/,/g, "") : amount;

  // Convert cleaned amount to a numeric type
  const numericAmount = Number(cleanedAmount);

  const calculatedTax = (numericAmount * incomeTaxSlab80D) / 100;

  const doctorfee = 2000;

  const totalbenifits = calculatedTax;

  // const { validateSalutation , validateFullName , validateOccupation, validateDob , validateWeight , validateHeight, validateMaritalStatus , validateEducation , validateAnnual} = validators

  const checkForSelfInsured = (insuredList) => {
    return insuredList?.some(insured => insured.InsuredRelation === "Self");
  };

  const validationCheck = () => {
    // for proposer ------------------
    //console.log("the proposerData in validation", proposerData);

    let validProposal = [];

    let ProposerSalutation = validateSalutation(
      proposerData.Salutation,
      "proposer"
    );
    let ProposerFullName = validateFullName(
      checkForSelfInsured(proposerData.InsuredDetail)?proposerData.InsuredDetail[0].InsuredName:proposerData.ProposalName,
      "proposer"
    );
    let ProposerOccupation = validateOccupation(
      proposerData.Occupation,
      "proposer"
    );
    let ProposerDob = validateDob(proposerData.dateOfbirth, "proposer");
    let ProposerWeight = validateWeight(proposerData.weight, "proposer");
    let ProposerHeight = validateHeight(proposerData.height, "proposer");
    let ProposerMaritalStatus = validateMaritalStatus(
      proposerData.MaritalStatus,
      "proposer"
    );
    let ProposerEducation = validateEducation(
      proposerData.Education,
      "proposer"
    );
    let ProposerAnnual = validateAnnual(proposerData.AnnualIncome, "proposer");

    let validProposer =
      ProposerSalutation &&
      ProposerFullName &&
      ProposerOccupation &&
      ProposerDob &&
      ProposerWeight &&
      ProposerHeight &&
      ProposerMaritalStatus &&
      ProposerEducation &&
      ProposerAnnual;

    let data;

    proposerData.InsuredDetail.map((item, index) => {
      if (item.InsuredRelation != "Self") {
        let salute = validateSalutation(item.Salutation, "proposal", index);
        let fullName = validateFullName(item.InsuredName, "proposal", index);
        let dob = validateDob(item.dateOfbirth, "proposal", index);
        let weight = validateWeight(item.weight, "proposal", index);
        let height = validateHeight(item.height, "proposal", index);
        let marital = validateMaritalStatus(
          item.MaritalStatus,
          "proposal",
          index
        );

        data = salute && fullName && dob && weight && height && marital;

        validProposal.push(data);
      } else {
        validProposal.push(true);
      }
    });
    //console.log("validProposer", validProposer);

    let verifyData = validProposer && !validProposal.includes(false);
    //console.log("verifyData", verifyData);
    // if (verifyData) {
    //   setShowModal(true);
    // }
    return verifyData;
  };

  const kycValidationCheck = () => {
    // //console.log("the data in kyc", kycData);
    if (validateKycForm) {
      return validateKycForm();
    }
    return true;
  };

  function convertToNumber(formattedString) {
  
    const cleanedString = String(formattedString).replace(/,/g, "");

    // Convert the cleaned string to a number
    const numericValue = parseFloat(cleanedString);

    // Check if the result is a valid number
    if (isNaN(numericValue)) {
      throw new Error("Invalid input format");
    }

    return numericValue;
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="summer">
        <ToastContainer />
        <div className="summeryheding">
          <h1 className="Summerypage">summary</h1>
        </div>
        <div className="marginsum">
          <p className="summary-premium-para">
            {planData.insurerName} - {planData.planName}
          </p>
          <div className="summary-premium-data">
            <img src={planData.insurerLogo} />
            <div className="tanuresuminsurediv">
              <p className="suminsuredata">
                <span className="boldtext">Sum-Insured :</span>{" "}
                {planInfo.request.sum_insured} &nbsp;Lacks
              </p>
              <p className="tanuredata">
                <span className="boldtext">Tenure :</span>{" "}
                {planInfo.request.tenure}
              </p>
            </div>
          </div>
          <div className="topaddons">
            <h1 className="nfh1 sumadd">Select Add-Ons</h1>
            {finalAddonList?.addonlist &&
            finalAddonList?.addonlist.length > 0 ? (
              finalAddonList?.addonlist.map((addon, index) => {
                // //console.log("the addon element", addon);
                // const addonname =
                // finalAddonList?.addonlist && Array.isArray(finalAddonList?.addonlist)
                //     ? finalAddonList?.addonlist.find(
                //         (element) => element.addonCode === addon.addonName
                //       )?.name || ""
                //     : "";
                return (
                  <div className="row sumboxesss summary-sum" key={index}>
                    <div className="para summary-addon" key={index}>
                      <span className="addoncode addon-sapn">
                        {/* {//console.log(
                          "the proposaldaata in summary addon",
                          proposalData
                        )} */}
                        {/* {finalAddonList?.addonlist &&
                          finalAddonList?.addonlist.find(
                            (element) => element.addonCode == addon.addonName
                          ).name} */}
                        {addon.addonName}

                        {addon.addonName == "HDCRiderSI"
                          ? "(" + apiResponse.request.HDCRiderSI / 1000 + "K)"
                          : null}
                        {addon.addonName == "deductible"
                          ? "(" + apiResponse.request.deductible / 1000 + "K)"
                          : null}
                        {addon.addonName == "HDCRiderSI"
                          ? "(" + apiResponse.request.HDCRiderSI / 1000 + "K)"
                          : null}
                        {addon.addonName == "deductible"
                          ? "(" + apiResponse.request.deductible / 1000 + "K)"
                          : null}

                        {addon.addonCode == "AD05"
                          ? "(" +
                            apiResponse.request.voluntarydeductiblelimit /
                              1000 +
                            "K)"
                          : null}

                        {addon.addonCode == "AD10" && plan_id != 2868
                          ? "(" +
                            apiResponse.request.voluntarydeductiblelimit +
                            ")"
                          : null}

                        {addon.addonCode == "AD11" && plan_id != 2868
                          ? "(" + apiResponse.request.roomrent_type + ")"
                          : null}

                        {addon.addonCode == "AD07" && plan_id == 2868
                          ? "(" + apiResponse.request.cdb_limit / 1000 + "K)"
                          : null}

                        {addon.addonCode == "AD08" && plan_id != 2868
                          ? "(" + apiResponse.request.ped_waiting_period + ")"
                          : null}

                        {addon.addonCode == "AD09" && plan_id != 2868
                          ? "(" +
                            (apiResponse.request
                              .reductioninwaitingperiodlimit == "6 Months"
                              ? "6M"
                              : "12M") +
                            ")"
                          : null}

                        {addon.addonCode == "AD04" && plan_id == 2868
                          ? "(" +
                            (apiResponse.request
                              .reductioninwaitingperiodlimit == "12"
                              ? "12M"
                              : "24M") +
                            ")"
                          : null}

                        {addon.addonCode == "AD04" && plan_id != 2868
                          ? "(" +
                            apiResponse.request.opd_sum_insured / 1000 +
                            "K)"
                          : null}

                        {addon.addonCode == "AD02" && plan_id != 2868
                          ? "(" +
                            (apiResponse.request.super_charger_option ==
                            "Option 1"
                              ? "Opt-1"
                              : "Opt-2") +
                            ")"
                          : null}
                      </span>
                    </div>
                    <div className="text-right">
                      <span
                        className={
                          addon.addonValue < 0
                            ? "addon-price-data-negative"
                            : "addon-price-data"
                        }
                      >
                        {extData?.show_price && addon.addonValue != null ? `₹${Math.abs(addon.addonValue)}` : ""}
                      </span>
                      {removeLoader ? (
                        <Loader />
                      ) : nextPage == "proposal" ? (
                        <span
                          className="badge badge-warning"
                          onClick={() => handleRemoveAddon(addon)}
                        >
                          Remove
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {c_id == "Care" ||
                ["28241", "28242", "28243"].includes(plan_id) ? null : (
                  <div className="row sumboxesss">
                    <span className="col-12 addoncode">No Addon added</span>
                  </div>
                )}
              </>
            )}

            {c_id == "Care" ? (
              <>
                <div className="row sumboxesss">
                  <div className="col-12 para">
                    <span className="addoncode">Wellness Benefit</span>
                  </div>
                </div>
                <div className="row sumboxesss">
                  <div className="col-12 para">
                    <span className="addoncode">Air Ambulance</span>
                  </div>
                </div>
              </>
            ) : null}
            {c_id == "Care" && maxAge < 45 ? (
              <>
                <div className="row sumboxesss">
                  <div className="col-12 para">
                    <span className="addoncode">Cumulative Bonus Super</span>
                  </div>
                </div>
              </>
            ) : null}

            {c_id == "Care" && maxAge > 75 ? (
              <>
                <div className="row sumboxesss">
                  <div className="col-12 para">
                    <span className="addoncode">Cumulative Bonus Super</span>
                  </div>
                </div>
              </>
            ) : null}
            {c_id == "Reliance" && plan_id == "28241" ? (
              <>
                <div className="row sumboxesss">
                  <div className="col-12 para">
                    <span className="addoncode">More Time</span>
                  </div>
                </div>
              </>
            ) : null}
            {c_id == "Reliance" && plan_id == "28242" ? (
              <>
                <div className="row sumboxesss">
                  <div className="col-12 para">
                    <span className="addoncode">More Cover</span>
                  </div>
                </div>
              </>
            ) : null}
            {c_id == "Reliance" && plan_id == "28243" ? (
              <>
                <div className="row sumboxesss">
                  <div className="col-12 para">
                    <span className="addoncode">More Global</span>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <div className="topaddons primiumamount">
            <div
              className={`row sumtot ${
                !showprimium ? "animatonTopToBottom" : ""
              }`}
              hidden={showprimium}
            >
              <div className="col-6">
                <h1 className="nfh1 colortex">Policy Amount</h1>
              </div>
              <div className="col-6 ">
                {/* <h1 className="nfh1 Primium"> {formatToINR(reduceamount)}</h1> */}
                <h1 className="nfh1 Primium">
                  {" "}
                  {formatToINR(
                    convertToNumber(planData.premiumBreakup.totalPremium) -
                      convertToNumber(
                        premiumDetails(planData.premiumBreakup.totalPremium).gst
                      )
                  )}
                </h1>
              </div>
              {/* { proposalData.deductible ?
              
              <>
              <div className="col-6">
                <h1 className="nfh1 colortex">Deductible Amount</h1>
              </div>
              <div className="col-6 ">
                <h1 className="nfh1 Primium">â‚¹ {proposalData.deductible}</h1>
              </div> 
              </>
              : null} */}
              {/* proposalData.HDCRiderSI */}
              {proposalData.HDCRiderSI ? (
                <>
                  <div className="col-6">
                    <h1 className="nfh1 colortex">HDCRiderSI Amount</h1>
                  </div>
                  <div className="col-6 ">
                    <h1 className="nfh1 Primium"> {HDCRiderPremium}</h1>
                  </div>
                </>
              ) : null}
            </div>
            <div
              className={`row sumtot ${
                !showprimium ? "animatonTopToBottom" : ""
              }`}
              hidden={showprimium}
            >
              <div className="col-6">
                <h1 className="nfh1 colortex">GST Amount</h1>
              </div>
              <div className="col-6 ">
                <h1 className="nfh1 Primium">
                  {formatToINR(
                    premiumDetails(planData.premiumBreakup.totalPremium).gst
                  )}
                </h1>
              </div>
            </div>

            <div className="primiumbox">
              <span className="premiumtext col-6">Total premium</span>
              <div className="col-6 text-right  px-0">
                <span className="Primiumamount">
                  <strong>
                    {" "}
                    {formatToINR(planData.premiumBreakup.totalPremium)}
                  </strong>
                </span>{" "}
                <button className="showhidebutton" onClick={showdata}>
                  <IoIosArrowUp
                    className={showprimium ? "rotate-down" : "rotate-up"}
                  />
                </button>
              </div>
            </div>

            {path.split("/").pop() == "summarypage" ? (
              <div className="col-12 flexdisplaysummery">
                <div>
                  <Checkbox
                    name="t_and_c"
                    defaultChecked={tAndC}
                    onChange={(e) => cisDownload()}
                  />
                </div>

                <div>
                  <p>
                    I accept all the terms & conditions and confirm that I have
                    read and noted all the details mention in the customer
                    information sheet/know your policy.
                  </p>
                </div>
              </div>
            ) : null}

            {path.split("/").pop() == "summarypage" && c_id == "hdfc" ? (
              <div className="col-12 flexdisplaysummery">
                {console.log("the gogreen data", goGreenCheck)}
                <div>
                  <Checkbox
                    name="goGreen"
                    defaultChecked={goGreenCheck}
                    onChange={(e) => {
                      setGoGreenCheck(e.target.checked);
                    }}
                  />
                </div>

                <div>
                  <p>
                    Go Green and make a difference to our planet! We shall
                    provide you with a soft copy of your policy at your
                    registered e-mail id. Additionally, by unchecking the check
                    box, we understand that you wish to have a physical copy of
                    your policy. For details on the process to receive your
                    physical policy kindly visit the{" "}
                    <a
                      href="https://www.hdfcergo.com"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      target="_blank"
                    >
                      Help
                    </a>{" "}
                    section on{" "}
                    <a
                      style={{
                        textDecoration: "underline",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      href="https://www.hdfcergo.com"
                      target="_blank"
                    >
                      www.hdfcergo.com
                    </a>{" "}
                    or contact our customer care. Note: Soft copy of your policy
                    can be easily accessed at your fingertips to refer to terms
                    and conditions, for lodging claims, and for any other
                    service needs.
                  </p>
                </div>
              </div>
            ) : null}
          </div>

          <div className="summerysec">
            {/* <button
              className="sumbtn"
              onClick={async () => {
                if (nextPage != "medicaldetails") {
                  if (nextPage == "kyc" && c_id == "Reliance") {
                    settingData();
                    checkAndRedirect(
                      ReliancecheckedQuestion,
                      nextPage,
                      unique_id,
                      c_id,
                      plan_id
                    );
                  } else {
                    if (nextPage != "proposal" && nextPage != "final") {
                      //summary_page
                      let data = await settingData();
                      // if(data){
                      let allVerified = kycValidationCheck();
                      if (allVerified)
                        redirect(nextPage, unique_id, c_id, plan_id);
                      // }
                    }
                    if (nextPage == "final") {
                      if (localStorage.getItem("userToken")) {
                        if (tAndC) {
                          callBack();
                        } else {
                          checkLogin(
                            "Please check the box to agree to our Terms and Conditions."
                          );
                        }
                      } else {
                        dispatch(updatedLoginPopup(true));
                        // checkLogin("Kindly log in to continue.")
                      }
                    }
                    if (nextPage == "proposal") {
                      let data = await settingDataAfter(finalAddonList);
                      if (data?.status == true) {
                        console.log("the data in settingdataafter", data)
                        redirect(nextPage, unique_id, c_id, plan_id);
                      }
                    }

                    // redirect(nextPage, unique_id, c_id, plan_id);
                  }
                }

                if (nextPage == "medicaldetails") {
                  settingData();
                  let allVerified = validationCheck();

                  if (allVerified) {
                    setShowBmiPop(true);
                  }
                  // if(allVerified){
                  //   redirect(nextPage, unique_id, c_id, plan_id);

                  // }

                  // redirectIfCorrect();
                  // setProceedMedClick(true);
                  // dispatch(updateProposalClick(true));
                }
              }}
            >
              {path.split("/").pop() == "kyc"
                ? "Make Payment"
                : "Proceed to proposal"}
            </button> */}

<button
  className="sumbtn"
  onClick={async () => {
    if (nextPage !== "medicaldetails") {
      if (nextPage === "kyc" && c_id === "Reliance") {
        settingData();
        checkAndRedirect(
          ReliancecheckedQuestion,
          nextPage,
          unique_id,
          c_id,
          plan_id
        );
      } else {
        if (nextPage !== "proposal" && nextPage !== "final") {
          let data = await settingData();
          let allVerified = kycValidationCheck();
          if (allVerified) redirect(nextPage, unique_id, c_id, plan_id);
        }

        if (nextPage === "final") {
          if (localStorage.getItem("userToken")) {
            if (tAndC) {
              callBack();
            } else {
              checkLogin("Please check the box to agree to our Terms and Conditions.");
            }
          } else {
            dispatch(updatedLoginPopup(true));
          }
        }

        if (nextPage === "proposal") {
          let data = await settingDataAfter(finalAddonList); // Ensure we wait for data
          if (data?.status === true) {
            console.log("The data in settingDataAfter:", data);
            redirect(nextPage, unique_id, c_id, plan_id);
          }
        }
      }
    }

    if (nextPage === "medicaldetails") {
      settingData();
      let allVerified = validationCheck();
      if (allVerified) {
        setShowBmiPop(true);
      }
    }
  }}
>
  {path.split("/").pop() === "kyc" ? "Make Payment" : "Proceed to proposal"}
</button>
          </div>

          {/* <div className="summerysec">
            <button className="sumbtn" onClick={handleButtonClick}>
              {path.split("/").pop() === "kyc"
                ? "Make Payment"
                : "Proceed to proposal"}
            </button>
            <ProposalPop
              show={showPop}
              onClose={() => setShowPop(false)}
              onExited={handlePopupClose} // Trigger after popup has exited
              apiResponse={apiResponse}
            />
          </div> */}

          {/* <div className="portpolicy">
            <input type="checkbox" onClick={portYourData} />
            <span>
              port My Exsisting Cover Policy <a href="">terms</a>&
              <a href="">condition</a>
            </span>
          </div> */}
          <div className="bestoffer">
            <div className="loading-overlay"></div>
            <span className="BannerOffer">
              Get Upto{" "}
              <span className="amountcolor">{formatToINR(totalbenifits)}</span>{" "}
              in Benefit
            </span>
            <button className="popupbutton" onClick={handleShowPopup}>
              See How{" "}
            </button>
          </div>

          {showPopup && (
            <div className="backgroundblock">
              <div className="overlay" onClick={handleClosePopup}></div>
              <div
                className="summerypopup"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="headerpopup">
                  <span>Additional benefits you get</span>
                  <button onClick={handleClosePopup}>
                    <RxCross2 />
                  </button>
                </div>
                <div className="popupbodysummery">
                  <span className="summerydesxription">
                    Tax Benefits under 80D for slab
                    <select onChange={updateIncomeTaxSlab80D}>
                      {Taxbenifitslab.map((data) => {
                        return (
                          <option value={data.value} key={data.id}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                    <FaAngleDown color="#0166b3" fontSize={"10pt"} />
                  </span>

                  <span className="summerymoneyspan">
                    {formatToINR(calculatedTax)}
                  </span>
                </div>

                <div className="popupbodysummery">
                  <h4 className="h4summerypopup">Total benefits</h4>

                  {/* <div className="popupbodysummery">
        <span className="summerydesxription">Doctor Consultation Benefits
          
        </span> 
      
     <span className="summerymoneyspan">{formatToINR(doctorfee)}</span>


       </div> */}
                  <div className="popupbodysummery">
                    <h4 className="h4summerypopup">Total benefits</h4>
                    &nbsp;&nbsp;
                    <span className="summerymoneyspanspecial">
                      {formatToINR(totalbenifits)}
                    </span>
                  </div>
                  <div className="termsandconditionspansummery">
                    *Above calculations are basis the details mentioned in the
                    policy and/or fair value assumption. Terms and conditions
                    apply. #Tax benefits are subject to change in tax laws
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* {showPop && (
        <ProposalPop
          show={showPop}
          onClose={() => setShowPop(false)}
          // onExited={handlePopupClose} 
          // Trigger after popup has exited
          apiResponse={apiResponse}
        />
      )} */}

      {showBmiPop && (
        <ProposalPop
          show={showBmiPop}
          onClose={() => {
            setShowBmiPop(false);
            checkAge();
          }}
          // onExited={handlePopupClose}
          // Trigger after popup has exited
          apiResponse={apiResponse}
          proposerData={proposerData}
          bmidata={bmidata}
          bmiData={bmiData}
          setBmiData={setBmiData}
        />
      )}

      {loginPopup.show ? <Login /> : null}

      {showModal && (
        <AgeModal
          ageSame={ageSame}
          ageDifference={ageDifference}
          apiResponse={apiResponse}
          setApiResponse={setApiResponse}
          showModal={showModal}
          setShowModal={setShowModal}
          setProposerCorrect={setProposerCorrect}
          setProposalCorrect={setProposalCorrect}
          proposerData={proposerData}
          socketManager={socketManager}
          apidata={apidata}
          calculateAge={calculateAge}
          setProposerData={setProposerData}
          settingData={settingData}
          previousPremium={previousPremium}
        />
      )}
    </>
  );
};

export default Summary;
