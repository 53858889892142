import React, { useEffect } from "react";
import "./paymentcss.css";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { Helmet } from "react-helmet-async";

const PaymentFiled = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Payment failed</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta
          name="keywords"
          content="Insurance Plan, Insurance Quotes, buy insurance"
        />
      </Helmet>

      <div className="sucessdiv">
        <div className="col-md-">
          <div className="imagemotal">
            <div className="sucesspagess">
              <FastImage
                url={"Website%20Img/failde.gif?updatedAt=1718860539609"}
                width={auto}
                height={auto}
                alt={"ca-img"}
              />
            </div>
          </div>
          <div className="textssucess">
            <div style={{ textAlign: "center" }}>
              <h1>Payment Failed</h1>
              <p className="tnkp">Oops Somthing whent Wrong</p>
              <p className="gdwish">Plese Try Again After Some time !</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFiled;
