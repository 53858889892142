import React,{useEffect} from 'react';

import './paymentcss.css'
import FastImage from '../../FastImage';
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import { Helmet } from 'react-helmet-async';


const PaymentProcess = () => {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const policyNum = params.get('policyNum');
  const file = params.get('file');
  const company = params.get('company');
  const kycLink = params.get('kyc');
  
  const handleDownload = () => {

   let cobj = {care:"CareHealthinsurance",godigit:"godigit",icici:"icici",hdfc:"HDFC"}
   
    const externalUrl = process.env.REACT_APP_MAIN_URL+'/healthpdf/'+cobj[company]+'/'+file;
    window.location.href = externalUrl;
  };

  const kyc = () => {
    window.location.href = kycLink;
  }

  useEffect(()=>{
    window.scrollTo(0, 0)
    
   },[])


  return (
    <>
       <Helmet>
       <title>Payment process</title>




        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta name="keywords" content="Insurance Plan, Insurance Quotes, buy insurance" />
      </Helmet>
    <div className='sucessdiv'> 
    <div className='col-md-4'>
    </div>
    <div className='col-md-4'>
    <div className='imagemotal'> 
    <div className='sucesspagess'>
    <FastImage
            url={"Website%20Img/sucess.gif?updatedAt=1718860539691"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
    </div>
    </div>
    <div className="textssucess">
    <div style={{textAlign:'center'}}>
    <h1>Payment Successfull</h1>
    <p className='tnkp'>Thank You For choosing Beema1</p>
    <p className='gdwish'>we wish to stand beside you for your good and bad time</p>
    <p className='policynum'>Your Policy has been generated and going through the process
    </p>
    {/* {file != "" ? (<button onClick={handleDownload}>Download</button>) : "please contact support"}<br/><br/>
    {kycLink != "" ? (<button onClick={kyc}>KYC Link</button>) : null} */}
    </div>
    </div>
    </div>

    <div className='col-md-4'>
    <FastImage
            url={"Website%20Img/agent-img%20(2).webp?updatedAt=1718706083170"}
            width={auto}
            height={auto}
            alt={"ca-img"}
            />
    </div>

    </div>
    </>
  );
};

export default PaymentProcess;