import "../../CommonCssFiles/planstyle.css";
import { FormHelperText, TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  getDate,
  feetAndInchesToCm,
  cmToFeetAndInch,
} from "../../helper/common";
import calculateAge from "../AfterPlanFetchPage/AgeModalHelper";
import dayjs from "dayjs";
import {
  selectProposal,
  updateData,
  // updateInsuredData,
  updateName,
  updateProposalName,
  updateErrorResult,
  updateErrorResultProposal,
  updateProposalClick,
  getDateofbirth,
  updateValidCheckProposer,
  updateheight,
} from "../../redux/Slice";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useRef } from "react";
import { parseData } from "../../helper/common";

import {updateInsuredData} from "../../helper/common";

const Proposal = ({
  data,
  index,
  proposerData,
  setProposerData,
  validateOccupation,
  validateSalutation,
  validateFullName,
  validationsState,
  validateDob,
  validateWeight,
  validateHeight,
  validateMaritalStatus,
  validateEducation,
  validateAnnual,
  apiResponse
}) => {
  const [heightcm, setHeightCm] = useState();

  let pData = proposerData?.InsuredDetail
    ? proposerData.InsuredDetail[index]
    : "";

  console.log("the pData in proposal", pData);

  // useEffect(()=>{
  //   setProposerData((prevData) => ({
  //     ...prevData,
  //     InsuredDetail: proposerData?.InsuredDetail
  //       ? {
  //         ...proposerData.InsuredDetail, 
  //         proposalIndex: index
  //       } : {},
  //     }));
  // },[]);

  // useEffect(() => {
  //   if (data.InsuredRelation === "Wife") {
      
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "Salutation", value: "3" }],
  //       })
      
  //   }
  //   if (data.InsuredRelation === "Husband") {
    
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "Salutation", value: "1" }],
  //       })
  
  //   }
  //   if (data.InsuredRelation === "Mother") {
    
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "Salutation", value: "3" }],
  //       })
  
  //   }
  //   if (data.InsuredRelation === "Father") {
    
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "Salutation", value: "1" }],
  //       })
    
  //   }

  //   // Marridial Status Start Hear From Thair /////////////////////////////////////////////////

  //   if (data.InsuredRelation === "Wife") {
     
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "MaritalStatus", value: "Married" }],
  //       })
   
  //   }
  //   if (data.InsuredRelation === "Husband") {
     
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "MaritalStatus", value: "Married" }],
  //       })
  
  //   }
  //   if (data.InsuredRelation === "Father") {
     
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "MaritalStatus", value: "Married" }],
  //       })
  
  //   }
  //   if (data.InsuredRelation === "Mother") {
    
  //       updateInsuredData({
  //         index: index,
  //         data: [{ key: "MaritalStatus", value: "Married" }],
  //       })
 
  //   }
  // }, []);




// useEffect(()=>{
//   settingMaritalData();
// },[])


  const updateInsuredDetailKey = (prevData, key, value, index) => {
    const updatedInsuredDetails = prevData?.InsuredDetail
      ? [...prevData?.InsuredDetail]
      : [];

    if (updatedInsuredDetails[index]) {
      updatedInsuredDetails[index] = {
        ...updatedInsuredDetails[index],
        [key]: value,
      };
    }

    return {
      ...prevData,
      InsuredDetail: updatedInsuredDetails,
    };
  };

  // const handleValueSet = (heightInFeet) => {

  //   const totalInches = heightInFeet * 12; // Convert feet to inches
  //   const heightInCentimeters = totalInches * 2.54;

  //   const cm = parseFloat(heightInCentimeters.toFixed(2));

  //   setProposerData((prevData) =>
  //     updateInsuredDetailKey(prevData, "heightcm", cm, index)
  //   );
  //   setHeightCm(cm);
  // };

  const handleValueSet = (heightInFeet) => {
    // Split the input into feet and inches
  
    const feet = Math.floor(heightInFeet); 
    const inches = heightInFeet.split('.')[1];
    // const inches = (heightInFeet - feet) ; 
    console.log("inches", inches);
    let heightInCentimeters;
    if(inches){
       heightInCentimeters = (feet * 30.48) + (Number(inches) * 2.54);

    }
    else{
      heightInCentimeters = (feet * 30.48) 

    }



    const cm = parseFloat(heightInCentimeters.toFixed(2)); // Round to 2 decimal places
    // Update proposer data and local state
    setProposerData((prevData) =>
      updateInsuredDetailKey(prevData, "heightcm", cm, index)
    );
    setHeightCm(cm);
  };







  return (
    <>
      <p
        className="user-info"
        data-bs-toggle="collapse"
        href={`#${data.InsuredRelation.replace(/[^a-zA-Z0-9]/g, "")}`}
        role="button"
        aria-expanded="true"
        aria-controls={`${data.InsuredRelation.replace(/[^a-zA-Z0-9]/g, "")}`}
      >
        {data.InsuredRelation === "Wife" ? "Spouse" : data.InsuredRelation}(
        {data.InsuredAge == 0 ? "< 91 Days" : `${data.InsuredAge} Years`})
      </p>
      <div
        className="userhide collapse show m-10"
        // class={dataFull ? "collapse show m-10" : "collapse m-10"}
        id={`${data.InsuredRelation.replace(/[^a-zA-Z0-9]/g, "")}`}
      >
        <div>
          <>
            <div className="row ppv10">
              <div className="col-md-2 px-0 py-0">
                <div className="prpfbd" style={{ width: "100%" }}>
                  <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                    Salutation*
                  </span>
                  <Select
                    style={{ width: "100%" }}
                    id="standard-select-currency-native"
                    select
                    label="Salutation"
                    // defaultValue={
                    //   data.InsuredRelation === "Husband" ||
                    //   data.InsuredRelation === "Father"
                    //     ? 1
                    //     : data.InsuredRelation === "Mother" ||
                    //       data.InsuredRelation === "Wife"
                    //     ? 2
                    //     : (data.Salutation? data.Salutation : 0)
                    // }
                    // defaultValue={pData ? pData.Salutation : 0}
                    value={pData.Salutation || 0}
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                    error={validationsState?.saluteErrorProposal}
                    helperText={validationsState?.saluteErrorProposal}
                    // onChange={(e) => {
                    //   handler(e, "Salutation");
                    //   setSalute(e.target.value);
                    //   validateSaluitation(e.target.value);
                    // }}

                    onChange={(e) => {
                      const selectedValue = e.target.value;

                      validateSalutation(selectedValue,"proposal",index);
                      setProposerData((prevData) =>
                        updateInsuredDetailKey(
                          prevData,
                          "Salutation",
                          selectedValue,
                          index
                        )
                      );
                    }}
                  >
                    <MenuItem value={0}>Select</MenuItem>

                    <MenuItem
                      value={1}
                      // selected={
                      //   data.Salutation
                      //     ? data.Salutation == "1" ||
                      //       data.InsuredRelation === "Husband" ||
                      //       data.InsuredRelation === "Father" ||
                      //       data.InsuredRelation === "Son"
                      //     : null
                      // }
                    >
                      Mr.
                    </MenuItem>

                    <MenuItem
                      value={2}
                      //  selected={data.Salutation == "2"}
                    >
                      Mrs.
                    </MenuItem>

                    <MenuItem
                      value={3}
                      // selected={
                      //   data.Salutation == "3" ||
                      //   data.InsuredRelation === "Wife" ||
                      //   data.InsuredRelation === "Mother"
                      // }
                    >
                      Ms.
                    </MenuItem>
                  </Select>
                  {/* <p className="error">{validationsState?.saluteErrorProposal}</p> */}
                  {Boolean(validationsState?.saluteErrorProposal) && (
                    <FormHelperText error>
                      {validationsState?.saluteErrorProposal}
                    </FormHelperText>
                  )}
                </div>
              </div>

              <div className="col-md-5  px-0 py-2">
                <div className="prpfbd" style={{ width: "100%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Full Name"
                    value={pData.InsuredName || ""}
                    variant="standard"
                    // value={data.InsuredName}
                    error={validationsState?.nameErrorProposal}
                    // helperText={validationsState?.nameErrorProposal}
                    required
                    // onChange={(e) => {
                    //   handler(e, "InsuredName");
                    //   setNameData(e.target.value);
                    //   validateFullName(e.target.value);
                    // }}

                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      validateFullName(selectedValue,"proposal",index);
                      setProposerData((prevData) =>
                        updateInsuredDetailKey(
                          prevData,
                          "InsuredName",
                          selectedValue,
                          index
                        )
                      );
                    }}
                  />
                  {Boolean(validationsState?.nameErrorProposal) && (
                    <FormHelperText error>
                      {validationsState?.nameErrorProposal}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="col-md-5 px-0">
                <div className="prpfbd" style={{ width: "100%" }}>
                  {/* <TextField
                style={{width:"100%"}}
                  id="standard-select-currency-native"
                  select
                  label="MaritalStatus"
                  SelectProps={{
                    native: true,
                  }}
                  error={maritalError}
                  helperText={maritalError}
                  variant="standard"
                  required
                  onChange={(e) => {
                    handler(e, "MaritalStatus");
                    setMaritaldata(e.target.value);
                    validateMarital(e.target.value);
                  }}

                  
                >
                  <option value="0">select</option>
                  <option
                    value="Married"
                    selected={
                      data.MaritalStatus == "Married" ||
                      data.InsuredRelation === "Wife" ||
                      data.InsuredRelation === "HUsband" ||
                      data.InsuredRelation === "Father" ||
                      data.InsuredRelation === "Mother"
                    }
                  >
                    Married
                  </option>
                  <option
                    value="Unmarried"
                    selected={data.MaritalStatus == "Unmarried"}
                  >
                    Unmarried
                  </option>
                </TextField> */}

                  {/* <span style={{color:'#2959B8',fontSize:'8pt'}}>MaritalStatus*</span> */}
                  <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                    MaritalStatus*
                  </span>
                  <Select
                    style={{ width: "100%" }}
                    id="standard-select-currency-native"
                    label="MaritalStatus"
                    // defaultValue={
                    //   data.MaritalStatus === "Married" ||
                    //   data.InsuredRelation === "Wife" ||
                    //   data.InsuredRelation === "Husband" ||
                    //   data.InsuredRelation === "Father" ||
                    //   data.InsuredRelation === "Mother"
                    //     ? "Married"
                    //     : data.MaritalStatus || 0
                    // }
                    value={pData.MaritalStatus || 0}
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      validateMaritalStatus(selectedValue,"proposal",index);

                      setProposerData((prevData) =>
                        updateInsuredDetailKey(
                          prevData,
                          "MaritalStatus",
                          selectedValue,
                          index
                        )
                      );
                    }}
                    error={validationsState?.maritalErrorProposal}
                    // helperText={maritalError}
                    // onChange={(e) => {
                    //   handler(e, "MaritalStatus");
                    //   setMaritaldata(e.target.value);
                    //   validateMarital(e.target.value);
                    // }}
                  >
                    <MenuItem value={0}>Select</MenuItem>

                    <MenuItem
                      value="Married"
                      // selected={
                      //   data.MaritalStatus === "Married" ||
                      //   data.InsuredRelation === "Wife" ||
                      //   data.InsuredRelation === "Husband" ||
                      //   data.InsuredRelation === "Father" ||
                      //   data.InsuredRelation === "Mother"
                      // }
                      // defaultValue={data.MaritalStatus}
                    >
                      Married
                    </MenuItem>
                    <MenuItem
                      value="Unmarried"
                      // selected={data.MaritalStatus === "Unmarried"}
                    >
                      Unmarried
                    </MenuItem>

                    <MenuItem value="Divorcee">Divorcee</MenuItem>
                    <MenuItem value="Widow">Widow</MenuItem>
                    <MenuItem value="Widower">Widower</MenuItem>
                  </Select>
                  {Boolean(validationsState?.maritalErrorProposal) && (
                    <FormHelperText error>
                      {validationsState?.maritalErrorProposal}
                    </FormHelperText>
                  )}

                  {/* <span className="error">{maritalError}</span> */}
                </div>
              </div>
            </div>
            {console.log("pdata dob", pData)}
            <div className="row  ppv10 ">
              <div className="col-md-4 px-0 py-2">
                <div className="prpfbd">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopDatePicker"]}>
                      <DesktopDatePicker
                        variant="standard"
                        id="standard-helperText"
                        label="DOB"
                        value={pData?.dateOfbirth ? dayjs(pData.dateOfbirth) : ""}
                        required
                        // value={
                        //   data.dateOfbirth ? dayjs(data.dateOfbirth) : ""
                        // }
                        // onChange={(e) => {
                        //   handler(e, "dateOfbirth", true);

                        //   setDobData(getDate(e));
                        //   validateDob(getDate(e));
                        // }}
                        onChange={(e) => {
                          const selectedValue = getDate(e);

                          validateDob(selectedValue,"proposal",index);

                          setProposerData((prevData) =>
                            updateInsuredDetailKey(
                              prevData,
                              "dateOfbirth",
                              selectedValue,
                              index
                            )
                          );
                        }}
                        format="DD-MM-YYYY"
                        error={validationsState?.dobErrorProposal}
                        // helperText={dobError}
                        sx={{
                          "& .MuiInputBase-root": {
                            boxShadow: "none",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                          },
                          "& .MuiInputBase-input": {
                            padding: "10px 5px", // Adjust padding as needed
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  {Boolean(validationsState?.dobErrorProposal) && (
                    <FormHelperText error>
                      {validationsState?.dobErrorProposal}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="col-md-3 px-0 py-2">
                <div className="prpfbd">
                  <TextField
                    id="standard-helperText"
                    label="Weight (in kgs)"
                    required
                    defaultValue=""
                    variant="standard"
                    error={Boolean(validationsState?.weightErrorProposal)}
                    value={pData.weight || ""}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      validateWeight(selectedValue,"proposal",index);

                      setProposerData((prevData) =>
                        updateInsuredDetailKey(
                          prevData,
                          "weight",
                          selectedValue,
                          index
                        )
                      );
                    }}
                  />
                  {Boolean(validationsState?.weightErrorProposal) && (
                    <FormHelperText error>
                      {validationsState?.weightErrorProposal}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="col-md-2 px-0 py-2">
                <div className="prpfbd">
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Height (cm)"
                    variant="standard"
                    type="number"
                    placeholder="e.g, 172.72"
                    disabled={true}
                    inputProps={{
                      min: "0",
                      max: "332.74",
                      step: "0.01",
                      pattern: "[0-9]+([.,][0-9]+)?",
                    }}
                    value={pData.heightcm}
                    InputLabelProps={{
                      shrink: heightcm !== "",
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3 px-0 py-2">
                <div className="prpfbd">
                  <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="height (ft,inchi) "
                    defaultValue=""
                    type="number"
                    variant="standard"
                    placeholder="e.g, 5.8"
                    // onChange={(e) => {
                    //   heighttoinchi(e);
                    //   setHeightinches(e.target.value);
                    //   handler(e, "height", false, "inches");
                    //   setHeightfeet(handlfeet);
                    //   validateHeight(e.target.value)
                    //   handler(e, "height", false, "feet");
                    //   handler(e, "height", false, "inches");
                    // }}
                    // value={handelFtInchi}
                    value={pData?.height ? pData.height : ""}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      validateHeight(selectedValue,"proposal",index);
                      handleValueSet(selectedValue);
                      setProposerData((prevData) =>
                        updateInsuredDetailKey(
                          prevData,
                          "height",
                          selectedValue,
                          index
                        )
                      );
                    }}
                  />

                  {Boolean(validationsState?.heightErrorProposal) && (
                    <FormHelperText error>{validationsState?.heightErrorProposal}</FormHelperText>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const ProposerForm = ({
  setProposerData,
  proposerData,
  data,
  SelfKey,
  validateOccupation,
  validateSalutation,
  validateFullName,
  validationstate,
  validateDob,
  validateWeight,
  validateHeight,
  validateMaritalStatus,
  validateEducation,
  validateAnnual,
  apiResponse
}) => {
  const [heightCm, setHeightCm] = useState();

  const OccupationData = [
    { Name: "Accountants", value: "100" },
    { Name: "Doctors", value: "101" },
    { Name: "Lawyers", value: "102" },
    { Name: "Architects", value: "103" },
    { Name: "IT Professional", value: "104" },
    { Name: "Consulting Engineers", value: "105" },
    { Name: "Teachers", value: "106" },
    { Name: " Bankers, Clerical Staff", value: "107" },
    { Name: " Persons engaged in administrative functions", value: "108" },
    { Name: "Agriculture", value: "109" },
    { Name: "Housewife", value: "H" },
    { Name: "Student", value: "R" },
    { Name: "Farmer", value: "112" },
    { Name: "Builders", value: "113" },
    {
      Name: "Contractors and Engineers engaged in superintending functions only",
      value: "114",
    },
    { Name: "Drivers", value: "115" },
    {
      Name: " All persons engaged in manual labour (Except those falling under Group III)",
      value: "116",
    },
    { Name: " Cash Carrying Employees", value: "117" },
    { Name: "Garage and Motor Mechanics", value: "118" },
    { Name: "Machine Operators", value: "119" },
    { Name: "Professional Athletics and Sportsmen", value: "120" },
    { Name: "Carpenter", value: "121" },
    { Name: "Insurance Advisor", value: "122" },
    { Name: "Retired Persons", value: "123" },
    { Name: "Electricians", value: "124" },
    { Name: "Others", value: "125" },
    {
      Name: "Persons Working in underground mines, explosives, magazines",
      value: "126",
    },
    {
      Name: "Workers involved in electrical installation with high tension supply",
      value: "127",
    },
    { Name: "Army/Navy/Airforce Professional", value: "128" },
    { Name: "Jockeys", value: "129" },
    { Name: " Adventure Sports Professional", value: "130" },
    { Name: "Merchant Navy", value: "131" },
    { Name: "Commercial Pilot", value: "132" },
    { Name: " Other Class 1", value: "133" },
    { Name: " Other Class 2", value: "134" },
  ];

  useEffect(() => {
    let data = localStorage.getItem("InsuredDetail");

    console.log("the data in proposal", JSON.parse(data));

    setProposerData((prevData) => ({
      ...prevData,
      InsuredDetail: proposerData?.InsuredDetail
        ? proposerData.InsuredDetail
        : JSON.parse(data),
      Gender: localStorage.getItem("Gender"),
      Proposalphonenumber: localStorage.getItem("Proposalphonenumber"),
      District: localStorage.getItem("District"),
      Sate: localStorage.getItem("State"),
      PED: JSON.parse(localStorage.getItem("PED")),
    }));
  }, []);

  // const handleValueSet = (heightInFeet) => {

  //   const totalInches = heightInFeet * 12; // Convert feet to inches
  //   const heightInCentimeters = totalInches * 2.54;

  //   const cm = parseFloat(heightInCentimeters.toFixed(2));

  //   setProposerData((prevData) => ({
  //     ...prevData,
  //     heightcm: cm,
  //   }));

  //   setProposerData((prevData) =>
  //     updateInsuredDetailKey(prevData, "heightcm", cm)
  //   );

  //   setHeightCm(cm);
  // };

  const handleValueSet = (heightInFeet) => {
    // Split the input into feet and inches
    const feet = Math.floor(heightInFeet); 
    const inches = heightInFeet.split('.')[1];
    // const inches = (heightInFeet - feet) ; 
    console.log("inches", inches);
    let heightInCentimeters;
    if(inches){
       heightInCentimeters = (feet * 30.48) + (Number(inches) * 2.54);

    }
    else{
      heightInCentimeters = (feet * 30.48) 

    }
    const cm = parseFloat(heightInCentimeters.toFixed(2)); 
   

    setProposerData((prevData) => ({
      ...prevData,
      heightcm: cm,
    }));

    setProposerData((prevData) =>
      updateInsuredDetailKey(prevData, "heightcm", cm)
    );

    setHeightCm(cm);

  };

  // for pushing data into insuredDetail for proposer -----------

  const updateInsuredDetailKey = (prevData, key, value) => {
    const updatedInsuredDetails = prevData?.InsuredDetail
      ? [...prevData?.InsuredDetail]
      : [];

    if (
      updatedInsuredDetails[0] &&
      updatedInsuredDetails[0].InsuredRelation === "Self"
    ) {
      updatedInsuredDetails[0] = {
        ...updatedInsuredDetails[0],
        [key]: value,
      };
    } else {
      return {
        ...prevData,
        [key]: value,
      };
    }

    return {
      ...prevData,
      [key]: value,
      InsuredDetail: updatedInsuredDetails,
    };
  };

  const convertHeightToFeetAndInches = (heightInFeet) => {
    const feet = Math.floor(heightInFeet); 
    const inches = Math.round((heightInFeet - feet) * 10); 

    return {
      height_feet: feet,
      height_inch: inches,
    };
  };


  console.log("the data in proposer", data)


  const checkForSelfInsured = (insuredList) => {
    return insuredList?.some(insured => insured.InsuredRelation === "Self");
  };


  return (
    <>
      <ProposalArea selfData={proposerData} data={data} />

      <div className={`userhide m-10`}>
        <div className="row  ppv10">
          <div className="col-md-2 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                Salutation*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                label="Salutation"
                value={proposerData.Salutation || 0}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  validateSalutation(selectedValue, "proposer");
                  setProposerData((prevData) => ({
                    ...prevData,
                    Salutation: selectedValue,
                  }));
                  setProposerData((prevData) =>
                    updateInsuredDetailKey(
                      prevData,
                      "Salutation",
                      selectedValue
                    )
                  );
                }}
                error={validationstate.saluteErrorProposer}
                helperText={validationstate.saluteErrorProposer}
              >
                <MenuItem value={0}>Select</MenuItem>

                <MenuItem
                  value={1}
                  // selected={
                  //   proposerData.Salutation == "1"
                  // }
                >
                  Mr.
                </MenuItem>
                <MenuItem
                  value={2}
                  // selected={
                  //   proposerData.Salutation == "2"
                  // }
                >
                  Mrs.
                </MenuItem>
                <MenuItem
                  value={3}
                  // selected={
                  //   proposerData.Salutation == "3"
                  // }
                >
                  Ms.
                </MenuItem>
              </Select>
              {Boolean(validationstate.saluteErrorProposer) && (
                <FormHelperText error>
                  {validationstate.saluteErrorProposer}
                </FormHelperText>
              )}
            </div>
          </div>
          <div className="col-md-5 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Full Name"
                // defaultValue={""}
                value={checkForSelfInsured(proposerData.InsuredDetail)?proposerData.InsuredDetail[0].InsuredName:proposerData.ProposalName || ""}
                variant="standard"
                required
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  validateFullName(selectedValue, "proposer");
                  // setProposerData((prevData) => ({
                  //   ...prevData,
                  //   ProposalName: selectedValue,
                  // }));

                  setProposerData((prevData) =>
                    updateInsuredDetailKey(
                      prevData,
                      "ProposalName",
                      selectedValue
                    )
                  );

                  let data = localStorage.getItem("InsuredDetail");

                  console.log("proposerdata in insured", proposerData);

                  if (JSON.parse(data)[0].InsuredRelation == "Self") {
                    setProposerData((prevData) => ({
                      ...prevData,
                      InsuredDetail: prevData.InsuredDetail.map((item, index) =>
                        index === 0
                          ? { ...item, InsuredName: selectedValue }
                          : item
                      ),
                    }));
                  }
                
                  

                  // validateFullName(e.target.value);
                  // dispatch(updateProposalName(e.target.value));
                  // handler(e, "InsuredName");
                  // setNameVal(e.target.value);
                }}
                error={validationstate.nameErrorProposer}
                helperText={validationstate.nameErrorProposer}
              />
              {/* {Boolean(validationstate.nameErrorProposer) && (
                <FormHelperText error>{validationstate.nameErrorProposer}</FormHelperText>
              )} */}
            </div>
          </div>
          <div className="col-md-5 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                Occupation*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                // label="Salutation"
                // defaultValue={proposerData.Occupation ? proposerData.Occupation : 0}
                value={proposerData.Occupation || 0}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                required
                // onChange={(e) => {

                //   const selectedValue = e.target.value;
                //   setProposerData((prevData) => ({
                //     ...prevData,
                //     Occupation: selectedValue,
                //   }));

                //   // dispatch(
                //   //   updateData([{ key: "Occupation", value: e.target.value }])
                //   // );
                //   // handler(e, "Occupation");
                //   // setOccupValue(e.target.value);
                //   // validateOccupation(e.target.value);

                // }}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  validateOccupation(selectedValue, "proposer");
                  setProposerData((prevData) =>
                    updateInsuredDetailKey(
                      prevData,
                      "Occupation",
                      selectedValue
                    )
                  );
                }}
                error={validationstate.occuErrorProposer}
                helperText={validationstate.occuErrorProposer}
              >
                <MenuItem value={0}>Select</MenuItem>

                {OccupationData.map((data) => {
                  return (
                    <MenuItem
                      value={data.value}
                      // selected={proposalData.Occupation == data.value}
                      key={data.value}
                    >
                      {data.Name}
                    </MenuItem>
                  );
                })}
              </Select>
              {/* <span className="error">{validationstate.occuErrorProposer}</span> */}
              {Boolean(validationstate.occuErrorProposer) && (
                <FormHelperText error>
                  {validationstate.occuErrorProposer}
                </FormHelperText>
              )}
            </div>
          </div>
        </div>

        <div className="row ppv10">
          <div className="col-md-4 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DesktopDatePicker"]}>
                  <DesktopDatePicker
                    variant="standard"
                    id="standard-helperText"
                    label="DOB"
                    // defaultValue=""
                    value={
                      proposerData.dateOfbirth
                        ? dayjs(proposerData.dateOfbirth)
                        : ""
                    }
                    required
                    onChange={(e) => {
                      let dobdata = getDate(e);
                      validateDob(dobdata, "proposer");
                      setProposerData((prevData) =>
                        updateInsuredDetailKey(prevData, "dateOfbirth", dobdata)
                      );

                      // setProposerData((prevData) => ({
                      //   ...prevData,
                      //   dateOfbirth: getDate(e),
                      // }));
                      // dispatch(
                      //   updateData([{ key: "dateOfbirth", value: getDate(e) }])
                      // );
                      // handler(e, "dateOfbirth", true);
                      // validateDob(getDate(e));
                      // setDobValue(getDate(e));
                      // dispatch(getDateofbirth(e));
                    }}
                    error={validationstate.dobErrorProposer}
                    helperText={validationstate.dobErrorProposer}
                    format="DD-MM-YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        boxShadow: "none",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 5px", // Adjust padding as needed
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {Boolean(validationstate.dobErrorProposer) && (
                <FormHelperText error>
                  {validationstate.dobErrorProposer}
                </FormHelperText>
              )}
            </div>
          </div>
          <div className=" col-12 col-md-3 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Weight (in kgs)"
                required
                value={proposerData.weight || ""}
                variant="standard"
                error={Boolean(validationstate.weightErrorProposer)}
                helperText={validationstate.weightErrorProposer}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  // setProposerData((prevData) => ({
                  //   ...prevData,
                  //   weight: selectedValue,
                  // }));
                  validateWeight(selectedValue, "proposer");
                  setProposerData((prevData) =>
                    updateInsuredDetailKey(prevData, "weight", selectedValue)
                  );

                  // dispatch(
                  //   updateData([{ key: "weight", value: e.target.value }])
                  // );
                  // handler(e, "weight");
                  // validateWeight(e.target.value);
                  // setWeightValue(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-2 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Height (cm)"
                variant="standard"
                type="number"
                placeholder="e.g, 172.72"
                disabled={true}
                inputProps={{
                  min: "0",
                  max: "332.74",
                  step: "0.01",
                  pattern: "[0-9]+([.,][0-9]+)?",
                }}
                value={heightCm ? heightCm : proposerData.heightcm}
                InputLabelProps={{
                  shrink: heightCm !== "",
                }}
              />
            </div>
          </div>

          <div className="col-md-3 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="height (ft,inchi) "
                type="number"
                variant="standard"
                placeholder="e.g, 5.8"
                value={proposerData.height || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  validateHeight(selectedValue, "proposer");
                  handleValueSet(selectedValue);

                  let heightall = convertHeightToFeetAndInches(selectedValue);

                  setProposerData((prevData) => ({
                    ...prevData,
                    height: selectedValue,
                    height_feet: heightall.height_feet,
                    height_inch: heightall.height_inch,
                  }));

                  setProposerData((prevData) =>
                    updateInsuredDetailKey(prevData, "height", selectedValue)
                  );
                }}
                error={Boolean(validationstate.heightErrorProposer)}
                helperText={validationstate.heightErrorProposer}
              />

              {/* {Boolean(validationstate.heightErrorProposer) && (
                <FormHelperText error>{validationstate.heightErrorProposer}</FormHelperText>
              )} */}
            </div>
          </div>
        </div>

        <div className="row  ppv10">
          <div className="col-md-4 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                MaritalStatus*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                defaultValue={0}
                value={proposerData.MaritalStatus || 0}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                required
                // onChange={(e) => {
                // dispatch(
                //   updateData([
                //     { key: "MaritalStatus", value: e.target.value },
                //   ])
                // );
                // handler(e, "MaritalStatus");
                // setMaritalValue(e.target.value);
                // validateMarital(e.target.value);
                // }}

                onChange={(e) => {
                  const selectedValue = e.target.value;
                  validateMaritalStatus(selectedValue, "proposer");

                  setProposerData((prevData) =>
                    updateInsuredDetailKey(
                      prevData,
                      "MaritalStatus",
                      selectedValue
                    )
                  );
                }}
                error={validationstate.maritalErrorProposer}
                helperText={validationstate.maritalErrorProposer}
              >
                <MenuItem value={0}>Select</MenuItem>

                <MenuItem
                  value="Married"
                  selected={proposerData.MaritalStatus == "Married"}
                >
                  Married
                </MenuItem>
                <MenuItem
                  value="Unmarried"
                  selected={proposerData.MaritalStatus == "Unmarried"}
                >
                  Unmarried
                </MenuItem>
                <MenuItem value="Divorcee">Divorcee</MenuItem>
                <MenuItem value="Widow">Widow</MenuItem>
                <MenuItem value="Widower">Widower</MenuItem>
              </Select>
              {Boolean(validationstate.maritalErrorProposer) && (
                <FormHelperText error>
                  {validationstate.maritalErrorProposer}
                </FormHelperText>
              )}
            </div>
          </div>
          <div className="col-md-4 px-0 py-0">
            <div className="prpfbd" style={{ width: "100%" }}>
              <span style={{ color: "#2959B8", fontSize: "8pt" }}>
                Education*
              </span>
              <Select
                style={{ width: "100%" }}
                id="standard-select-currency-native"
                select
                // defaultValue={0}
                value={proposerData.Education || 0}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                required
                // onChange={(e) => {
                // dispatch(
                //   updateData([{ key: "Education", value: e.target.value }])
                // );
                // handler(e, "Education");
                // setEduValue(e.target.value);
                // validateEducation(e.target.value);
                // }}

                onChange={(e) => {
                  const selectedValue = e.target.value;

                  validateEducation(selectedValue, "proposer");

                  setProposerData((prevData) =>
                    updateInsuredDetailKey(prevData, "Education", selectedValue)
                  );
                }}
                error={validationstate.eduErrorProposer}
                helperText={validationstate.eduErrorProposer}
              >
                <MenuItem value={0}>Select</MenuItem>

                <MenuItem
                  value="highSchool"
                  // selected={pfData.Education == "highSchool"}
                >
                  High School
                </MenuItem>
                <MenuItem
                  value="associate"
                  // selected={pfData.Education == "associate"}
                >
                  Associate's Degree
                </MenuItem>
                <MenuItem
                  value="bachelor"
                  // selected={pfData.Education == "bachelor"}
                >
                  Bachelor's Degree
                </MenuItem>
                <MenuItem
                  value="master"
                  // selected={pfData.Education == "master"}
                >
                  Master's Degree
                </MenuItem>
                <MenuItem
                  value="doctorate"
                  // selected={pfData.Education == "doctorate"}
                >
                  Doctorate
                </MenuItem>
              </Select>

              {Boolean(validationstate.eduErrorProposer) && (
                <FormHelperText error>
                  {validationstate.eduErrorProposer}
                </FormHelperText>
              )}
              {/* <span className="error">{eduError}</span> */}
            </div>
          </div>
          <div className="col-md-4 px-0 py-2">
            <div className="prpfbd" style={{ width: "100%" }}>
              <TextField
                style={{ width: "100%" }}
                id="standard-helperText"
                label="Annual Income"
                value={proposerData.AnnualIncome || ""}
                variant="standard"
                error={Boolean(validationstate.annualErrorProposer)}
                helperText={validationstate.annualErrorProposer}
                // value={}
                required
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  validateAnnual(selectedValue, "proposer");

                  setProposerData((prevData) => ({
                    ...prevData,
                    AnnualIncome: selectedValue,
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProposalArea = ({ data, selfData }) => {
  return data !== false ? (
    <p
      className="user-info"
      data-bs-toggle="collapse"
      href={`#${data.InsuredRelation}`}
      role="button"
      aria-expanded="true"
      aria-controls={data.InsuredRelation}
    >
      Proposer({data.InsuredRelation}{" "}
      {data.InsuredAge == 0 ? "< 91 Days" : `${data.InsuredAge} Years`} )
    </p>
  ) : (
    <p className="user-info">Proposer</p>
  );
};

// export default Proposal
export { ProposerForm, Proposal };