import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../AllPageComponents/Loader";
import ComapirPolicyBord from "./ComapirPolicyBord";
import { fetchQuickQuote, updateQuickQuoteCompanywise } from "../../Api/Api";
import FeaturesPop from "./FeaturesPage/FeaturesPop";
import Swal from "sweetalert2";
import "./Fetchplane.css";
import { FaCloudDownloadAlt } from "react-icons/fa";
import FormHelperText from "@mui/material/FormHelperText";
import {
  updatedLoginPopup,
  selectUserData,
  Compairpolicy,
  RecvedPolicyCode,
  Recevedpolicyc_id,
  SendUnicID,
} from "../../redux/Slice";
import { filterCompany, replaceCompany } from "../../helper/common";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { formatToINR } from "../../../HomePage/MoneyHealper/Moneyhelper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FastImage from "../../../FastImage";
import { FaAngleUp } from "react-icons/fa";
import { formatAmount } from "./HelperMoneyconverter/MoneyConverter";
import { Skeleton, Box, Typography } from "@mui/material";
import { settingReduxData } from "../../Api/Api";

const FetchPlan = ({
  apiResponse,
  setApiResponse,
  planData,
  setPlanData,
  totalSkeletons,
  setTotalSkeletons,
  loadingPlanCode,
  setLoadingPlanCode,
  isConnected
  
}) => {
  const [showData, setShowData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popOpen, setPopOpen] = useState();

  const [featureshow, setFeatureShow] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [policy, setPolicy] = useState([]);
  const [stopLoading, setStopLoading] = useState(false);

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page when user clicks "OK"
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  const { RecevedpolicyCode } = useSelector((state) => {
    return state.FetchPlan;
  });

  // console.log("The REdux DAta", RecevedpolicyCode);

  // Set default selected values when plans change
  useEffect(() => {
    const initialValues = {};
    // Iterate over each company's plans
    for (const company in planData) {
      if (Array.isArray(planData[company])) {
        planData[company].forEach((plan) => {
          initialValues[plan.planCode] = plan.sumInsured / 100000;
        });
      }
    }
    setSelectedValues(initialValues);
  }, [planData]);

  const handleSelectChange = (planCode, newValue, cname, insurerData) => {
    setLoadingPlanCode(planCode);
    // setSelectedValues((prevState) => ({
    //   ...prevState,
    //   [planCode]: newValue,
    // }));
    // Call your updateCompanyData function here if needed
    updateCompanyData(newValue, planCode, cname, insurerData);
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  useEffect(() => {
    if (unique_id) {
      dispatch(SendUnicID(unique_id));
    }
  }, [unique_id]);

  const dispatch = useDispatch();

  const loginData = useSelector(selectUserData);

  // useEffect(() => {
  //   dataSetting();
  //   // console.log("the planData", planData);
  // }, []);

  // const dataSetting = () => {
  //   if (apiResponse.selected_company && apiResponse.selected_company.response) {
  //     const replacedResponse = replaceCompany(
  //       apiResponse.response,
  //       apiResponse.selected_company.response.insurerName,
  //       apiResponse.selected_company.response.planCode,
  //       apiResponse.selected_company.response
  //     );

  //     // console.log("replacedResponse in onload", replacedResponse);

  //     setPlanData(replacedResponse);
  //   } else {
  //     setPlanData(apiResponse ? apiResponse.response : "");
  //   }
  // };

  // filter for selected_company ----------------------------------

  const navigate = useNavigate();
  const handlePlanClick = (plan) => {
    //console.log("Annualy Data", plan);
    // if (loginData.data == false) {
    //   dispatch(updatedLoginPopup(true));
    //   return;
    // }

    //console.log("planedat is" + planData);
    setIsLoading(false)
    const planCode = plan.planCode;
    const insurerName = plan.insurerName;
    const url = `/afterplanfatch?req_id=${unique_id}&c_id=${insurerName}&plan_id=${planCode}`;
    navigate(url);
  };

  const replacePlan = (responseData) => {
    try {
      const replacedResponse = replaceCompany(
        apiResponse.response,
        responseData.data.request.CompanyName,
        responseData.data.request.PlanCode,
        responseData.data.response
      );

      return replacedResponse;
    } catch (error) {}
  };

  const updateCompanyData = async (value, plancode, planname, insurerData) => {
    try {
      // setPlanData({})
      // setIsLoading(true);
      let payload = {
        ...apiResponse.request,
        sum_insured: value,
        PlanCode: plancode,
        CompanyName: planname,
      };

      let data = isConnected();
      console.log("the data in isconnected", data)

      if(data){
        const response = await updateQuickQuoteCompanywise(
          payload,
          unique_id
        ).then((response) => {
          // const newResponse = replacePlan(response);
          // console.log("newResponse in companydata", newResponse);
          // setApiResponse({
          //   ...apiResponse,
          //   response: newResponse,
          // });
          // setLoadingPlanCode(null);
          // toggleShowData(insurerData)
  
          // setPlanData(newResponse);
          setIsLoading(false);
        });
      }
      else{
        setIsLoading(false);
        return
      }

     
    } catch (error) {
      console.error("Error updating company data:", error);
    }
  };

  const [comparePolicystate, setcomparePolicy] = useState([]);

  const comparePolicy = (policycode) => {
    if (policycode) {
      const isSelected = RecevedpolicyCode.includes(policycode);

      if (isSelected) {
        // Remove policy code from the selected list
        setcomparePolicy((prev) => prev.filter((code) => code !== policycode));
        dispatch(
          RecvedPolicyCode(
            RecevedpolicyCode.filter((code) => code !== policycode)
          )
        );
      } else {
        // Add policy code if less than 3 are selected
        if (RecevedpolicyCode.length < 3) {
          setcomparePolicy((prev) => [...prev, policycode]);
          dispatch(RecvedPolicyCode([...RecevedpolicyCode, policycode]));
        } else {
          alert("You can only select up to three items.");
        }
      }
    }
  };
  useEffect(() => {
    if (RecevedpolicyCode === "") {
      setcomparePolicy([]);
    }
  }, [RecevedpolicyCode]);

  const [ShowPolicyBord, setShowPolicyBord] = useState(false);
  useEffect(() => {
    if (RecevedpolicyCode) {
      setShowPolicyBord(true);
    } else if (RecevedpolicyCode.length < 0) {
      setShowPolicyBord(false);
    }
  }, [policy]);

  const toggleShowData = (insurer) => {
    setShowData((prev) => (prev === insurer ? "" : insurer));
  };

  useEffect(() => {
    if (
      apiResponse
        ? apiResponse.response == null || apiResponse.selected_company
          ? apiResponse.selected_company.response == null
          : ""
        : false
    ) {
      setStopLoading(false);
      handleError("Oops there is an Error !");
    }
  }, [apiResponse]);

  // Check if RecevedpolicyCode is an array before using map
  const PlaneCodes = Array.isArray(RecevedpolicyCode)
    ? RecevedpolicyCode.map((item) => item.planCode)
    : [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 820 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 632, min: 0 },
      items: 1,
    },
  };

  const TastemonialData = [
    {
      id: 0,
      image: "imagepath",
      Name: "Akash Mondal",
      Review:
        "I had a seamless experience using the platform. The variety of insurance options available was impressive, and the comparison tools helped me make an informed decision. The customer support was also very helpful throughout the process. Highly recommend this service for anyone looking to explore insurance options!",
      Givingstar: 5,
    },
    {
      id: 1,
      image: "imagepath",
      Name: "Rajib Dasi",
      Review:
        "Overall, I’m satisfied with the experience. The platform was easy to use, and I was able to compare various insurance policies quickly. However, I feel that there could be a few more personalized recommendations based on my profile.",
      Givingstar: 4,
    },
    {
      id: 2,
      image: "imagepath",
      Name: "Akash Mukharjee",
      Review:
        "The website was very user-friendly, and I appreciated the detailed policy breakdowns. It made my decision-making process a lot easier. A little more information on customer reviews of the insurance providers would make it even better.",
      Givingstar: 4,
    },
    {
      id: 3,
      image: "imagepath",
      Name: "Anmol Agarwal",
      Review:
        "The platform was helpful, but I had a few challenges with the navigation and finding detailed policy terms. I had to contact customer support for clarification, but they were responsive and resolved my query quickly. Still, there is room for improvement in terms of user experience.",
      Givingstar: 3,
    },
    {
      id: 4,
      image: "imagepath",
      Name: "Nilanjan Bhattacharya",
      Review:
        "I couldn’t have asked for a better experience! The platform provided a wide variety of insurance policies to compare, and the user interface was very intuitive. I found the perfect plan for me in no time. The customer service was excellent as well!",
      Givingstar: 5,
    },
    {
      id: 5,
      image: "imagepath",
      Name: "Biplab Sarkar",
      Review:
        "The platform is solid and provides a great selection of insurance options. I liked how easy it was to compare quotes. However, I feel there could be more filters to help narrow down the options more effectively.",
      Givingstar: 4,
    },
    {
      id: 6,
      image: "imagepath",
      Name: "Koushik Jhaa",
      Review:
        "The service was generally good. I was able to compare policies and choose one that fits my needs. There were a few delays in getting in touch with support, but overall, the experience was smooth, and I’m happy with the plan I selected.",
      Givingstar: 4,
    },
    {
      id: 7,
      image: "imagepath",
      Name: "Goutam Das",
      Review:
        "Excellent platform! It helped me find an insurance policy that suited my needs perfectly. The comparison feature was incredibly helpful in understanding what each plan offers. I’ll definitely be using this service again for future insurance purchases.",
      Givingstar: 5,
    },
    {
      id: 8,
      image: "imagepath",
      Name: "Suparna Saha",
      Review:
        "I had a good experience using the platform. The variety of plans was great, and the process was simple. The only thing I think could be improved is having more in-depth comparisons between plans, especially for those with complex coverage options.",
      Givingstar: 4,
    },
    {
      id: 9,
      image: "imagepath",
      Name: "Sanchita Triwari",
      Review:
        "I’m very satisfied with the service provided. The website made it easy to compare multiple insurance plans, and the information provided was clear and helpful. The customer support team was excellent when I had questions about my policy.",
      Givingstar: 5,
    },
    {
      id: 10,
      image: "imagepath",
      Name: "Rajib Sarkar",
      Review:
        "A great experience! I found the platform to be very intuitive, and I was able to compare quotes from multiple insurance providers in minutes. The claims process could be explained in more detail, but overall, I’m very happy with my experience.",
      Givingstar: 5,
    },
    {
      id: 10,
      image: "imagepath",
      Name: "Purab Jha",
      Review:
        "The platform is good, but I had some issues with the mobile interface. It took longer than expected to get through the comparison process. Once I reached customer support, they were very helpful in guiding me through the options, but the website could use a bit of improvement.",
      Givingstar: 3,
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const renderStars = (count) => {
    return "⭐".repeat(count);
  };

  //  ------------------ skeleton work------------------------------

  
  const planDataKeys = Object.keys(planData); 
  const remainingSkeletons = Math.max(totalSkeletons - planDataKeys.length, 0);


  // const planDataKeys = planData ? Object.keys(planData) : 0; // Get the keys from planData
  // const remainingSkeletons = totalSkeletons - planDataKeys.length; 
  // const actualRemainingSkeletons = totalSkeletons - planDataKeys.length;
  // const [firstLoad,setFirstLoad] = useState(true);
  // const [remainingSkeletons, setRemainingSkeletons] = useState(5);

  // useEffect(()=>{
  //   if(!firstLoad) setRemainingSkeletons(actualRemainingSkeletons);
  //   else setTimeout(()=>setRemainingSkeletons(actualRemainingSkeletons),100);
  // },[planData]);


 const settingData = async (cdata) => {
    try {
       console.log("cdata in set", cdata)

       let data = {
        "c_id" : cdata?.insurerName || "",
          "plan_id" : cdata?.planCode || "",
          "show_price" : true

       }
      localStorage.setItem('show_price',true)
      let payloadData = {
        extra_data: (data),
        phone_no: localStorage.getItem('Proposalphonenumber'),
      };
      let response = await settingReduxData(unique_id, payloadData);
      console.log("the response in set reduxdata kycpage", response);


      return response;
    } catch (error) {
      console.log("the error", error);
    }
  };




  return (
    <>
      {isLoading && <Loader />}
      {stopLoading == false && (
        <>
          <div className="container">
            <div className="row p-0">
              <div className="col-lg-8">
                <div className="container">
                  {/* {console.log("the plandata", planData)} */}

                  {Object.keys(planData).map(
                    (insurer) =>
                      planData[insurer][0] != null && (
                        <>
    
                          {planData[insurer][0] != null && (
                            
                            <div className="row pb-3">
                              <div className="col-md-2 p-0">
                                <div className="rowleftsec ">
                                  <div className="logo text-center">
                                    <img
                                      src={
                                        planData[insurer]
                                          ? planData[insurer][0]
                                            ? planData[insurer][0].insurerLogo
                                            : ""
                                          : ""
                                      }
                                      style={{
                                        width: "70px",
                                        objectFit: "contain",
                                        aspectRatio: 3 / 2,
                                      }}
                                    />
                                    {planData[insurer].length > 1 &&
                                    planData[insurer] &&
                                    planData[insurer].length - 1 >= 1 ? (
                                      <div
                                        onClick={() => {
                                          toggleShowData(insurer);
                                        }}
                                      >
                                        {showData != insurer &&
                                        planData[insurer].length - 1 >= 1 ? (
                                          <p
                                            className="font11"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {planData[insurer].length - 1}
                                            {"  "}
                                            {planData[insurer].length - 1 > 1
                                              ? "More Plans"
                                              : "More Plan"}{" "}
                                            <br />
                                            {/* <i className={`fa fa-chevron-${showData === insurer ? "up" : "down"}`} aria-hidden="true"/> */}
                                            <FaAngleUp
                                              className={
                                                showData === insurer
                                                  ? "upclass"
                                                  : "dowclass"
                                              }
                                            />
                                          </p>
                                        ) : (
                                          <p className="font11">
                                            {"show less"}
                                            <br />
                                            {/* <i className={`fa fa-chevron-${showData === insurer ? "up" : "down"}`} aria-hidden="true"/> */}
                                            <FaAngleUp
                                              className={
                                                showData === insurer
                                                  ? "upclass"
                                                  : "dowclass"
                                              }
                                            />
                                          </p>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-10 p-0">
                                <div className="rowrightsec ">
                                  {planData[insurer].length > 0 &&
                                    planData[insurer].map((item, index) => {
                                      if (index == 0 || showData == insurer) {
                                        return (
                                          <>
                                            {loadingPlanCode ===
                                            item.planCode ? (
                                              <div
                                                key={index}
                                                className="downborder pb-2 animation pad-fix"
                                                hidden=""
                                                style={{
                                                  padding: "0px",
                                                  maxWidth: "1200px",
                                                }}
                                              >
                                                <div
                                                  className="skel-main-div col-md-14"
                                                  style={{ padding: "0px" }}
                                                >
                                                  <div className="left-main-skel">
                                                    <Skeleton
                                                      className="heading-skel"
                                                      height={40}
                                                      variant="text"
                                                      sx={{ fontSize: "1rem" }}
                                                      style={{
                                                        margin: "10px",
                                                        position: "relative",
                                                      }}
                                                    />

                                                    <div className="left-skel-data">
                                                      <Skeleton
                                                        className="sub1"
                                                        height={25}
                                                        variant="text"
                                                        sx={{
                                                          fontSize: "1rem",
                                                        }}
                                                        style={{
                                                          margin: "10px",
                                                          position: "relative",
                                                        }}
                                                      />
                                                      {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
                                                      <Skeleton
                                                        className="sub2"
                                                        height={25}
                                                        variant="text"
                                                        sx={{
                                                          fontSize: "1rem",
                                                        }}
                                                        style={{
                                                          margin: "10px",
                                                          marginBottom: "-5px",
                                                          position: "relative",
                                                        }}
                                                      />
                                                      <Skeleton
                                                        className="sub3"
                                                        height={25}
                                                        variant="text"
                                                        sx={{
                                                          fontSize: "1rem",
                                                        }}
                                                        style={{
                                                          margin: "10px",
                                                          marginBottom: "0px",
                                                          position: "relative",
                                                        }}
                                                      />
                                                      {/* </div> */}
                                                    </div>

                                                    <Skeleton
                                                      className="compare-box-skel"
                                                      height={40}
                                                      variant="text"
                                                      sx={{ fontSize: "1rem" }}
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    />
                                                  </div>

                                                  {/* -------------- right side start -------------------- */}

                                                  <div className="right-main-skel">
                                                    <Skeleton
                                                      className="heading-right"
                                                      height={50}
                                                      variant="text"
                                                      sx={{ fontSize: "1rem" }}
                                                      style={{ margin: "10px" }}
                                                    />

                                                    <div className="cover-div-data col-md-12">
                                                      <div className="col-md-6">
                                                        <Skeleton
                                                          className="sub-data-left"
                                                          height={40}
                                                          variant="text"
                                                          sx={{
                                                            fontSize: "1rem",
                                                          }}
                                                          style={{
                                                            margin: "10px",
                                                            position:
                                                              "relative",
                                                          }}
                                                        />
                                                        <Skeleton
                                                          className="sub-data-left"
                                                          height={20}
                                                          variant="text"
                                                          sx={{
                                                            fontSize: "1rem",
                                                          }}
                                                          style={{
                                                            margin: "10px",
                                                            position:
                                                              "relative",
                                                          }}
                                                        />
                                                      </div>

                                                      <div className="col-md-6">
                                                        <Skeleton
                                                          className="sub-data-left"
                                                          height={40}
                                                          variant="text"
                                                          sx={{
                                                            fontSize: "1rem",
                                                          }}
                                                          style={{
                                                            margin: "10px",
                                                            position:
                                                              "relative",
                                                          }}
                                                        />
                                                        <Skeleton
                                                          className="sub-data-right"
                                                          height={20}
                                                          variant="text"
                                                          sx={{
                                                            fontSize: "1rem",
                                                          }}
                                                          style={{
                                                            margin: "10px",
                                                            position:
                                                              "relative",
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                    <Skeleton
                                                      className="price-amt"
                                                      height={80}
                                                      variant="text"
                                                      sx={{ fontSize: "1rem" }}
                                                      style={{ margin: "10px" }}
                                                    />
                                                  </div>

                                                  <Skeleton
                                                    key={index}
                                                    variant="rectangular"
                                                    // height={150}
                                                    className="sec-skeleton"
                                                    style={{
                                                      marginBottom: "10px",
                                                      borderRadius: "10px",
                                                      borderTopLeftRadius:
                                                        "0px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                key={index}
                                                className="container downborder pb-2 animation"
                                                hidden=""
                                                style={{ padding: "0px" }}
                                              >
                                                <div className="cnt">
                                                  <div className="row">
                                                    <div className="col-lg-6 px-4 flexdircol">
                                                      <h1 className="Planename">
                                                        {item.planName}
                                                      </h1>
                                                      <p
                                                        style={{
                                                          color:
                                                            "rgb(33, 52, 216)",
                                                          position: "relative",
                                                          top: 10,
                                                        }}
                                                      >
                                                        <svg
                                                          stroke="currentColor"
                                                          fill="currentColor"
                                                          strokeWidth={0}
                                                          viewBox="0 0 1024 1024"
                                                          height="1em"
                                                          width="1em"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path d="M644.7 669.2a7.92 7.92 0 0 0-6.5-3.3H594c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.8-5.3 0-12.7-6.5-12.7h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-22.9-31.9zM688 306v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm184 458H208V148h560v296c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h312c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm402.6-320.8l-192-66.7c-.9-.3-1.7-.4-2.6-.4s-1.8.1-2.6.4l-192 66.7a7.96 7.96 0 0 0-5.4 7.5v251.1c0 2.5 1.1 4.8 3.1 6.3l192 150.2c1.4 1.1 3.2 1.7 4.9 1.7s3.5-.6 4.9-1.7l192-150.2c1.9-1.5 3.1-3.8 3.1-6.3V538.7c0-3.4-2.2-6.4-5.4-7.5zM826 763.7L688 871.6 550 763.7V577l138-48 138 48v186.7z" />
                                                        </svg>
                                                        <span className="offeerspan">
                                                          {item.planDescription}
                                                        </span>
                                                      </p>
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                          justifyContent:
                                                            "start",
                                                          display: "flex",
                                                          flexWrap: "wrap",
                                                          flexDirection: "row",
                                                        }}
                                                      >
                                                        {item.features.map(
                                                          (data, index) => (
                                                            <div
                                                              className="row py-0"
                                                              key={index}
                                                            >
                                                              <span className="offertext fetchplanetag featuretag">
                                                                &nbsp;{" "}
                                                                <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth={
                                                                    0
                                                                  }
                                                                  version={1}
                                                                  viewBox="0 0 48 48"
                                                                  enableBackground="new 0 0 48 48"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <polygon
                                                                    fill="#43A047"
                                                                    points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9"
                                                                  />
                                                                </svg>{" "}
                                                                {data}
                                                              </span>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-6 px-4 flexdircol">
                                                      <div className="row  ">
                                                        <div className="col text-center">
                                                          <div className="top-love">
                                                            <svg
                                                              stroke="currentColor"
                                                              fill="currentColor"
                                                              strokeWidth={0}
                                                              viewBox="0 0 16 16"
                                                              color="black"
                                                              height="1em"
                                                              width="1em"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              style={{
                                                                color: "black",
                                                              }}
                                                            >
                                                              <path d="M8.069 0c.262 0 .52.017.76.057a4.1 4.1 0 0 1 .697.154c.228.069.451.155.674.263.217.103.44.229.663.366.377.24.748.434 1.126.589a7.537 7.537 0 0 0 2.331.525c.406.029.823.046 1.257.046v4c0 .76-.097 1.48-.291 2.166a8.996 8.996 0 0 1-.789 1.943 10.312 10.312 0 0 1-1.188 1.725 15.091 15.091 0 0 1-1.492 1.532 17.57 17.57 0 0 1-1.703 1.325c-.594.412-1.194.795-1.794 1.143l-.24.143-.24-.143a27.093 27.093 0 0 1-1.806-1.143 15.58 15.58 0 0 1-1.703-1.325 15.082 15.082 0 0 1-1.491-1.532 10.947 10.947 0 0 1-1.194-1.725 9.753 9.753 0 0 1-.789-1.943A7.897 7.897 0 0 1 .571 6V2c.435 0 .852-.017 1.258-.046a8.16 8.16 0 0 0 1.188-.171c.383-.086.766-.2 1.143-.354A6.563 6.563 0 0 0 5.28.846C5.72.56 6.166.349 6.606.21A4.79 4.79 0 0 1 8.069 0zm6.502 2.983a9.566 9.566 0 0 1-2.234-.377 7.96 7.96 0 0 1-2.046-.943A4.263 4.263 0 0 0 9.23 1.16 3.885 3.885 0 0 0 8.074.994a3.99 3.99 0 0 0-1.165.166 3.946 3.946 0 0 0-1.058.503A7.926 7.926 0 0 1 3.8 2.61c-.709.206-1.451.332-2.229.378v3.017c0 .663.086 1.297.258 1.908a8.58 8.58 0 0 0 .72 1.743 9.604 9.604 0 0 0 1.08 1.572c.417.491.862.948 1.342 1.382.48.435.983.835 1.509 1.206.531.372 1.063.709 1.594 1.017a22.397 22.397 0 0 0 1.589-1.017 15.389 15.389 0 0 0 1.514-1.206c.48-.434.926-.891 1.343-1.382a9.596 9.596 0 0 0 1.08-1.572 8.258 8.258 0 0 0 .709-1.743 6.814 6.814 0 0 0 .262-1.908V2.983z" />
                                                              <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11.797 4.709l-.44-.378-.406.035-4.36 5.148-1.485-2.12-.4-.068-.463.331-.069.4 1.909 2.726.217.12.457.028.234-.102 4.835-5.715-.029-.405z"
                                                              />
                                                            </svg>
                                                            <p>
                                                              {item.insurerName ===
                                                              "ICICI"
                                                                ? "98.53%"
                                                                : item.insurerName ===
                                                                  "Care"
                                                                ? "90%"
                                                                : item.insurerName ===
                                                                  "GoDigit"
                                                                ? "97%"
                                                                : item.insurerName ===
                                                                  "HDFC"
                                                                ? "98%"
                                                                : item.insurerName ===
                                                                  "Reliance"
                                                                ? "89%"
                                                                : "100%"}{" "}
                                                              &nbsp; of claims
                                                              paid within 3
                                                              months
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div
                                                        className="row"
                                                        key={item.planCode}
                                                      >
                                                        {item.brochure &&
                                                        item.wording ? (
                                                          <div className="brosurediv col-md-12">
                                                            <a
                                                              className="bocurewarding"
                                                              href={`${process.env.REACT_APP_MAIN_URL}/brochure_wording/${item.brochure}`}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                            >
                                                              Policy Brochure
                                                              &nbsp;
                                                              <FaCloudDownloadAlt />
                                                            </a>
                                                            <a
                                                              className="bocurewarding"
                                                              href={`${process.env.REACT_APP_MAIN_URL}/brochure_wording/${item.wording}`}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                            >
                                                              Policy Wording
                                                              &nbsp;
                                                              <FaCloudDownloadAlt />
                                                            </a>
                                                          </div>
                                                        ) : null}
                                                        <div className="col-6 text-center">
                                                          <p className="coveramount">
                                                            Cover Amount
                                                          </p>

                                                          <div className="amountselect">
                                                            <FormControl
                                                              sx={{
                                                                m: 1,
                                                                minWidth:
                                                                  "100%",
                                                              }}
                                                            >
                                                              <Select
                                                                value={
                                                                  item.sumInsured /
                                                                  100000
                                                                }
                                                                onChange={(e) =>
                                                                  handleSelectChange(
                                                                    item.planCode,
                                                                    e.target
                                                                      .value,
                                                                    item.insurerName,
                                                                    insurer
                                                                  )
                                                                }
                                                                sx={{
                                                                  boxShadow:
                                                                    "none",
                                                                  height:
                                                                    "20px", // Adjust height
                                                                  padding:
                                                                    "0px", // Adjust padding
                                                                  ".MuiOutlinedInput-notchedOutline":
                                                                    {
                                                                      border: 0,
                                                                    },
                                                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                                    {
                                                                      border: 0,
                                                                    },
                                                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                                    {
                                                                      border: 0,
                                                                    },
                                                                }}
                                                              >
                                                                {item.si.map(
                                                                  (
                                                                    item,
                                                                    index
                                                                  ) => (
                                                                    <MenuItem
                                                                      key={
                                                                        index
                                                                      }
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="coveramountselect"
                                                                    >
                                                                      {formatAmount(
                                                                        item
                                                                      )}
                                                                    </MenuItem>
                                                                  )
                                                                )}
                                                              </Select>
                                                            </FormControl>
                                                          </div>
                                                        </div>
                                                        <div className="col-6 text-end">
                                                          <p className="cashlesshospitals">
                                                            Cashless hospitals
                                                          </p>
                                                          <p className="cashlesshospitalp">
                                                            {item.insurerName ==
                                                            "icici"
                                                              ? "9,700"
                                                              : item.insurerName ==
                                                                "care"
                                                              ? "10,632"
                                                              : item.insurerName ==
                                                                "godigit"
                                                              ? "16,334"
                                                              : item.insurerName ==
                                                              "hdfc" ? "" : ""}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-12 flexbtnplanepage">
                                                      <div
                                                        className="plan-compare-add"
                                                        onClick={(e) => {
                                                          comparePolicy(item);
                                                        }}
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          className="compare-inp-plan"
                                                          id="exampleCheck1"
                                                          onChange={(e) => {
                                                            comparePolicy(item);
                                                          }}
                                                          checked={PlaneCodes.includes(
                                                            item.planCode
                                                          )}
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          // htmlFor="exampleCheck1"
                                                        >
                                                          Add to Compare
                                                        </label>
                                                      </div>

                                                      <div className="maxbtnwidth">
                                                        <div className=" my-1 text-center">
                                                          <button
                                                            type="button"
                                                            className="btnmonth"
                                                            onClick={async() => {
                                                              setIsLoading(true)
                                                              let response = await settingData(item);
                                                              if(response){
                                                                handlePlanClick(
                                                                  item
                                                                );
                                                              }
                                                              
                                                            }}
                                                          >
                                                            {formatToINR(
                                                              item
                                                                .premiumBreakup
                                                                .totalPremium
                                                            )}

                                                            {item.premiumBreakup
                                                              .term == "1"
                                                              ? "/Annually"
                                                              : item
                                                                  .premiumBreakup
                                                                  .term == "2"
                                                              ? " for 2 Years"
                                                              : item
                                                                  .premiumBreakup
                                                                  .term == "3"
                                                              ? " for 3 Years"
                                                              : `/${item.premiumBreakup.term} Years`}
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* <div className="row">
                                          <div className="col" />
                                        </div> */}
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        );
                                      } else return <></>;
                                    })}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )
                  )}
                </div>
                {/* Show skeletons based on how many objects are in planData */}
                {Array.from({ length: remainingSkeletons }).map((_, index) => (
  <div className="skel-main-div" key={index}>
    {/* Left Side Skeleton */}
    <div className="col-md-2" style={{ padding: "0px" }}>
      <Skeleton
        className="logo-skel"
        variant="rectangular"
        height={70}
      />
      <Skeleton
        variant="rectangular"
        height={150}
        className="first-skeleton"
      />
    </div>

    {/* Right Side Skeleton */}
    <div className="col-md-10" style={{ padding: "0px" }}>
      <div className="left-main-skel">
        <Skeleton
          className="heading-skel"
          height={40}
          variant="text"
          sx={{ fontSize: "1rem" }}
          style={{ margin: "10px", position: "relative" }}
        />

        <div className="left-skel-data">
          <Skeleton
            className="sub1"
            height={25}
            variant="text"
            sx={{ fontSize: "1rem" }}
            style={{ margin: "10px", position: "relative" }}
          />
          <Skeleton
            className="sub2"
            height={25}
            variant="text"
            sx={{ fontSize: "1rem" }}
            style={{
              margin: "10px",
              marginBottom: "-5px",
              position: "relative",
            }}
          />
          <Skeleton
            className="sub3"
            height={25}
            variant="text"
            sx={{ fontSize: "1rem" }}
            style={{
              margin: "10px",
              marginBottom: "0px",
              position: "relative",
            }}
          />
        </div>

        <Skeleton
          className="compare-box-skel"
          height={40}
          variant="text"
          sx={{ fontSize: "1rem" }}
          style={{ position: "relative" }}
        />
      </div>

      {/* Right Side Details */}
      <div className="right-main-skel">
        <Skeleton
          className="heading-right"
          height={50}
          variant="text"
          sx={{ fontSize: "1rem" }}
          style={{ margin: "10px" }}
        />

        <div className="cover-div-data col-md-12">
          <div className="col-md-6">
            <Skeleton
              className="sub-data-left"
              height={40}
              variant="text"
              sx={{ fontSize: "1rem" }}
              style={{ margin: "10px", position: "relative" }}
            />
            <Skeleton
              className="sub-data-left"
              height={20}
              variant="text"
              sx={{ fontSize: "1rem" }}
              style={{ margin: "10px", position: "relative" }}
            />
          </div>

          <div className="col-md-6">
            <Skeleton
              className="sub-data-left"
              height={40}
              variant="text"
              sx={{ fontSize: "1rem" }}
              style={{ margin: "10px", position: "relative" }}
            />
            <Skeleton
              className="sub-data-right"
              height={20}
              variant="text"
              sx={{ fontSize: "1rem" }}
              style={{ margin: "10px", position: "relative" }}
            />
          </div>
        </div>
        <Skeleton
          className="price-amt"
          height={80}
          variant="text"
          sx={{ fontSize: "1rem" }}
          style={{ margin: "10px" }}
        />
      </div>

      {/* Bottom Skeleton */}
      <Skeleton
        variant="rectangular"
        height={150}
        className="sec-skeleton"
        style={{
          marginBottom: "10px",
          borderRadius: "10px",
          borderTopLeftRadius: "0px",
        }}
      />
    </div>
  </div>
))}

              </div>
              <div
                className="modal fade"
                id="videoModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="videoModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title video-h5"
                        id="videoModalCenterTitle"
                      >
                        Secure Your Future Today !
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">Ã—</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {/* <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/cOXttb0Vvoo"
                      allowFullScreen=""
                      title="Plan Video"
                    />
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pb-1">
                <div className="sticky-top ">
                  <div className="righttop text-center ">
                    <div className="col">
                      <div className="listheaderplanepage">
                        <h5 className="listfetchplane">
                          {/* 11k+ customers in Mumbai got claims assistance worth
                          â‚¹52cr */}
                          Points to Check Before Buying Health Insurance Plane
                        </h5>
                        <p className="planelistdis">
                          Insure your Family Health with IRDAI Certified expert
                          advice
                        </p>
                      </div>
                      <hr />

                      <div className="checkdivs">
                        <li className="planelist">
                          <FastImage
                            url="/Website%20Img/tick-lightgreenbg.svg?updatedAt=1729674266379"
                            className="tikplane"
                          />{" "}
                          Certified Experts
                        </li>
                        <li className="planelist">
                          <FastImage
                            url="/Website%20Img/tick-lightgreenbg.svg?updatedAt=1729674266379"
                            className="tikplane"
                          />{" "}
                          Claim Support
                        </li>
                        <li className="planelist">
                          <FastImage
                            url="/Website%20Img/tick-lightgreenbg.svg?updatedAt=1729674266379"
                            className="tikplane"
                          />{" "}
                          Free Advice{" "}
                        </li>
                        <li className="planelist">
                          <FastImage
                            url="/Website%20Img/tick-lightgreenbg.svg?updatedAt=1729674266379"
                            className="tikplane"
                          />{" "}
                          Addon Fetures{" "}
                        </li>
                      </div>
                    </div>
                  </div>

                  <div className="uppercarsoul">
                    <div className="backgroundcarasoulfetchplane">
                      <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={false}
                        infinite={true}
                        autoPlay={true}
                        // autoPlaySpeed={2000}
                        keyBoardControl={true}
                        customTransition="transform 0.5s ease"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        arrows={false}
                        itemClass="carousel-item-padding-40-px"
                      >
                        {TastemonialData.map((data) => {
                          return (
                            <>
                              <div className="onlytesteminial">
                                <div className="flextestemonial">
                                  <h6>{data.Name}</h6>
                                  <img
                                    src="https://ik.imagekit.io/teofx8bxs/Website%20Img/bima_aaa-copy-fotor-20230726123724.png?updatedAt=1718871789882&tr=n-ik_ml_thumbnail"
                                    className="testemonialicon"
                                  />
                                  {/* <FastImage url="/Website%20Img/bima_aaa-copy-fotor-20230726123724.png?updatedAt=1718871789882" className="testemonialicon"/> */}
                                </div>
                                <p>{renderStars(data.Givingstar)}</p>
                                <span>{truncateText(data.Review, 370)}</span>
                              </div>
                            </>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {ShowPolicyBord && RecevedpolicyCode.length !== 0 && (
            <ComapirPolicyBord />
          )}
          {featureshow && (
            <FeaturesPop setPopOpen={setPopOpen} popOpen={popOpen} />
          )}
        </>
      )}
    </>
  );
};

export default FetchPlan;
