import React,{useEffect, useState} from 'react'
import '../ChildEducationPlanner/ChildEducationPlanner.css'
import Slider from "@mui/material/Slider";
import Redirect from '../../../AllPageComponents/Rederict';
import Calculatorlist from '../Calculator-Charat/Calculatorlist';
import Chart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
const Marrige = () => {
    
  const [ChildAge, setChildAge] = useState(5);

  const handleChildAgeChange = (e) => {
    let value = e.target.value;
  
    if (value === '') {
      setChildAge(''); // Set to an empty string if the input is cleared
    } else {
      value = Number(value);
      if (value > 60) {
        value = 60; // Set the value to 60 if it's greater than 60
      }
      setChildAge(value);
    }
  };
  
  



const [StartEducationAge, setStartEducationAge] = useState(18)

const handleChildMarrageAgeChange = (e) => {
  let value = e.target.value;

  if (value === '') {
    setStartEducationAge(''); // Set to an empty string if the input is cleared
  } else {
    value = Number(value);
    if (value > 60) {
      value = 60; // Set the value to 60 if it's greater than 60
    }
    setStartEducationAge(value);
  }
};






const [selectedValue, setSelectedValue] = useState('Yes'); // Initial value set to 'Yes'
const [CurrentEducationPrice, setCurrentEducationPrice] = useState(0)
const [howMuchamountYousave, sethowMuchamountYousave] = useState(10000)
const [Influation, setInfluation] = useState(6)
const [Return, setReturm] = useState(8)
const [tab, settab] = useState(1)



var convertRupeesIntoWords = require('convert-rupees-into-words');


useEffect(()=>{
  window.scrollTo(0, 0);
},[tab])


const TotalAge = StartEducationAge - ChildAge
//console.log(TotalAge)



// Influation Calculators

function calculateFutureValue(principal, inflationRate, years) {
  const adjustedRate = 1 + inflationRate / 100;
  const futureValue = principal * Math.pow(adjustedRate, years);
  return futureValue.toFixed(2);
}
const principal = CurrentEducationPrice; 
const inflationRate = Influation; 
const years = TotalAge;
// The Feture Amount with added infulation rate 
const futureAmount = calculateFutureValue(principal, inflationRate, years);



// The Amount Save for Education perpose

function calculateFutureValue(principalAmount, annualInterestRate, timePeriod) {
  const monthlyInterestRate = annualInterestRate / 100 / 12;
  const months = timePeriod * 12;
  let futureValue = principalAmount;

  for (let i = 0; i < months; i++) {
      futureValue *= (1 + monthlyInterestRate);
  }

  return futureValue.toFixed(2);
}

// Example usage:
const principalAmount = howMuchamountYousave; // ₹900,000
const annualInterestRate = Return; // 7%
const timePeriod = TotalAge; // 14 years

const futureAmounts = calculateFutureValue(principalAmount, annualInterestRate, timePeriod);
//console.log("The Investment value",futureAmounts);
//console.log("Feture Edeucation value",futureAmount)

const Total_amount = Math.round(futureAmount - futureAmounts) //269807
const TotalMomth = TotalAge*12 // 168 Month 

const monthlyAmount = Total_amount / TotalMomth ;
//console.log("Monthly amount:", monthlyAmount.toFixed(2));


const Amounttotext = convertRupeesIntoWords(Math.ceil(monthlyAmount))










// The money Converter

function formatIndianCurrency(amount) {
  const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
  });
  return formatter.format(amount);
}




  return (
    <>
       <Helmet>
        <title>Marriage calculator</title>
        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        {/* <meta name="keywords" content="best term insurance in india , buy term life insurance, compare and purchase term insurance" /> */}
      </Helmet>
<section className="margincalculator"></section>
<div className="childeducation">
<p className='breadcum'>Home &nbsp;&gt;&nbsp;&nbsp;&nbsp;<Link to={'/calculator'}>calculator</Link>&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{color:'green'}}>Marriage Calculator</span></p>
<h1>Marriage Expense <span className='Mainheading'>Calculator</span></h1>
<span className='spantext'>
The child's future marriage planning calculator by Beema1.com is specially designed to calculate the wedding cost and to help you towards planning a memorable wedding for your child.</span>
</div>



<div className='underdiv'>
<div className="latemaincalculator">

{tab === 1 && (


<div className="educationCalculators">




<p>Your child's age</p>
<div className="education-calculator">
  <div className='slider-lvh'>
    <Slider
      value={ChildAge}
      onChange={handleChildAgeChange}
      min={1}
      max={60}
      step={1}
      aria-label="Monthly Investment"
      valueLabelDisplay="auto"
    />
  </div>
  <div className='value-lvh'>
    <input 
      type="number" 
      value={ChildAge}     
      onChange={handleChildAgeChange} 
    />
  </div>
</div>

{/* The Second Section start hear */}

<p>Child’s age at the time of marriage</p>
<div className="education-calculator">
<div className='slider-lvh'>
<Slider
value={StartEducationAge}
              onChange={handleChildMarrageAgeChange} 
min={18}
max={26}
step={1}
aria-label="Monthly Investment"
valueLabelDisplay="auto"
/>
</div>
<div className='value-lvh'>
<input type="number" value={StartEducationAge}     
onChange={handleChildMarrageAgeChange}  />
</div>
</div>
{/* Therd Section start hear */}




   
        <div>
          <p>Expenditure on the marriage</p>
          <div className="education-calculator">
            <div className='slider-lvh'>
              <Slider
                value={CurrentEducationPrice}
                onChange={(e, newValue) => setCurrentEducationPrice(newValue)} 
                min={10000}
                max={10000000}
                step={10000}
                aria-label="Current Education Price"
                valueLabelDisplay="auto"
              />
            </div>
            <div className='value-lvh'>
              <input
                type="number"
                value={CurrentEducationPrice}     
                onChange={(e) => setCurrentEducationPrice(parseInt(e.target.value))}
              />
            </div>
          </div>
        </div>



      <p>Current savings for the marriage</p>
      <div className="education-calculator">
        <div className='slider-lvh'>
          <Slider
            value={howMuchamountYousave}
            onChange={(e, newValue) => sethowMuchamountYousave(newValue)} 
            min={10000}
            max={100000000}
            step={10000}
            aria-label="Current Education Price"
            valueLabelDisplay="auto"
          />
        </div>
        <div className='value-lvh'>
          <input
            type="number"
            value={howMuchamountYousave}     
            onChange={(e) => sethowMuchamountYousave(parseInt(e.target.value))}
          />
        </div>
      </div>
      

      <p>Rate of Influation</p>
      <div className="education-calculator">
        <div className='slider-lvh'>
          <Slider
            value={Influation}
            onChange={(e, newValue) => setInfluation(newValue)} 
            min={0}
            max={20}
            step={1}
            aria-label="Current Education Price"
            valueLabelDisplay="auto"
          />
        </div>
        <div className='value-lvh'>
          <input
            type="number"
            value={Influation}     
            onChange={(e) => setInfluation(parseInt(e.target.value))}
          />
        </div>
      </div>

      <p>Rate of Return</p>
      <div className="education-calculator">
        <div className='slider-lvh'>
          <Slider
            value={Return}
            onChange={(e, newValue) => setReturm(newValue)} 
            min={0}
            max={20}
            step={1}
            aria-label="Current Education Price"
            valueLabelDisplay="auto"
          />
        </div>
        <div className='value-lvh'>
          <input
            type="number"
            value={Return}     
            onChange={(e) => setReturm(parseInt(e.target.value))}
          />
        </div>
      </div>
   <div className="buttoncalculate">
   
   <button onClick={()=>settab(2)}>Calculate</button>
   
   
   </div>
</div>
)}
{tab === 2 && (
<div>
<div className="resultdivforchild">


<div className="calculateamount">

<div className='calculatemoney'>
<span>Amount you should save monthly</span>
<h2>{formatIndianCurrency(monthlyAmount.toFixed(2))}</h2>
<span>{Amounttotext}</span>
</div>
<div className="graphdiv">
<Chart
     type='donut'
     width={400}
     height={445}
     series={[CurrentEducationPrice, howMuchamountYousave]}
     options={{
       labels:['Expenditure','Savings']
     }}
   />
</div>

</div>


<div className="buttonback">
<button onClick={()=>settab(1)} className='buybutton'>Previous</button>    
 
</div>
</div>









</div>
)}
</div>
<div className="resultse">

<Calculatorlist/>

</div>
</div>




</>
  )
}

export default Marrige