import React, { useState, useEffect } from "react";
import "../UnderStandpolicy/Companypolicy.css";

import { AiOutlineCheck } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Alias this Link as RouterLink
import {Link as ScrollLink,
  Element,
  Events,
  scrollSpy,
} from "react-scroll"; 
import { Helmet } from "react-helmet-async";
import FastImage from "../../FastImage";
import { auto } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";








const Companypolicypage = () => {
  const [activeSection, setActiveSection] = useState("introduction");
  const { selectInsurance } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectInsurance]);

  const Arraydata = [
    {
      Go_Digit: {
        name: "Go Digit",
        imag: "Website%20Img/company_logo1706170629.webp?updatedAt=1718714076744",
        discription:
          "Digit Insurance is an Insurance tech start-up founded 4 years ago, and is backed by the Financial Services major Fairfax Group. Although it is relatively younger in the industry, it has a wide product list of Health, Travel, Motor, Home, Shop Insurance. There are only two Health policies, but with a decent number of features and even Health check-ups.",
        clamessettlement: 49.23,
        networkhospitals: 16400,
        metaTag: "Go Digit",
        metadescription: "metadiscription",
        planes: [
          {
            id: 0,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Dobule Wallet Plane Desh",
            discription:
              "Digital insurence smart plane offers you a compatble option that gives you a hight benifits and class option",
            link: "Double-Wallet-Plan-des",
          },
          {
            id: 1,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Infinity Wallet plane",
            discription:
              "Digital insurence smart plane offers you a compatble option that gives you a hight benifits and class option",
            link: "Infinity-Wallet-Plan-des",
          },
          {
            id: 2,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "World Wide Treatment Plane Desh",
            discription:
              "Digital insurence smart plane offers you a compatble option that gives you a hight benifits and class option",
            link: "Worldwide-Treatment-Plan-des",
          },
        ],
        cashless_claims: [
          {
            number: 1,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 2,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 3,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 4,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
        ],
        reimbursement_claims: [
          {
            number: 1,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 2,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 3,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 4,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
        ],
        renual: [
          {
            number: 1,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 2,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 3,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 4,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 5,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
        ],
      },
      Care_Health: {
        name: "Care Health",
        imag: "Website%20Img/company_logo1706170620.webp?updatedAt=1718714157426",
        discription:
          "Digit Insurance is an Insurance tech start-up founded 4 years ago, and is backed by the Financial Services major Fairfax Group. Although it is relatively younger in the industry, it has a wide product list of Health, Travel, Motor, Home, Shop Insurance. There are only two Health policies, but with a decent number of features and even Health check-ups.",
        clamessettlement: 49.23,
        networkhospitals: 12400,
        metaTag: "Care_Health",
        metadescription: "metadiscription",
        planes: [
          {
            id: 0,
            name: "Care Supreme des",
            discription:
              "Digital insurence smart plane offers you a compatble option that gives you a hight benifits and class option",
            link: "Care-Supreme-des",
            metaTag: "Go Digit",
            metadescription: "metadiscription",
          },
        ],
        cashless_claims: [
          {
            number: 1,
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
            metaTag: "Go Digit",
            metadescription: "metadiscription",
          },
          {
            number: 2,
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
            metaTag: "Go Digit",
            metadescription: "metadiscription",
          },
          {
            number: 3,
            name: " Inform the Hospital Desk",
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 4,
            name: " Inform the Hospital Desk",
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
        ],
        reimbursement_claims: [
          {
            number: 1,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 2,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 3,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
          {
            number: 4,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: " Inform the Hospital Desk",
            discription:
              "All network hospitals have an insurance desk. You can submit your policy copy (even soft copy works), ID proof, initial diagnosis report and the insurance desk will request the insurance company for “pre-authorization”. In case of planned treatments, you need to do this process 2 days before hospitalization",
          },
        ],
        renual: [
          {
            number: 1,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 2,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 3,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 4,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
          {
            number: 5,
            metaTag: "Go Digit",
            metadescription: "metadiscription",
            name: "Inform the Hospital Desk",
            discription:
              "You’d be asked to provide your current policy number which you can find in the policy soft copy. You may as well be asked to enter your mobile number, email ID, Date of Birth or policy expiry date.",
          },
        ],
      },
    },
  ];

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      //console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      //console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const insuranceData = Arraydata.find(
    (insurance) => insurance[selectInsurance],
  );

  if (!insuranceData) {
    return <div>Insurance data not found for {selectInsurance}</div>;
  }
  const insurance = insuranceData[selectInsurance];

  const InsureCompanys = [
    {
      id: 0,
      name: "Care Health",
      img: "Website%20Img/company_logo1706170620.webp?updatedAt=1718714157426",
      route: "Care_Health",
    },
    {
      id: 1,
      name: "Digit",
      img: "Website%20Img/company_logo1706170629.webp?updatedAt=1718714076744",
      route: "Go_Digit",
    },
  ];

  return (
    <div>
      {Arraydata.map((data, index) => {
        //console.log(data);
        return (
          <div className="maincompanydiv">
            <div className="sildebarCompany">
              <div className="Companydetails">
                <div className="Companynamedetails">
                <Helmet>
                <title>{data.metaTag}</title>
                  <meta
                    name="description"
                    content={data.metadescription}
                  />
                </Helmet>
                  <div>
                  <FastImage
                        url={insurance.imag}
                        width={auto}
                        height={auto}
                        alt='Callbackagent'
                     
                      />
                  
                    <h4>{insurance.name}</h4>
                  </div>
                </div>

                <div className="menucompany">
                  <ScrollLink
                    to={"introduction"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    // activeClass="active"
                    onSetActive={(section) => setActiveSection(section)}
                  >
                    <li
                      className={
                        activeSection === "introduction"
                          ? "Activeclass"
                          : "deactiveclss"
                      }
                    >
                      Overview
                    </li>
                  </ScrollLink>

                  <ScrollLink
                    to={"Planes"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    // activeClass="active"
                    onSetActive={(section) => setActiveSection(section)}
                  >
                    <li
                      className={
                        activeSection === "Planes"
                          ? "Activeclass"
                          : "deactiveclss"
                      }
                    >
                      Related Plane's
                    </li>
                  </ScrollLink>

                  <ScrollLink
                    to={"clamemanagement"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    // activeClass="active"
                    onSetActive={(section) => setActiveSection(section)}
                  >
                    <li
                      className={
                        activeSection === "clamemanagement"
                          ? "Activeclass"
                          : "deactiveclss"
                      }
                    >
                      Clame Management
                    </li>
                  </ScrollLink>

                  <ScrollLink
                    to={"renual"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    // activeClass="active"
                    onSetActive={(section) => setActiveSection(section)}
                  >
                    <li
                      className={
                        activeSection === "renual"
                          ? "Activeclass"
                          : "deactiveclss"
                      }
                    >
                      Renewal Policy
                    </li>
                  </ScrollLink>

                  <ScrollLink
                    to={"related"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    // activeClass="active"
                    onSetActive={(section) => setActiveSection(section)}
                  >
                    <li
                      className={
                        activeSection === "related"
                          ? "Activeclass"
                          : "deactiveclss"
                      }
                    >
                      Related Company's
                    </li>
                  </ScrollLink>
                </div>
              </div>
            </div>

            <div className="maincontentCompany">
              <Element name="introduction">
                <Helmet>
                  <title>{insurance.metaTag}</title>
                  <meta
                    name="description"
                    content={insurance.metadescription}
                  />
                  </Helmet>
                  <div className="aboutsection">
                    <span className="catagory">HEALTH INSURENCE</span>
                    <br />
                    <h3>{insurance.name}</h3>

                    <div className="discriptioncompany">
                      <span>{insurance.discription}</span>
                    </div>

                    <div className="chickens">
                      <h2>
                        <AiOutlineCheck /> &nbsp; Claim Settlement Ratio -{" "}
                        {insurance.clamessettlement}% (average of last 3 years)
                      </h2>

                      <h2>
                        <AiOutlineCheck /> &nbsp; Network Hospitals -{" "}
                        {insurance.networkhospitals}+
                      </h2>
                    </div>
                  </div>
             
              </Element>

              <h3 className="companyheading"> Health insurance plans</h3>

              <Element name="Planes">
                <div className="viewpolicys">
                  {insurance.planes.map((data, index) => {
                    return (
                      <>
                      <Helmet>
                      <title>{insurance.metaTag}</title>
                      <meta
                          name="description"
                          content={insurance.metadescription}
                        />
                        </Helmet>
                        <div className="policycards">
                          <h4>{data.name}</h4>
                          <span>{data.discription}</span>
                          <br />
                          <Link to={`${data.link}`}>
                            View Policy
                            <FaLongArrowAltRight />
                          </Link>
                        </div>
              
                      </>
                    );
                  })}
                </div>
              </Element>

              <div className="headingdivas">
                <h3>Health insurance claim process</h3>
                <span>
                  Digit claims can be of different types based on the hospital
                  in which you are getting the treatment. If it’s part of
                  Digit's network hospitals, you can get a “Cashless claim” in
                  which insurance company directly pays the bills to the
                  hospital. But if your choice of hospital is not part of their
                  network, then you have to pay the bills and apply for
                  reimbursement claim. Here’s a brief process for both types of
                  claims:
                </span>
              </div>

              <Element name="clamemanagement">
                <div className="clamepolicycompany">
                  <div className="classlessclame">
                    <h3 className="headerclass">Cashless Claims</h3>
                    {insurance.cashless_claims.map((data, index) => {
                      return (
                        <>
                        <Helmet>
                        <title> {data.metaTag}</title>
                        <meta
                            name="description"
                            content={data.metadescription}
                          />
                          </Helmet>
                          <div className="detailsdivs">
                            <h3 className="clamename">
                              <span className="numberingspan">
                                {data.number}
                              </span>{" "}
                              {data.name}
                            </h3>
                            <span>{data.discription}</span>
                            </div>
                            </>
                      );
                    })}
                  </div>

                  <div className="reambusment">
                    <h3 className="headerclass">Reimbursement Claims</h3>

                    {insurance.reimbursement_claims.map((data, index) => {
                      return (
                        <div className="detailsdivs">
                          <h3 className="clamename">
                            <span className="numberingspan">{data.number}</span>{" "}
                            {data.name}
                          </h3>
                          <span>{data.discription}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Element>

              <div className="headingdivas">
                <h3>Digit Health Insurance Renewal</h3>
                <span>
                  Firstly, if you’ve purchased the policy online, you’ll always
                  receive an intimation e-mail from the insurer around 30 days
                  before the renewal date. The e-mail will have a renewal link
                  that will directly take you to the page where you can make the
                  payment and check policy details. And it’s done. You’ll
                  receive the policy document via e-mail.
                </span>
              </div>

              <Element name="renual">
                <div className="companyinsurencerenual">
                  {insurance.renual.map((data, index) => {
                  
                    return (
                      <>
                      <Helmet>
                      <title> {data.metaTag}</title>
                      <meta
                          name="description"
                          content={data.metadescription}
                        />
                        </Helmet>
                        <div className="renuallistdiv">
                          <h3 class="clamename">
                            <span class="numberingspan">{data.number}</span>{" "}
                            {data.name}
                          </h3>
                          <span>{data.discription}</span>
                          </div>
                        
                          </>
                    );
                  })}
                </div>
              </Element>

              <Element name="related">
                <div className="relatedpolicys">
                  {InsureCompanys.map((data, index) => {
                    return (
                      <>
                  
                      <Helmet>
                      <title>{data.metaTag}</title>
                      <meta name="description" content={data.metadescription} />
                    </Helmet>
                        <Link to={`/health-insurence/${data.route}`}>
                          <div key={index} className="relatedpolicyses">
                          <FastImage
                        url={data.img}
                        width={auto}
                        height={auto}
                        alt='Callbackagent'
                     
                      />
                            <br />
                            <span>{data.name}</span>
                          </div>
                        </Link>
                   
                      </>
                    );
                  })}
                </div>
              </Element>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Companypolicypage;
