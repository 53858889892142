import React, { useState, useEffect } from "react";
import "./AgeModal.css";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import calculateAge from "./AgeModalHelper";
import { UpdatePlanAgeApi } from "../../redux/Slice";
import axios from "axios";
import { encryption } from "../../helper/common";
import { useNavigate } from "react-router-dom";
import ProposalPop from "../../commonComponents/ProposalPop";
import {
  UpdatePlanApi,
  updateCoverAmount,
  updateInsuredData,
  updateTotalAmount,
  updateProposalClick,
  updateInsuredMembers,
  resetAddonData,
  updatePremium,
  updateFullHdfcPremium,
  selectProposal,
  updateInsuredArray,
  updatePremiumData,
  settingProposal,
} from "../../redux/Slice";
import { useDispatch } from "react-redux";
import Loader from "../../../AllPageComponents/Loader";
import Swal from "sweetalert2";

import { updateQuickQuoteCompanywise } from "../../Api/Api";
import { settingReduxData, updateQuickQuote} from "../../Api/Api";



const AgeModal = ({
  isOpen,
  showModal,
  setShowModal,
  ageDifference,
  ageSame,
  setProposalCorrect,
  setProposerCorrect,
  apiResponse,
  setApiResponse,
  proposerData,
  socketManager,
  apidata,
  calculateAge,
  setProposerData,
  previousPremium


}) => {
  const [isActive, setIsActive] = useState(true);

  const [isClosing, setIsClosing] = useState(false);
  const [newPremium, setNewPremium] = useState("");
  const [newUserData, setNewUserData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [stopLoading, setStopLoading] = useState(false);
  const [showPop, setShowPop] = useState(false);

  const [appear, setAppear] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");


  const nav = useNavigate();
  const updateAllInsuredAges = (insuredDetails, newAges) => {
    let updatedInsuredDetails = [];

    let insuredArr = insuredDetails;
    insuredArr.forEach((insured, index) => {
      let ageChangeFound = false;
      for (var i = 0; i < newAges.length; i++) {
        let newAgeItem = newAges[i];

        //if index of current relation was found in the new ages list
        if (newAgeItem.index == index) {
          ageChangeFound = true;

          // Check if the age in the insuredDetails array is different from the corresponding newAge
          if (insured.InsuredAge !== newAgeItem.newAge) {
            // If ages are different, create a new object with the updated age and push it to the updatedInsuredDetails array
            updatedInsuredDetails.push({
              ...insured,
              InsuredAge: newAgeItem.newAge,
            });
          } else {
            // If ages are the same, push the original insured object to the updatedInsuredDetails array
            updatedInsuredDetails.push(insured);
          }
        }

        if (ageChangeFound) break;
      }
      if (!ageChangeFound) {
        updatedInsuredDetails.push(insured);
      }
    });

    return updatedInsuredDetails;
  };

  // const onMessageRecievedInAgeModal = 
  

  const premiumChange = (companyName, planCode) => {
    setIsLoading(true);

    let data = socketManager.isConnected();
    console.log("data in socket check", data);

    if(data){
      console.log("apiResponse", apiResponse);

      // getting the new insuredage array from localstorage ---------------
      const storedInsuredDetail = proposerData.InsuredDetail;
      let parsedInsuredDetail = null;
      // if (typeof storedInsuredDetail === 'string') {
      //   try {
      //     parsedInsuredDetail = JSON.parse(storedInsuredDetail);
      //   } catch (error) {
      //     console.error("Failed to parse storedInsuredDetail as JSON:", error);
      //   }
      // }
  
      let updatedInsuredDetails = updateAllInsuredAges(
        storedInsuredDetail,
        ageDifference
      );
  
      ////console.log("the arr in useEffect", arr);
      //console.log("the updated insured", updatedInsuredDetails);
      // console.log("modalresponse", apiResponse)
  
      // let payloadnew =
      //   {
      //     ProposalName: proposerData.InsuredDetail[0].InsuredName,
      //     Proposalphonenumber:modalResponse.request.Proposalphonenumber,
      //     PinCode: modalResponse.request.PinCode,
      //     District: modalResponse.request.District,
      //     State: modalResponse.request.State,
      //     PED:modalResponse.request.PED,
      //     Gender: proposalData.Gender,
      //     tenure: modalResponse.request.tenure,
      //     sum_insured: modalResponse.request.sum_insured,
      //     InsuredDetail:updatedInsuredDetails,
      //     PlanCode: plan_id,
      //     CompanyName: c_id,
      //     HDCRiderSI : modalResponse?.request?.HDCRiderSI ? modalResponse.request.HDCRiderSI :0,
      //     addons : modalResponse?.request?.addons ? modalResponse.request.addons :"",
      //     deductible : modalResponse?.request?.deductible ? modalResponse.request.deductible :0
      // }
  
      console.log("the proposerdata in the agemodal", proposerData);
      console.log("the apiResponse in agemodal", apiResponse)
  
      const updatedUserdata = {
        ...proposerData,
        CompanyName: c_id,
        PlanCode: plan_id,
        InsuredDetail: updatedInsuredDetails,
        State: "West Bengal",
        sum_insured: apiResponse?.request?.sum_insured,
        addons: apiResponse?.request?.addons || "",
        addonPrice: apiResponse?.request?.addonPrice || "" ,
        // addonPrice: "" ,
        tenure: apiResponse.request.tenure,
        PED:JSON.parse(localStorage.getItem('PED')),
        voluntarydeductiblelimit: apiResponse.request.voluntarydeductiblelimit || null,
        roomrent_type: apiResponse.request.roomrent_type || null,
        cdb_limit: apiResponse.request.cdb_limit || null,
        ped_waiting_period: apiResponse.request.ped_waiting_period || null,
        reductioninwaitingperiodlimit: apiResponse.request.reductioninwaitingperiodlimit || null,
        opd_sum_insured:apiResponse.request.opd_sum_insured || null,
        opd_plan:apiResponse.request.opd_plan || null,
        super_charger_option:apiResponse.request.super_charger_option || null,
        NetworkAdvantageApplicable : "no",
        HDCRiderSI: apiResponse?.request?.HDCRiderSI || 0,
        deductible: apiResponse?.request?.deductible || 0,
        // addonlist:""
  
      };
  
      // console.log("the updated userData", payloadnew);
      // alert(JSON.stringify(updatedUserdata));
  
      // setNewUserData(payloadnew);
  
      updateQuickQuoteCompanywise(updatedUserdata, unique_id).then((data) => {
        if (data) {
          setApiResponse((prevData) => ({
            ...prevData,
            request: apiResponse.request,
          }));
        }
  
        // if (data?.data) {
        //   setResponseData(data?.data);
        // }
        // if (data.data.response == null) {
        //   setStopLoading(true);
        //   setIsLoading(false);
        //   return;
        // }
        // dispatch(settingProposal(data.data.request));
  
        // console.log("the data", data.data.response.premiumBreakup.totalPremium);
        // if (showModal == true) {
        //   // dispatch(updateInsuredArray(data.data.request.InsuredDetail));
        //   // data.data.request.InsuredDetail.map((item, index) => {
        //   //   // Dispatch action to update insured data in Redux
        //   //   dispatch(updateInsuredData(item));
        //   // });
  
        //   //finally, all old data is updated, so push to local storage
  
        //   data.data.request.InsuredDetail &&
        //     localStorage.setItem(
        //       "InsuredDetail",
        //       JSON.stringify(data.data.request.InsuredDetail)
        //     );
        // }
        // if (totalPremium == data.data.response.premiumBreakup.totalPremium) {
        //   // submitAccept();
        //   if (c_id == "HDFC") {
        //     // dispatch(updateFullHdfcPremium(premiumData));
        //   }
        //   nav(
        //     "/" +
        //       "medicaldetails" +
        //       "?req_id=" +
        //       unique_id +
        //       "&c_id=" +
        //       c_id +
        //       "&plan_id=" +
        //       plan_id
        //   );
  
        //   return;
        // }
  
        // setIsLoading(false);
        // console.log(
        //   "the new premium response",
        //   data.data.response.premiumBreakup.totalPremium
        // );
  
        // if (c_id == "HDFC") {
        //   setPremiumData(data.data.response.hdfc_premium);
        // }
        // if (data.data.response) {
        //   setNewPremium(data.data.response.premiumBreakup.totalPremium);
        // }
        // setAppear(true);
      });
    }
    else{
      setIsLoading(false);
      return
    }
  
  };


 



  const settingData = async (value) => {
    try {
      console.log("the proposerData", proposerData);

      let phNo = localStorage.getItem("Proposalphonenumber");

      let payloadData = {
        proposal_data: value,
        phone_no: proposerData?.Proposalphonenumber,
        
      };

      console.log("the payloaddata in settingdata", payloadData);

      let response = await settingReduxData(unique_id, payloadData).then(
        (response) => {
          console.log("the response in set reduxdata", response);
        }
      );
    } catch (error) {
      console.log("the error", error);
    }
  };



  



const submitAccept =async()=>{

  let newInsuredDetail = proposerData.InsuredDetail.map((item,index)=>{

    let newAge = calculateAge(item.dateOfbirth);

    item.InsuredAge = newAge;

    return item

  })

  console.log("newInsuredDetail data ", newInsuredDetail);

//  localStorage.setItem('InsuredDetail',newInsuredDetail);
localStorage.setItem('InsuredDetail', JSON.stringify(newInsuredDetail));

let payVal ={
  ...proposerData,
  InsuredDetail : newInsuredDetail
}

 setProposerData((prevData)=>(
{
  ...prevData,
  InsuredDetail:newInsuredDetail
}
 )
 )

//  let payloadData = {
//   phone_no: proposerData.Proposalphonenumber,
//   proposal_data : payVal

// };
settingData(payVal)


 nav(
          "/" +
            "medicaldetails" +
            "?req_id=" +
            unique_id +
            "&c_id=" +
            c_id +
            "&plan_id=" +
            plan_id
        );



}

console.log("the proposerdata", proposerData)


// const submitViewPlan = () => {
//   dispatch(updateInsuredArray(newUserData.InsuredDetail));

//   //finally, all old data is updated, so push to local storage

//   newUserData &&
//     localStorage.setItem(
//       "InsuredDetail",
//       JSON.stringify(newUserData.InsuredDetail)
//     );
//   // dispatch(updateInsuredData(newUserData.InsuredDetail));

//   // navigation to plans page and update the age ------------
//   handleOnClickView();
// };


// const handleOnClickView = async () => {
//   // Call the API or perform any other necessary action
//   setIsLoading(true);
//   try {
//     // let payloadRequest = {
//     //   ...modalResponse.request,
//     //   planCode: plan_id,
//     //   CompanyName: c_id,
//     //   InsuredDetail: newUserData.InsuredDetail,
//     // };
//     let payloadRequest = {
    
//         ProposalName: proposalData.InsuredDetail[0].InsuredName,
//         Proposalphonenumber:modalResponse.request.Proposalphonenumber,
//         PinCode: modalResponse.request.PinCode,
//         District: modalResponse.request.District,
//         State: modalResponse.request.State,
//         PED:modalResponse.request.PED,
//         Gender: proposalData.Gender,
//         tenure: modalResponse.request.tenure,
//         sum_insured: modalResponse.request.sum_insured,
//         InsuredDetail: responseData.request.InsuredDetail,
//         PlanCode: plan_id,
//         CompanyName: c_id,
//         HDCRiderSI : modalResponse?.request?.HDCRiderSI ? modalResponse.request.HDCRiderSI :0,
//         addons : modalResponse?.request?.addons ? modalResponse.request.addons :"",
//         deductible : modalResponse?.request?.deductible ? modalResponse.request.deductible :0
    
    
//     }

//     let response = await updateQuickQuoteCompanywise(
//       payloadRequest,
//       unique_id
//     ).then((res) => {
//       setResponseData(res.data);
//       if (res.data.response == null) {
//         setStopLoading(true);
//         setIsLoading(false);
//         return;
//       }
//       // dispatch(settingProposal(res.data.request));

//       setIsLoading(false);

//       nav(`/plans?req_id=${unique_id}`);
//     });
//   } catch (error) {
//     console.log("the error", error);
//   }
//   //console.log("the localstorage", localStorage);

//   // ---------------------------------------------------------------------------------
// };





const viewPlan = async ()=>{

  let newInsuredDetail = proposerData.InsuredDetail.map((item,index)=>{

    let newAge = calculateAge(item.dateOfbirth);

    item.InsuredAge = newAge;

    return item

  })

  console.log("newInsuredDetail data ", newInsuredDetail);

 localStorage.setItem('InsuredDetail',JSON.stringify(newInsuredDetail));

 let payVal = {
  ...proposerData,
  InsuredDetail : newInsuredDetail
 }


 setProposerData((prevData)=>(
{
  ...prevData,
  InsuredDetail:newInsuredDetail
}
 )
 )


//  let payloadData = {
//   phone_no: proposerData.Proposalphonenumber,
//   proposal_data : payVal

// };
settingData(payVal)

//  nav(
//           "/" +
//             "medicaldetails" +
//             "?req_id=" +
//             unique_id +
//             "&c_id=" +
//             c_id +
//             "&plan_id=" +
//             plan_id
//         );

        nav(`/plans?req_id=${unique_id}`);

}




  useEffect(() => {
    premiumChange();
    // socketManager.subscribe("message", onMessageRecievedInAgeModal);
  }, []);
  useEffect(() => {

    let datacheck = socketManager.isConnected();
    console.log("datacheck", datacheck);

    if(datacheck){
      socketManager.subscribe("message", async (data) => {
        console.log("new data socket agemodal", data)
        const { company_name, plan_code, emit_data } = data;
        console.log("----------------------called subscriber------------------");
        // console.log("company-name", company_name);
        // console.log("plan-code", plan_code);
        console.log("emit data in socket", emit_data);
    
        let emitval;
    
        if (emit_data && apiResponse.request) {
          if (emit_data[c_id]) {
            emitval = emit_data[c_id][0];
    
            // setApiResponse((prevState) => ({
            //   ...prevState,
            //   response: emitval
            // }));
    
            console.log("emitval", emitval);
  
         
            setNewPremium(emitval.premiumBreakup.totalPremium)
    
            // if (emitval != null) {
            //   let payloadData = {
            //     ...apiResponse.request,
            //   };
            //   let data = await updateDatabase(unique_id, payloadData);
    
            //   console.log("the data api", data);
    
            //   if (data?.message == "Request updated") {
            //     // setLoading(false);
            //   }
            // }
    
            setApiResponse({
              request: apiResponse.request,
              response: emitval,
            });
  
            if(previousPremium == emitval.premiumBreakup.totalPremium){
  
              submitAccept()
  
              // nav(
              //   "/" +
              //     "medicaldetails" +
              //     "?req_id=" +
              //     unique_id +
              //     "&c_id=" +
              //     c_id +
              //     "&plan_id=" +
              //     plan_id
              // );
  
              return
  
            }
            setIsLoading(false);
            setAppear(true);
  
  
          }
        } else {
          return;
        }
      });
    }
   
  
    // onMessageRecievedInAgeModal(socketManager.getLatestData("message"));
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {/* {stopLoading == false && ( */}
      <div className={appear ? "modal-wrapper" : "appear-none"}>
        <div
          className={`overlay ${isActive ? "active" : ""}`}
          // onClick={handleClose}
        ></div>
        <div
          className={`slideInComponent ${isActive ? "active" : ""} ${
            isClosing ? "closing" : ""
          }`}
        >
          {/*_____________ the header part start here ___________  */}

          <div className="ageupper">
            <h3>Premium Revised</h3>
            {/* <button className="ageCloseButton" onClick={handleClose}>
              <RxCross1 className="ageCloseButton-icon" />
            </button> */}
          </div>

          {/*________ header end _____ */}

          {/* the body of modal start _________ */}

          <div className="agecontext">
  <p className="contextwarning">
    Please Note: - Based on the birth date entered your premium has been revised.
  </p>
  {/* Insurer details */}
  <div className="d-flex align-items-center">
    <img
      style={{ width: "80px" }}
      src={apiResponse?.response?.insurerLogo}
      alt="Insurer Logo"
    />
    {/* 
    <p className="p-2">
      {modalResponse.response.planName} - {modalResponse.response.planDescription}
    </p> 
    */}
  </div>

  {/* Member details with age differences */}
  {ageDifference.map((ageItem, index) => {
    console.log("agedifference data", ageDifference);
    return (
      <div className="age-member-details" key={index}>
        <p className="age-membername">Member - {ageItem.item.InsuredName}</p>
        <div className="age-difference-details">
          <div className="agedetails-flex">
            <p>Previous age</p>
            <h3>
              <s>
                {ageItem.item.InsuredAge === 0
                  ? "< 91 Days"
                  : `${ageItem.item.InsuredAge} Years`}
              </s>
            </h3>
          </div>
          <div className="agedetails-flex" style={{ marginRight: "28px" }}>
            <p>As per date of birth</p>
            <h3>
              {calculateAge(ageItem.item.dateOfbirth) === 0
                ? "< 91 Days"
                : `${calculateAge(ageItem.item.dateOfbirth)} Years`}
            </h3>
          </div>
        </div>
      </div>
    );
  })}

  {/* Member details with same ages */}
  {ageSame.map((ageItem, index) => {
    return (
      <div className="age-member-details" key={index}>
        {/* Uncomment if InsuredName is needed */}
        <p className="age-membername">Member - {ageItem.InsuredName}</p>
        <div className="age-difference-details">
          <div className="agedetails-flex">
            <p>Current age</p>
            {/* Uncomment if calculateAge is needed */}
            <h3>
              {calculateAge(ageItem.dateOfbirth) === 0
                ? "< 91 Days"
                : `${calculateAge(ageItem.dateOfbirth)} Years`}
            </h3>
          </div>
        </div>
      </div>
    );
  })}
</div>


          <div className="age-premium-details">
            <div className="age-premium-inner">
              <p>Old Premium</p>
              <span><s>{previousPremium}/-</s></span>
            </div>

            <div className="age-premium-inner agerightside">
              <p>Revised Premium</p>
              <span>
            
            <b>{newPremium}/-</b>
          </span>
              {/* {newPremium ? ( // Render if newPremium has a value
                  <span>
                    <b>{newPremium}/-</b>
                  </span>
                ) : (
                  // Render Loader component if newPremium is still loading
                  <Loader />
                )} */}
            </div>
          </div>
          <div className="age-premium-footer">
            <div className="age-premium-change">
              <div className="age-footer-upper">
                <p className="totalprimuim"> Total Premium </p>
                <div className="age-footer-premium-detail">
                  <p className="age-old-premium">
                    {/* <s>{apiResponse.response.premiumBreakup.totalPremium}/-</s> */}
                  </p>
                  {newPremium ? (
                      <p className="age-new-premium">{newPremium}/-</p>
                    ) : (
                      <Loader />
                    )}
                </div>
              </div>

              <div className="age-footer-btn">
                <button
                  // onClick={() => {
                  //   setShowPop(true);
                  //   setTimeout(() => {
                  //     setShowPop(false);

                  //     setIsLoading(true);
                  //     submitAccept();
                  //     // if (c_id == "HDFC") {
                  //     //   dispatch(updateFullHdfcPremium(premiumData));
                  //     // }
                  //   }, 3000);
                  // }}
                  onClick={()=>{
                    submitAccept()
                  }}
                  className="acceptplane"
                >
                  Accept & continue
                </button>
                <button
                  // onClick={() => {
                  //   setShowPop(true);

                  //   setTimeout(() => {
                  //     setShowPop(false);

                  //     setIsLoading(true);
                  //     submitViewPlan();
                  //     setProposalCorrect(false);
                  //     setProposerCorrect(false);
                  //   }, 3000);
                  // }}

                  onClick={()=>{
                    viewPlan()
                  }}
                  className="acceptplane"
                >
                  View other plans
                </button>
              </div>
            </div>
          </div>

          {/* {showPop && (
            <ProposalPop
              show={showPop}
              onClose={() => setShowPop(false)}
              onExited={handlePopupClose} // Trigger after popup has exited
            />
          )} */}

          {/* __________the modal body end here _________ */}
        </div>
      </div>
      {/* // )} */}
    </>
  );
};

export default AgeModal;