import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../PlansPage/Filter";
import Summary from "../../commonComponents/Summary";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "../../CommonCssFiles/planstyle.css";
import Loader from "../../../AllPageComponents/Loader";
import "./AfterPlanFatch.css";
import { GoPlus } from "react-icons/go";
import { FaPlus } from "react-icons/fa6";
import { updateData } from "../../redux/Slice";
import { getMaxAge, premiumDetails } from "../../helper/common";
import Redirect from "../../../AllPageComponents/Rederict";
import Swal from "sweetalert2";
import {
  afterPlanFetchApi,
  updateQuickQuoteCompanywise,
  AddonApi,
  updateAddonApi,
  updateDatabase,
} from "../../Api/Api";
import { filterCompany } from "../../helper/common";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Helmet } from "react-helmet-async";
import { formatToINR } from "../../../HomePage/MoneyHealper/Moneyhelper";
import { formatAmount } from "../PlansPage/HelperMoneyconverter/MoneyConverter";
import { settingReduxData, getReduxData } from "../../Api/Api";
import { Skeleton } from "@mui/material";

// import { getSocket } from "../../../socket";

const Afterplanfatch = ({ socketManager }) => {
  const [apiResponse, setApiResponse] = useState(null);
  // const [loading, setLoading] = useState(false);

  const [oneData, setOneData] = useState();
  const [twoData, setTwoData] = useState();
  const [threeData, setThreeData] = useState();
  const [addonlist, setAddonList] = useState();

  const [addonArr, setAddonArr] = useState([]);

  const [deductibleVal, setDeductibleVal] = useState(25000);
  const [hdcVal, setHdcVal] = useState(1000);

  const [coverVal, setCoverVal] = useState("");

  const [stopLoading, setStopLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [CoverAmountHandelChnage, setCoverAmountHandelChnage] = useState([]);

  const [showhdc, setShowHdc] = useState(false);
  const [maxAge, setMaxAge] = useState("");
  const [beforeAddon, setBeforeAddon] = useState();
  const [addonPrev, setAddonPrev] = useState([]);
  const [addonDataList, _setAddonDataList] = useState([]);

  const [addonJson, setAddonJson] = useState([]);
  const [premiumBaseData, setPremiumBaseData] = useState();
  const [relDeductible, setRelDeductible] = useState(25000);

  const [hosDeductible, setHosDeductible] = useState(1000);
  const [pedDeduct, setPedDeduct] = useState("12 Months");
  const [redIll, setRedIll] = useState("6 Months");
  const [roomtype, setRoomType] = useState("Twin Sharing");

  const [planChoose , setPlanChoose] = useState("select plan");
  const [siChoose , setSiChoose] = useState("");

  const [smartData , setSmartData] = useState("Option 1");
  const [extData , setExtData] = useState();
  







  const [finalAddonList, setFinalAddonList] = useState();

  const [previousAddon , setPreviousAddon] = useState([]);

  const [addonlistArray , setAddonListArray] = useState([]);

  const [proposerDataAfter , setProposerDataAfter] = useState();

  const [showPriceData , setShowPriceData] = useState();

  let setAddonDataList = (t) => {
    console.log("tried setting addons");
    _setAddonDataList(t);
  };

  const [apidata, setApiData] = useState({});

  const handleError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
    setStopLoading(true);
  };

  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const unique_id = params.get("req_id");
  const plan_id = params.get("plan_id");
  const c_id = params.get("c_id");

  const countMember = (response) => {
    try {
      if (response?.data?.request?.InsuredDetail && plan_id === "Family") {
        let adultPerson = 0;
        let childPerson = 0;
        let parentPerson = 0;
        const adultRelations = [
          "Self",
          "Wife",
          "Father",
          "Mother",
          "Grand Father",
          "Grand Mother",
          "Husband",
          "Brother",
          "Sister",
        ];
        const parentRelations = [
          "Father",
          "Mother",
          "Father In Law",
          "Mother In Law",
        ];
        const childRelations = ["Son", "Daughter"];
        response.data.request.InsuredDetail.forEach((item) => {
          if (adultRelations.includes(item.InsuredRelation)) {
            if (parentRelations.includes(item.InsuredRelation)) {
              parentPerson += 1;
            } else {
              adultPerson += 1;
            }
          } else if (childRelations.includes(item.InsuredRelation)) {
            childPerson += 1;
          }
        });
        if (
          (adultPerson === 2 && parentPerson === 0 && childPerson <= 6) ||
          (adultPerson === 2 && parentPerson === 2 && childPerson <= 6) ||
          (adultPerson === 2 && parentPerson === 2 && childPerson <= 6) ||
          (adultPerson === 2 && childPerson <= 6 && parentPerson === 0) ||
          (adultPerson === 2 && childPerson <= 6 && parentPerson === 1) ||
          (adultPerson === 1 && childPerson <= 6 && parentPerson === 0)
        ) {
          setShowHdc(true);
        } else {
          setShowHdc(false);
        }
      } else if (plan_id == "Individual") {
        setShowHdc(true);
      } else {
        setShowHdc(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  

  const settingData = async (premiumJson) => {
    try {
      setLoading(true);
      let phNo = localStorage.getItem("Proposalphonenumber");
  
      let payload = {
        ...proposerDataAfter,
        ...premiumJson
      };
  
      let extInfo = {
        ...extData,
        "show_price": showPriceData
      };
  
      let payloadData = {
        proposal_data: payload,
        phone_no: phNo,
        extra_data: extInfo,
      };
      console.log("payloadData", payloadData);
  
      let response = await settingReduxData(unique_id, payloadData);
      console.log("the response in set reduxdata", response);
      
      setLoading(false);
      return response; // Ensure response is returned
    } catch (error) {
      console.log("the error", error);
      setLoading(false);
      return null; // Return null in case of error to avoid undefined errors
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      let response = await getReduxData(unique_id).then((response) => {
        // console.log("the response in get reduxdata", response.data.data[0].proposal_data)

        // if (response?.data?.data[0]?.proposal_data) {
        //   let proposerInfo = {
        //     grossTotalPremium:
        //       response?.data?.data[0]?.proposal_data.totalPremium,
        //     grossGstAmount: response?.data?.data[0]?.proposal_data.gst,
        //     grossBaseAmount: response?.data?.data[0]?.proposal_data.grossAmount,
        //   };

        //   // setPremiumBaseData(proposerInfo);
        // }
        console.log("extdata",response?.data?.data[0]?.extra_data)
        setProposerDataAfter(response?.data?.data[0]?.proposal_data);
        // setExtData(response?.data?.data[0]?.extra_data);

        // let priceValue = response?.data?.data[0]?.extra_data;
        setExtData(response?.data?.data[0]?.extra_data);
        setShowPriceData(response?.data?.data[0]?.extra_data?.show_price)

    //     let val = {
    //       "c_id" : c_id,
    //       "plan_id" : plan_id,
    //       "show_price" : true
    //     }
    //  if(priceValue){
    //     setExtData(val);

    //   }


        return response?.data?.data[0]?.proposal_data;
      });

      return response;
    } catch (error) {
      console.log("the error", error);
      handleError("there is an error in getdata")

    }
  };

  function formatNumber(input) {
    // Regular expression to validate numbers with optional commas
    const numberWithCommasRegex = /^-?\d{1,3}(,\d{3})*(\.\d+)?$/;
  
    if (typeof input === "string" && numberWithCommasRegex.test(input)) {
      // Remove commas and convert to number
      return parseFloat(input.replace(/,/g, ""));
    }
  
    // If the input doesn't match the expected format, return it as is
    return input;
  }
  

  const fetchPlan = async () => {
    try {
      setLoading(true);
      const response = await afterPlanFetchApi(unique_id, c_id, plan_id);
      setCoverAmountHandelChnage(response.data.response.si);

      if (response.data.response == null) {
        setLoading(false);
        setStopLoading(true);
        handleError("Opps there is an Error");
        return;
      }

      // if (response.data.request.addons == "" || !response.data.request.addons) {
      //   let data = premiumDetails(
      //     response.data.response.premiumBreakup.totalPremium
      //   );

      //   console.log("the data in afterplanfetch", data);

      //   let proposerData = {
      //     grossTotalPremium: response.data.response.premiumBreakup.totalPremium,
      //     grossGstAmount: data.gst,
      //     grossBaseAmount: data.grossAmount,
      //   };
      //   let totalData;

      //   let getDataRes = await getData().then((getDataRes) => {
      //     if (getDataRes) {
      //       totalData = {
      //         ...getDataRes,
      //         ...proposerData,
      //       };
      //       setPremiumBaseData(proposerData);
      //       settingData(totalData);
      //     }
      //   });
      // } else {
      //   // getData();
      // }
      if(response?.data?.request?.opd_sum_insured < 25000){
        setPlanChoose("Plan A");
        setSiChoose(response?.data?.request?.opd_sum_insured)
      }
      else if (response?.data?.request?.opd_sum_insured >= 25000){
        setPlanChoose("Plan B");
        setSiChoose(response?.data?.request?.opd_sum_insured)


      }

      let getDataRes = await getData().then((getdata) => {
        if (getdata) {
          return getdata;
        }
      });

      console.log("getDataRes", getDataRes);


      // when we have no addon -------
      if (response.data.request.addons == "" || !response.data.request.addons) {
        let data = premiumDetails(
          response.data.response.premiumBreakup.totalPremium
        );

        let baseData = {
          grossTotalPremium: formatNumber(
            response.data.response.premiumBreakup.totalPremium
          ),
          grossGstAmount: data.gst,
          grossBaseAmount: data.grossAmount,
        };

        console.log("basedata", baseData);

        setPremiumBaseData(baseData);

        if (getDataRes) {
          let info = formatNumber(
            response.data.response.premiumBreakup.totalPremium
          );
          console.log("info", info);

          let datapayload = {
            ...getDataRes,
            grossTotalPremium: formatNumber(
              response.data.response.premiumBreakup.totalPremium
            ),
            grossGstAmount: data.gst,
            grossBaseAmount: data.grossAmount,
          };

          settingData(datapayload);
        } else {
          let info = formatNumber(
            response.data.response.premiumBreakup.totalPremium
          );

          console.log("info", info);

          let datapayload = {
            grossTotalPremium: formatNumber(
              response.data.response.premiumBreakup.totalPremium
            ),
            grossGstAmount: data.gst,
            grossBaseAmount: data.grossAmount,
          };
          settingData(datapayload);
        }


        // when we have addon ---------------
      } else if (response.data.request.addons) {
        if (getDataRes) {
          let baseData = {
            grossTotalPremium: getDataRes.grossTotalPremium,
            grossGstAmount: getDataRes.grossGstAmount,
            grossBaseAmount: getDataRes.grossBaseAmount,
          };

          setPremiumBaseData(baseData);
        }
      }

      if (response?.data?.request) {
        countMember(response);
      }

      if (response) {
        let maxage = getMaxAge(response?.data?.request?.InsuredDetail);
        setMaxAge(maxage);
      }
      setBeforeAddon(response.data.response);
      setApiResponse(response.data);
      // setApiData(response.data)
      setDeductibleVal(
        response.data.request.deductible
          ? response.data.request.deductible
          : 25000
      );
      setHdcVal(
        response.data.request.HDCRiderSI
          ? response.data.request.HDCRiderSI
          : 1000
      );
      setCoverVal(response.data.request.sum_insured);

      if (response.data.request.addons) {
        let currentAddons = response.data.request.addons
          ? response.data.request.addons.split(",")
          : [];

          console.log("currentaddons in fetch", currentAddons)
          setPreviousAddon([...currentAddons]);

        setAddonArr(currentAddons);
        setAddonPrev(currentAddons);
      }
      if (response.data.request.addonPrice) {
        setAddonDataList(response.data.request.addonPrice);
        setFinalAddonList(response.data.request.addonPrice);
        setAddonJson(response.data.request.addonPrice.addonlist)
      }


      if (response.data.request.tenure == "1 Year") {
        setOneData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "2 Year") {
        setTwoData(response.data.response.premiumBreakup.totalPremium);
      } else if (response.data.request.tenure == "3 Year") {
        setThreeData(response.data.response.premiumBreakup.totalPremium);
      }
    } catch (error) {
      console.log("the error in afterfetchplan", error);
      handleError("there is an error in fetchplan")
    } finally {
      setLoading(false);
    }
  };

  console.log("the apiresponse in main after", apiResponse);

  useEffect(() => {
    getData();
    fetchPlan();
    getAddon();
  }, []);

  const updatePerTenure = async (tenuredata) => {
    try {
      setLoading(true);

      let data = socketManager.isConnected();
      console.log("the data in isconnected", data);
    
    if(data){  let payload = {
        ...apiResponse.request,
        tenure: tenuredata,
        PlanCode: plan_id,
        CompanyName: c_id,
        // addons: "",
        addons:apiResponse.request.addons || "",
        // addonPrice: [],
        addonPrice:apiResponse.request.addonPrice || [],
        voluntarydeductiblelimit: apiResponse.request.voluntarydeductiblelimit || null,
        roomrent_type: apiResponse.request.roomrent_type || null,
        cdb_limit: apiResponse.request.cdb_limit || null,
        ped_waiting_period: apiResponse.request.ped_waiting_period || null,
        reductioninwaitingperiodlimit: apiResponse.request.reductioninwaitingperiodlimit || null,
        opd_sum_insured:apiResponse.request.opd_sum_insured || null,
        opd_plan:apiResponse.request.opd_plan || null,
        super_charger_option:apiResponse.request.super_charger_option || null,
        NetworkAdvantageApplicable : "no",
        HDCRiderSI: apiResponse.request.HDCRiderSI || 0,
        deductible: apiResponse.request.deductible || 0,
        // showAddonPrice : apiResponse?.request?.addons ? false : true,
        
      };
      const response = await updateQuickQuoteCompanywise(
        payload,
        unique_id
      ).then((response) => {
        let val = {
          "c_id" : c_id,
          "plan_id" : plan_id,
          "show_price" : apiResponse?.request?.addons ?false : true
        }
        setExtData(val);
        if(apiResponse.request.addons){
          setShowPriceData(false);
       

        }
        else{
          setShowPriceData(true)

        }
        setApiResponse((prevState) => ({
          ...prevState,
          request: response.data.request,
        }));

        setApiData({
          request: response.data.request,
          response: apiResponse.response,
        });

        if(plan_id != 2868 && c_id=="reliance"){
          setPedDeduct(
            response.data.request.ped_waiting_period &&
              response.data.request.ped_waiting_period != null
              ? response.data.request.ped_waiting_period
              : "12 Months"
          );
        }
        // setAddonJson([]);
        // setFinalAddonList([])

        // setAddonDataList([]);
        // setAddonPrev([]);
      });}
    } catch (error) {
      console.log("the error ", error);
      setStopLoading(true);
      handleError("Oops there is an error !");
    } finally {
      // setLoading(false); // Stop loading
    }
  };

  const updateCoverAmount = async (coverData) => {


    try {
      setLoading(true);
      let data = socketManager.isConnected();
      console.log("the data in isconnected", data);

      if(data){
        let payload = {
          ...apiResponse.request,
          sum_insured: coverData,
          PlanCode: plan_id,
          CompanyName: c_id,
          addons: apiResponse?.request?.addons || "",
          addonPrice: apiResponse?.request?.addonPrice || "",
          roomrent_type: apiResponse?.request?.roomrent_type || null,
          voluntarydeductiblelimit: apiResponse?.request?.voluntarydeductiblelimit || null,
          cdb_limit: apiResponse?.request?.cdb_limit || null,
          ped_waiting_period:  apiResponse?.request?.ped_waiting_period || null,
          reductioninwaitingperiodlimit: apiResponse?.request?.reductioninwaitingperiodlimit || null,
          opd_sum_insured:apiResponse?.request?.opd_sum_insured || null,
          opd_plan:apiResponse?.request?.opd_plan || null,
          super_charger_option:apiResponse?.request?.super_charger_option || null,
          NetworkAdvantageApplicable : "no",
          HDCRiderSI: apiResponse?.request?.HDCRiderSI || 0,
          deductible:  apiResponse?.request?.deductible || 0,
          // showAddonPrice : apiResponse?.request?.addons ? false : true,

          
        };
  
        let currentAddons = apiResponse.request.addons
          ? apiResponse.request.addons.split(",")
          : [];
        const response = await updateQuickQuoteCompanywise(
          payload,
          unique_id
        ).then((responseData) => {

          let val = {
            "c_id" : c_id,
            "plan_id" : plan_id,
            "show_price" : apiResponse?.request?.addons ? false : true
          }
          setExtData(val)
          setCoverVal(responseData.data.request.sum_insured);
  
          // setAddonDataList([]);
          // setAddonPrev([]);
        
          if(apiResponse.request.addons){
            setShowPriceData(false)
  
          }
          else{
            setShowPriceData(true)
  
          }
          
  
          setAddonArr(currentAddons);
          setApiResponse((prevState) => ({
            ...prevState,
            request: responseData.data.request,
          }));
  
          if(plan_id != 2868 && c_id=="reliance"){
            setPedDeduct(
              responseData.data.request.ped_waiting_period &&
                responseData.data.request.ped_waiting_period != null
                ? responseData.data.request.ped_waiting_period
                : "12 Months"
            );
          }
  
          setApiData({
            request: responseData.data.request,
            response: apiResponse.response,
          });
  
          // setAddonJson([]);
  
          // setFinalAddonList([])
        });
      }
  
  

      // if (response.data.response == null) {
      //   setLoading(true);
      //   handleError("Oops there isan error !");
      // }
      // setCoverVal(response.data.request.sum_insured);
      // let newData = {
      //   ...apiResponse,
      //   response: response.data.response,
      // };
      // setAddonDataList([])
      // setAddonPrev([])
      // setBeforeAddon(response.data.response)
      // // console.log("the newData", newData);
      // setApiResponse(response.data);
      // if (response.data.request.tenure == "1 Year") {
      //   setOneData(response.data.response.premiumBreakup.totalPremium);
      // } else if (response.data.request.tenure == "2 Year") {
      //   setTwoData(response.data.response.premiumBreakup.totalPremium);
      // } else if (response.data.request.tenure == "3 Year") {
      //   setThreeData(response.data.response.premiumBreakup.totalPremium);
      // }
    } catch (error) {
      console.log("error cover amount", error);
      setStopLoading(true);
      handleError("Oops there is an error !");
    } finally {
      // setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAddon = async () => {
    try {
      const response = await AddonApi(c_id, plan_id).then((response) => {
        // console.log("the response in addon", response);
        setAddonList(response.addon_data.data);
      });
    } catch (error) {
      //console.log("the error in addonlist");
      setStopLoading(true);
      handleError("Oops there is an error !");
    }
  };

  // const getPriceAddon = (responseValue, apidata) => {
  //   //console.log("the apiResponse addon in summary", responseValue);

  //   console.log("the new data", responseValue.premiumBreakup.totalPremium);

  //   //console.log("the prev data", beforeAddon.premiumBreakup.totalPremium);

  //   let prevData = beforeAddon.premiumBreakup.totalPremium;
  //   let newData = responseValue.premiumBreakup.totalPremium;
  //   // if (prevData.includes(",")) {
  //   //   prevData = prevData.replace(",", "");
  //   // }
  //   if (String(prevData).includes(",")) {
  //     prevData = String(prevData).replace(",", "");
  //   }
  //   // if (newData.includes(",")) {
  //   //   newData = newData.replace(",", "");
  //   // }
  //   if (String(newData).includes(",")) {
  //     newData = String(newData).replace(",", "");
  //   }

  //   let diff = Number(newData) - Number(prevData);

  //   //console.log("the difference", diff);

  //   let arr = addonDataList ? addonDataList : [];

  //   //console.log("the addonPrev", addonPrev);
  //   //console.log("addonArray data", addonArr);

  //   let addonlistArr = apidata.request.addons;
  //   let arrayData;
  //   let arrayPrevData;
  //   if (addonlistArr) {
  //     arrayData = addonlistArr.split(",");
  //   }

  //   if (addonPrev && Array.isArray(addonPrev) == false) {
  //     arrayPrevData = addonPrev.split(",");
  //   } else {
  //     arrayPrevData = addonPrev || [];
  //   }

  //   let newAddons;

  //   if (addonPrev) {
  //     newAddons = arrayData?.filter((addon) => !arrayPrevData.includes(addon));
  //   } else if (arrayPrevData?.length == 0 && arrayData?.length == 1) {
  //     newAddons = arrayData[0];
  //   }
  //   // else{
  //   //   return
  //   // }
  //   //console.log("the newAddons", newAddons);
  //   //console.log("addonlistArr :", addonlistArr);

  //   if (addonlistArr) {
  //     //console.log("the arrayData", arrayData);

  //     arrayData?.map((item, index) => {
  //       if (item == newAddons) {
  //         arr.push({
  //           addonName: item,
  //           amount: diff,
  //         });
  //       }
  //     });
  //   }

  //   //console.log("arr addon new list", arr);
  //   setAddonDataList(arr);

  //   //console.log("the beforeaddon in beforeaddon", beforeAddon);
  //   setBeforeAddon(responseValue);

  //   if (addonlistArr) {
  //     setAddonPrev(addonlistArr);
  //   }

  //   // setApiResponse({
  //   //   request:apidata.request,
  //   //   response: responseValue
  //   // });

  //   return arr;
  // };

  // const addNewAddon = (emitvalue, apivalue) => {
  //   console.log("apiResponse in addNewAddon", apiResponse);

  //   let prevData = beforeAddon.premiumBreakup.totalPremium;
  //   let newData = emitvalue.premiumBreakup.totalPremium;

  //   if (String(prevData).includes(",")) {
  //     prevData = String(prevData).replace(",", "");
  //   }

  //   if (String(newData).includes(",")) {
  //     newData = String(newData).replace(",", "");
  //   }

  //   let addonValueData = Number(newData) - Number(prevData);

  //   let totalAddonPriceList = addonDataList ? addonDataList : [];

  //   let currentAddonString = apiResponse.request.addons;

  //   if (currentAddonString == "") {
  //     setFinalAddonList({});
  //     setBeforeAddon(emitvalue);

  //     if (currentAddonString) {
  //       setAddonPrev(currentAddonString);
  //     }
  //     return {};
  //   }

  //   let currentAddonArray;

  //   let addonPrevArray;

  //   if (totalAddonPriceList) {
  //     currentAddonArray = currentAddonString.split(",");
  //   }
  //   if (addonPrev && Array.isArray(addonPrev) == false) {
  //     addonPrevArray = addonPrev.split(",");
  //   } else {
  //     addonPrevArray = addonPrev || [];
  //   }
  //   let newAddons;

  //   if (addonPrev) {
  //     newAddons = currentAddonArray?.filter(
  //       (addon) => !addonPrevArray.includes(addon)
  //     );
  //   } else if (addonPrevArray?.length == 0 && currentAddonArray?.length == 1) {
  //     newAddons = currentAddonArray[0];
  //   }
  //   console.log("new addon", newAddons);
  //   console.log("totalAddonlist", addonlist);

  //   let namedata;

  //   addonlist.map((item, index) => {
  //     if (item.code == newAddons[0]) {
  //       namedata = item.name;
  //     }
  //   });

  //   let gstdata = premiumDetails(addonValueData);

  //   console.log("gstdata", gstdata);

  //   let jsondata = {
  //     addonName: namedata,
  //     addonCode: Array.isArray(newAddons) ? newAddons[0] : newAddons,
  //     addonValue: addonValueData,
  //     gst: gstdata.gst,
  //     grossValue: gstdata.grossAmount,
  //   };

  //   console.log("jsondata", jsondata);

  //   if (Array.isArray(newAddons) ? newAddons.length != 0 : newAddons) {
  //     addonJson.push(jsondata);
  //   }

  //   console.log("addonJson", addonJson);

  //   let addonSetJson = {
  //     ...premiumBaseData,
  //     baseAmountWithAddon: finalAddonList?.grossAmountWithAddon
  //       ? finalAddonList?.grossAmountWithAddon + gstdata.grossAmount
  //       : premiumBaseData.grossBaseAmount + gstdata.grossAmount,
  //     gstAmountWithAddon: finalAddonList?.gstAmountData
  //       ? finalAddonList?.gstAmountData + gstdata.gst
  //       : premiumBaseData.grossGstAmount + gstdata.gst,
  //     totalPremiumWithAddon: newData,
  //     addonlist: addonJson,
  //   };

  //   console.log("premiumBaseData", premiumBaseData);
  //   console.log("final addon list", addonSetJson);

  //   if (Array.isArray(newAddons) ? newAddons.length != 0 : newAddons) {
  //     setFinalAddonList(addonSetJson);
  //   }
  //   setBeforeAddon(emitvalue);

  //   if (currentAddonString) {
  //     setAddonPrev(currentAddonString);
  //   }
  //   return addonSetJson;
  // };













  const addonData = (emitvalue, apiResponse) => {
    console.log("emitValue", emitvalue);
    console.log("apivalue", apiResponse);


    
    // console.log("dbResponse", dbResponse);

    let prevData = beforeAddon.premiumBreakup.totalPremium;
      let newData = emitvalue.premiumBreakup.totalPremium;
     
      if (String(prevData).includes(",")) {
        prevData = String(prevData).replace(",", "");
      }
   
      if (String(newData).includes(",")) {
        newData = String(newData).replace(",", "");
      }
  
      let diff = Number(newData) - Number(prevData);

      console.log("the difference here", diff);


      

      let currentAddonsArr = apiResponse.request.addons
          ? apiResponse.request.addons.split(",")
          : [];

      console.log("previousAddon", previousAddon);
      console.log("CurrentAddons", currentAddonsArr);

      let newAddon;

      if(previousAddon){
       newAddon = currentAddonsArr.filter(addon => !previousAddon.includes(addon));

        console.log("newAddon",newAddon); 
      }
      else{
        newAddon = apiResponse.request.addons.split(",")
      }


  console.log("addonList", addonlist);

  console.log("newAddon outer",newAddon); 

  let finaljson

  // console.log("addonobj",addonobj)
let arr = [];
  if(newAddon.length != 0){
    let addonitem = addonlist.filter(item => newAddon.includes(item.code))

    let addonobj = addonitem[0];
    console.log("addonobj", addonobj);

    let gstdata = premiumDetails(diff);

    console.log("gstdata", gstdata);

    let jsondata = {
      addonName: addonobj.name,
      addonCode: addonobj.code,
      addonValue: diff,
      gst: gstdata.gst,
      grossValue: gstdata.grossAmount,
      // addonValue: "",
      // gst: "",
      // grossValue: "",
    };

    console.log("the jsonData", jsondata);

    addonJson.push(jsondata);

    console.log("addonlistArray",addonlistArray)


    console.log("premiumbasedata", premiumBaseData);

    let finalgstbreakup = premiumDetails(emitvalue.premiumBreakup.totalPremium);

    console.log("finalgstbreakup",finalgstbreakup)

     finaljson = {
      // ...premiumBaseData,
      // baseAmountWithAddon: finalAddonList?.grossAmountWithAddon
      //   ? finalAddonList?.grossAmountWithAddon + gstdata.grossAmount
      //   : premiumBaseData.grossBaseAmount + gstdata.grossAmount,
      // gstAmountWithAddon: finalAddonList?.gstAmountData
      //   ? finalAddonList?.gstAmountData + gstdata.gst
      //   : premiumBaseData.grossGstAmount + gstdata.gst,
      // totalPremiumWithAddon: newData,
      // addonlist: addonJson,

      ...premiumBaseData,
      baseAmountWithAddon : finalgstbreakup.grossAmount,
      gstAmountWithAddon : finalgstbreakup.gst,
      totalPremiumWithAddon : emitvalue.premiumBreakup.totalPremium,
      addonlist : addonJson
    
    }

    console.log("the finaljson", finaljson)

    setFinalAddonList(finaljson)


    }
    // else{
      
    //   addonJson.push(apiResponse.request.addons.split(","))
    // }


  
  
  


    
      setPreviousAddon([...currentAddonsArr]);
      setBeforeAddon(emitvalue);

      setLoading(false);
      return finaljson
 
  };


//   GuaranteedSuperBonus
// InfiniteCare
// JumpStart
// RoomModifier

  const putAddon = async (addoncode) => {
    try {
      setLoading(true);

      let data = socketManager.isConnected();
      console.log("the data in isconnected", data);

      if(data){


      let currentAddons = apiResponse.request.addons
        ? apiResponse.request.addons.split(",")
        : [];

      // console.log("the current addons", currentAddons);

      if (!currentAddons.includes(addoncode)) {
        currentAddons.push(addoncode);
      }

      let updatedAddons = currentAddons.join(",");

      let payload = {};

      if (addoncode == "deductible") {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',
          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: apiResponse.request.HDCRiderSI
            ? apiResponse.request.HDCRiderSI
            : 0,
          deductible: Number(deductibleVal),
        };
      } else if (addoncode == "HDCRiderSI") {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: Number(hdcVal),
          deductible: apiResponse.request.deductible
            ? apiResponse.request.deductible
            : 0,
        };
      } 
      else if (addoncode == "AD05" && c_id == "reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          voluntarydeductiblelimit: relDeductible,
        };
      } else if (addoncode == "AD03" && c_id == "reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          roomrent_type: roomtype,
        };
      } else if (
        addoncode == "AD10" &&
        c_id == "reliance" &&
        ["28241", "28242", "28243"].includes(plan_id)
      ) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          voluntarydeductiblelimit: relDeductible,
        };
      } else if (
        addoncode == "AD11" &&
        c_id == "reliance" &&
        ["28241", "28242", "28243"].includes(plan_id)
      ) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          roomrent_type: roomtype,
        };
      } else if (addoncode == "AD07" && c_id == "reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          cdb_limit: hosDeductible,
        };
      } else if (addoncode == "AD08" && c_id == "reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          ped_waiting_period: pedDeduct,
        };
      } 
      else if (addoncode == "AD04" && c_id == "reliance" && plan_id == 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          reductioninwaitingperiodlimit: pedDeduct.split(" ")[0],
        };
      }
      else if (addoncode == "AD09" && c_id == "reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          reductioninwaitingperiodlimit: redIll,
        };
      } 
      else if (addoncode == "AD04" && c_id == "reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          opd_sum_insured: siChoose,
          opd_plan:planChoose
        };
      }
      else if (addoncode == "AD02" && c_id == "reliance" && plan_id != 2868) {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          super_charger_option:smartData
        };
      }
      else if(addoncode == "NetworkAdvantageApplicable" && c_id== 'icici' && plan_id == 1576){
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
          NetworkAdvantageApplicable : "yes"
        };
      }
      
      
      else {
        payload = {
          ...apiResponse.request,
          addons: updatedAddons,
          // addonPrice : addonPrice ? addonPrice : '',

          CompanyName: c_id,
          PlanCode: plan_id,
          HDCRiderSI: 0,
          deductible: 0,
        };
      }

      if(apiResponse.request.addons == ""){
        setShowPriceData(true)
      }

      await updateAddonApi(unique_id, payload).then((responseData) => {
        // if (responseData.response == null) {
        //   setStopLoading(true);
        // }

        // if (responseData.response == null) {
        //   // alert("error")
        //   handleError("Oops there is an error !");
        //   return
        // }

        

        setAddonArr(currentAddons);
        setApiResponse((prevState) => ({
          ...prevState,
          request: responseData.request,
        }));

        setApiData({
          request: responseData.request,
          response: apiResponse.response,
        });


        
        //console.log("apidata", apidata);
        // setApiResponse({
        //   request: responseData.request,
        //   response: apiResponse.response
        // })

        // console.log("the response in putaddon", responseData);

        // if (responseData.request.tenure == "1 Year") {
        //   setOneData(responseData.response.premiumBreakup.totalPremium);
        // } else if (responseData.request.tenure == "2 Year") {
        //   setTwoData(responseData.response.premiumBreakup.totalPremium);
        // } else if (responseData.request.tenure == "3 Year") {
        //   setThreeData(responseData.response.premiumBreakup.totalPremium);
        // }
        // if(responseData.response != null){
        // let addonPrice = getPriceAddon(responseData);

        //   let payloadData = {
        //     ...payload,
        //     addonPrice : addonPrice
        //   }
        //   await updateDatabase(unique_id , payloadData)
        // }
        // setLoading(false);
      });
    }
    } catch (error) {
      // console.log("Error in putAddon function:", error);
      setStopLoading(true);
      handleError("Oops there is an error in putaddon !");
    }
  };

  useEffect(() => {
    socketManager.subscribe("message", async (data) => {
      const { company_name, plan_code, emit_data } = data;
      // console.log("----------------------called subscriber------------------");
      // console.log("company-name", company_name);
      // console.log("plan-code", plan_code);
      console.log("socket: emit-data in AfterPlanFetch useEffect, ", emit_data);

      let emitval;

      if (emit_data && apiResponse?.request) {
        if (emit_data[c_id]) {
          emitval = emit_data[c_id][0];

          if (apiResponse.request.tenure == "1 Year") {
            setOneData(emitval.premiumBreakup.totalPremium);
          } else if (apiResponse.request.tenure == "2 Year") {
            setTwoData(emitval.premiumBreakup.totalPremium);
          } else if (apiResponse.request.tenure == "3 Year") {
            setThreeData(emitval.premiumBreakup.totalPremium);
          }
          // setApiResponse((prevState) => ({
          //   ...prevState,
          //   response: emitval
          // }));

          let payloadData;
          if (emitval != null) {
            // let addonPrice = getPriceAddon(emitval, apidata);

            // let datanew = addNewAddon(emitval, apiResponse);
            let datanew = [];
            

            console.log("the apiresponse in addon", apiResponse)

            let addonInfo = addonData(emitval, apiResponse);
            console.log("addonInfo", addonInfo);

            if(addonInfo){
              payloadData = {
                ...apiResponse.request,
                addonPrice: addonInfo,
                deductible : Number(deductibleVal),
                HDCRiderSI: Number(hdcVal)

              };
            }
            else{
              let finalgstbreakup = premiumDetails(emitval.premiumBreakup.totalPremium);
              let data = {
                ...premiumBaseData,
                baseAmountWithAddon : finalgstbreakup.grossAmount,
                gstAmountWithAddon : finalgstbreakup.gst,
                totalPremiumWithAddon : emitval.premiumBreakup.totalPremium,
              }

            //   let addon_price = {
            //     "grossTotalPremium": "",
            //     "grossGstAmount": "",
            //     "grossBaseAmount": "",
            //     "baseAmountWithAddon": 20777.12,
            //     "gstAmountWithAddon": 3739.88,
            //     "totalPremiumWithAddon": 24517,
            //     "addonlist": [
            //         {
            //             "addonName": "Deductible",
            //             "addonCode": "deductible",
            //             "addonValue": -1487,
            //             "gst": -226.83,
            //             "grossValue": -1260.17
            //         },
            //         {
            //             "addonName": "HDC India",
            //             "addonCode": "HDCRiderSI",
            //             "addonValue": 1100,
            //             "gst": 167.8,
            //             "grossValue": 932.2
            //         }
            //     ]
            // }
              
              payloadData = {
                ...apiResponse.request,
               
              };

              let currentAddonsArr = apiResponse.request.addons
              ? apiResponse.request.addons.split(",")
              : [];

          
              setFinalAddonList(apiResponse.request.addonPrice);
              setPreviousAddon([...currentAddonsArr]);
              setBeforeAddon(emitval);
            }

            console.log("the payloaddata here", payloadData)
            let data = await updateDatabase(unique_id, payloadData);

            console.log("the data api", data);

            if (data?.message == "Request updated") {
              setLoading(false);
            }
          }
          setApiResponse({
            request: payloadData,
            response: emitval,
          });
          setLoading(false);
        }
      } else {
        return;
      }
    });
  }, [apiResponse]);

  // useEffect(() => {
  //   // // const socket = getSocket();
  //   // socket?.on("message", async (company_name, plan_code, emit_data) => {
  //   //   alert(JSON.stringify(emit_data));
  //   //   console.log("company-name", company_name);
  //   //   console.log("plan-code", plan_code);
  //   //   console.log("emit-data", emit_data);
  //   // });

  //   console.log("socketdata in after", socket);

  //   alert(socket);
  // }, []);

  // useEffect(() => {
  //   if (apiResponse && !apiResponse.response && !stopLoading) {
  //     setStopLoading(true);
  //     handleError("Oops, there is an error.");
  //   }
  // }, [apiResponse, stopLoading]);

console.log("premiumbaseddata", premiumBaseData)


  if (!apiResponse && !stopLoading) {
    return (
<>

<Redirect />

<div className="container">
  <div className="row">
  <div className="col-lg-8 ">

<div className="sklitonfetch">
<Skeleton variant="circular" animation="wave"  className="SklitionAfterFetchimages" />
<Skeleton variant="rectangular" animation="wave" className="animationdivs1"/>
<Skeleton variant="rectangular" animation="wave" className="animationdivs2"/>
<Skeleton variant="rectangular" animation="wave" className="animationdivs3"/>
</div>
<div className="addonssklitions">
  <div className="firstrowsklition">

<Skeleton variant="circular" animation="wave"  className="addonslogoSklitions" />
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonText"/>

  </div>
<div className="sendonrowsklition">
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription1"/>
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription2"/>
</div>
</div>
<div className="addonssklitions">
  <div className="firstrowsklition">

<Skeleton variant="circular" animation="wave"  className="addonslogoSklitions" />
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonText"/>

  </div>
<div className="sendonrowsklition">
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription1"/>
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription2"/>
</div>
</div>
<div className="addonssklitions">
  <div className="firstrowsklition">

<Skeleton variant="circular" animation="wave"  className="addonslogoSklitions" />
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonText"/>

  </div>
<div className="sendonrowsklition">
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription1"/>
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription2"/>
</div>
</div>
<div className="addonssklitions">
  <div className="firstrowsklition">

<Skeleton variant="circular" animation="wave"  className="addonslogoSklitions" />
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonText"/>

  </div>
<div className="sendonrowsklition">
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription1"/>
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription2"/>
</div>
</div>
<div className="addonssklitions">
  <div className="firstrowsklition">

<Skeleton variant="circular" animation="wave"  className="addonslogoSklitions" />
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonText"/>

  </div>
<div className="sendonrowsklition">
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription1"/>
<Skeleton variant="rectangular" animation="wave" className="tanureTermSkalitonTextdescription2"/>
</div>
</div>
  </div>
  <div className="col-lg-4">
<div className="summerySklition">
  <div className="headerSklition">
  <Skeleton variant="rectangular" animation="wave" className="summeryheadtext"/>
  </div>
  <div className="summerysklitonbody">
  <Skeleton variant="circular" animation="wave"  className="summeryAfterFetchimages" />
  <Skeleton variant="rectangular" animation="wave" className="priceandtanureSummerySkalition"/>
  </div>
  <div className="sklitionbody2">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText1"/>

  </div>

  <div className="summeryfooterbody">
  <Skeleton variant="rectangular" animation="wave" className="skalitionSummeryText3"/>

  </div>
</div>
  </div>
  </div>
  </div>
  </>
    );
  }

  console.log("showPriceData",showPriceData)

  return (
    <>


    
    
    
    {loading && <Loader/>}
      <Helmet>
        <title>Healthinsurance fetchplans</title>

        {/* <meta name="description" content="A brief description of your page for SEO." /> */}
        <meta
          name="keywords"
          content="Insurance Plan, Insurance Quotes, buy insurance"
        />
      </Helmet>

      {loading && <Loader />}

      {stopLoading == false && (
        //  maxAge &&
        <>
          <Redirect />{" "}
          <div className="container">
            <div className="row">
              <div className="col-lg-8 ">
                <div className="nextfatchtop ">
                  <div className="row text-center py-0">
                    <div className="col align-self-center">
                      {/* {console.log(
                        "apiResponse in afterFetchPlan",
                        apiResponse
                      )} */}
                      <div className="nextimg ">
                        <img
                          src={
                            apiResponse.response.insurerLogo ||
                            apiResponse.request.insurerLogo
                          }
                          alt="Image"
                          height={60}
                          width={70}
                        />
                      </div>
                    </div>
                    <div className="col align-self-center nexcont">
                      <div className="nexthead text-left">
                        <h1 className="nfh1 afterlogo">
                          {apiResponse.response.planName}
                          <br />
                          {/* <span
                          className="viewalloffers"
                          style={{
                            fontSize: 13,
                            top: 12,
                            marginLeft: "-10px",
                            padding: 7,
                            borderRadius: 5,
                          }}
                        >
                          view all features{" "}
                          <i
                            className="fa fa-chevron-right faaroc10px"
                            aria-hidden="true"
                          />
                        </span> */}
                        </h1>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="nexthead ">
                        <p className="nfh1 cash">
                          <i className="fa fa-building" aria-hidden="true" />{" "}
                          <span className="hospitalnum">
                            {" "}
                            {c_id == "ICICI"
                              ? "9,700"
                              : c_id == "care"
                              ? "10,632"
                              : c_id == "GoDigit"
                              ? "16,334"
                              : ""}
                          </span>
                          Cashless hospitals in India
                        </p>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div className="nextcover text-center">
                        <p className="nfh1 colortexdesktop">Cover Amount</p>

                        {/* <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={coverVal}
                            onChange={(e) => {
                              updateCoverAmount(e.target.value);
                            }}
                            label="Age"
                          >
                            {CoverAmountHandelChnage.map((data)=>{
                              return(
                                <MenuItem value={data}>{data} lac</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl> */}

                        <FormControl sx={{ m: 1, minWidth: "60%" }}>
                          <Select
                            value={coverVal}
                            onChange={(e) => {
                              updateCoverAmount(e.target.value);
                            }}
                            sx={{
                              boxShadow: "none",
                              height: "20px", // Adjust height
                              padding: "0px", // Adjust padding
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                            }}
                          >
                            {CoverAmountHandelChnage.map((data, i) => {
                              return (
                                <MenuItem value={data} key={i}>
                                  {/* {data} lac */}
                                  {formatAmount(data)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nextfatchtopmobile">
                  <div className="blockdivmobilefetchplane">
                    <div className="nextfatchtopmobileplanename">
                      <div className="nexthead text-left">
                        <h1 className="nfh1 afterlogo">
                          {apiResponse.response.planName}
                        </h1>
                      </div>
                    </div>
                    <div className="flexdisplayafterfetchplane">
                      <div className="nextfatchtopmobileimg">
                        {/* {console.log(
                          "apiResponse in afterFetchPlan",
                          apiResponse
                        )} */}

                        <img
                          src={
                            apiResponse.response.insurerLogo ||
                            apiResponse.request.insurerLogo
                          }
                          alt="Image"
                          height={60}
                          width={70}
                        />
                      </div>
                      <div className="col align-self-center">
                        <div className="nexthead ">
                          <p className="nfh1 cash">
                            <i className="fa fa-building" aria-hidden="true" />{" "}
                            <span className="hospitalnum">
                              {" "}
                              {c_id == "ICICI"
                                ? "9,700"
                                : c_id == "care"
                                ? "10,632"
                                : c_id == "GoDigit"
                                ? "16,334"
                                : ""}
                            </span>
                            Cashless hospitals in India
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flexdisplayafterfetchplane">
                      <div className="col mobilecoverafterfetch">
                        <div className="nextcover text-center">
                          <p className="nfh1 colortex">Cover Amount</p>

                          <FormControl sx={{ m: 1, minWidth: "60%" }}>
                            <Select
                              value={coverVal}
                              onChange={(e) => {
                                updateCoverAmount(e.target.value);
                              }}
                              sx={{
                                boxShadow: "none",
                                height: "20px", // Adjust height
                                padding: "0px", // Adjust padding
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                              }}
                            >
                              {CoverAmountHandelChnage.map((data) => {
                                return (
                                  <MenuItem value={data}>{data} lac</MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nextsection">
                  <h1 className="nfh1 colortex">Policy and Period</h1>
                  {/* <p className="para">
                  Easy EMI options starting from â‚¹2,454/month.{" "}
                  <span className="after-view">View Details</span>
                </p> */}

                  {maxAge < 96 ? (
                    <>
                      <div className="row text-center py-0">
                        <div className="col align-self-center">
                          <div
                            className={`form-group form-check ppriod ${
                              apiResponse.request.tenure === "1 Year"
                                ? "oneshowdata"
                                : ""
                            }`}
                            onClick={() => {
                              updatePerTenure("1 Year");
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked=""
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              <strong className="afterplan-strong">
                                {" "}
                                1 Year {oneData ? formatToINR(oneData) : ""}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col align-self-center">
                          <div
                            className={`form-group form-check ppriod ${
                              apiResponse.request.tenure === "2 Year"
                                ? "oneshowdata"
                                : ""
                            }`}
                            onClick={() => {
                              updatePerTenure("2 Year");
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              <strong className="afterplan-strong">
                                {" "}
                                <>
                                  2 Year {twoData ? formatToINR(twoData) : ""}
                                </>
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col align-self-center">
                          <div
                            className={`form-group form-check ppriod ${
                              apiResponse.request.tenure === "3 Year"
                                ? "oneshowdata"
                                : ""
                            }`}
                            onClick={() => {
                              updatePerTenure("3 Year");
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              style={{ visibility: "hidden" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              <strong className="afterplan-strong">
                                {" "}
                                3 Year {threeData ? formatToINR(threeData) : ""}
                              </strong>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {apiResponse.response.insurerName == "hdfc" && (
                  <div className="nextsection ">
                    {addonlist &&
                      addonlist.map((element) => {
                        if (element.code == "deductible") {
                          return maxAge < element.age ? null : (
                            <div className="deductible-section">
                              <h1 className="nfh1 colortex">
                                Deductible Discount
                              </h1>
                              <p className="para">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry
                              </p>
                              <div className="reccard" key={element.code}>
                                <div className="row text-center py-0 reccardline">
                                  <div className="col align-self-center reccard-img">
                                    <div className="nextimg">
                                      <img
                                        src={apiResponse.response.insurerLogo}
                                        alt="Image"
                                        className="recimg"
                                      />
                                    </div>
                                  </div>
                                  <div className="col align-self-center">
                                    <div
                                      className="nexthead"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h1 className="nfh1 addonname">
                                        {element.name}
                                      </h1>
                                      {element.code === "deductible" && (
                                        <div
                                          className="discriptionse"
                                          style={{ marginRight: "-23px" }}
                                        >
                                          <span className="insurtext">
                                            {/* {loading && <Loader />} */}

                                            {/* <select
                                              onChange={(e) => {
                                                setDeductibleVal(
                                                  e.target.value
                                                );
                                              }}
                                              value={deductibleVal}
                                            >
                                              <option value="25000">
                                                25000
                                              </option>
                                              <option value="50000">
                                                50000
                                              </option>
                                              <option value="100000">
                                                100000
                                              </option>
                                            </select> */}

                                            <FormControl
                                              variant="standard"
                                              sx={{ minWidth: "100%" }}
                                            >
                                              <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                onChange={(e) => {
                                                  setDeductibleVal(
                                                    e.target.value
                                                  );
                                                }}
                                                value={deductibleVal}
                                              >
                                                <MenuItem value={25000}>
                                                  25000
                                                </MenuItem>
                                                <MenuItem value={50000}>
                                                  50000
                                                </MenuItem>
                                                <MenuItem value={100000}>
                                                  100000
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col align-self-center">
                                    {finalAddonList?.addonlist &&
                                    finalAddonList.addonlist.some(
                                      (data) => data.addonCode == element.code
                                    ) ? (
                                      <button className="recbtn">Added</button>
                                    ) : (
                                      <button
                                        className="recbtn"
                                        onClick={() => {
                                          putAddon(element.code);
                                        }}
                                      >
                                        <FaPlus
                                          style={{
                                            marginTop: "-2px",
                                            height: "13px",
                                          }}
                                        />
                                        Add
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="addon-extra-hdfc">
                                  <p className="reccard-des">
                                    Description:
                                    <span className="des-span">
                                      {element.description}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
                <div className="nextsection ">
                  <h1 className="nfh1 colortex">Recomended Add-Ons</h1>
                  {/* <p className="para">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry
                </p> */}

                  {console.log("the addonDataList in after", addonDataList)}

                  {addonlist &&
                    addonlist.map((item, index) => {
                      if (
                        item.code != "deductible" &&
                        item.code != "HDCRiderSI" &&
                        c_id != "reliance"
                      ) {
                        //console.log("item", item);
                        // alert(maxAge)
                        return maxAge < item.age ||
                          item.code === "WBS1146" ||
                          item.code === "AACS1147" ||
                          // (item.code == "AD03" &&
                          //   apiResponse.request.tenure != "3 Year") ||
                          (item.code == "1" && plan_id == "28241") ||
                          (item.code == "2" && plan_id == "28242") ||
                          (item.code == "3" && plan_id == "28243") ? null : (
                          <div className="reccard">
                            <div className="reccardline">
                              <div className="col reccard-img">
                                <div className="nextimg">
                                  <img
                                    src={apiResponse.response.insurerLogo}
                                    alt="Image"
                                    className="recimg"
                                  />
                                </div>
                              </div>
                              <div className="col textaddonname">
                                <div className="nexthead">
                                  <h1 className="nfh1 addonname">
                                    {item.name}
                                  </h1>
                                </div>
                              </div>
                              <div className="col buttonaddon">
                                {/* {console.log(
                                  "addonArr new addonDataList",
                                  addonDataList
                                )} */}
                                {finalAddonList?.addonlist &&
                                finalAddonList?.addonlist.some(
                                  (data) => data.addonCode == item.code
                                ) ? (
                                  <button className="recbtn" disabled={true}>
                                    Added
                                  </button>
                                ) : (
                                  <button
                                    className="recbtn"
                                    onClick={() => {
                                      putAddon(item.code);
                                    }}
                                  >
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth={0}
                                      viewBox="0 0 448 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ marginTop: "-2px", height: 13 }}
                                    >
                                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="reccardlinemobile">
                              <div className="col textaddonnamemobile">
                                <div className="nexthead">
                                  <h1 className="nfh1 addonname">
                                    {item.name}
                                  </h1>
                                </div>
                              </div>

                              <div className="mobileflexaddonsection">
                                <div className="col reccard-imgmobile">
                                  <div className="nextimg">
                                    <img
                                      src={apiResponse.response.insurerLogo}
                                      alt="Image"
                                      className="recimg"
                                    />
                                  </div>
                                </div>

                                <div className="col buttonaddonmobile">
                                  {finalAddonList?.addonlist &&
                                  finalAddonList?.addonlist.some(
                                    (data) => data.addonCode == item.code
                                  ) ? (
                                    <button className="recbtn" disabled={true}>
                                      Added
                                    </button>
                                  ) : (
                                    <button
                                      className="recbtn"
                                      onClick={() => {
                                        putAddon(item.code);
                                      }}
                                    >
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 448 512"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                          marginTop: "-2px",
                                          height: 13,
                                        }}
                                      >
                                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                      </svg>
                                      Add
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="addon-extra-hdfc">
                              <p className="reccard-des">
                                Description:{" "}
                                <span className="des-span">
                                  {item.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}

                  {addonlist &&
                    addonlist.map((item, index) => {
                      if (
                        item.code != "deductible" &&
                        item.code != "HDCRiderSI" &&
                        c_id == "reliance"
                      ) {
                        //console.log("item", item);

                        return maxAge < item.age ||
                          (item.code == "AD06" && maxAge < 60) ||
                          // (item.code == "AD03" &&
                          //   apiResponse.request.tenure != "3 Year") ||
                          (item.code == "1" && plan_id == "28241") ||
                          (item.code == "2" && plan_id == "28242") ||
                          (item.code == "3" && plan_id == "28243") ? null : (
                          <div className="reccard">
                            <div className="row text-center py-0 reccardline">
                              <div className="col align-self-center reccard-img">
                                <div className="nextimg">
                                  <img
                                    src={apiResponse.response.insurerLogo}
                                    alt="Image"
                                    className="recimg"
                                  />
                                </div>
                              </div>

                              <div className="col nexthead">
                                <h1 className="nfh1 addonname">{item.name}</h1>
                              </div>

                              { 
                                  
                                  item.code == "AD08" &&  plan_id != 2868 && c_id == "reliance" && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setPedDeduct(e.target.value);
                                        }}
                                        value={pedDeduct}
                                      >
                                        <MenuItem value={"12 Months"}>
                                          12 Months
                                        </MenuItem>
                                        <MenuItem value={"24 Months"}>
                                          24 Months
                                        </MenuItem>
                                        {/* <MenuItem value={"48 Months"}>
                                          48 Months
                                        </MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  )}


                                { 
                                 item.code == "AD04" && plan_id == 2868 &&
                                  
                                  c_id == "reliance" && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setPedDeduct(e.target.value);
                                        }}
                                        value={pedDeduct}
                                      >
                                        <MenuItem value={"12 Months"}>
                                          12 Months
                                        </MenuItem>
                                        <MenuItem value={"24 Months"}>
                                          24 Months
                                        </MenuItem>
                                        {/* <MenuItem value={"48 Months"}>
                                          48 Months
                                        </MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  )}
                                    
                                  {/* ------------------------------ */}
                                {
                                  item.code == "AD10" &&
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setRelDeductible(e.target.value);
                                        }}
                                        value={relDeductible}
                                      >
                                        <MenuItem value={10000}>10000</MenuItem>
                                        <MenuItem value={25000}>25000</MenuItem>
                                        <MenuItem value={50000}>50000</MenuItem>
                                        <MenuItem value={100000}>
                                          100000
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}

                                  {/* ------------------------------ */}
                                {item.code == "AD11" && plan_id != 2868 && (
                                  <FormControl
                                    variant="standard"
                                    sx={{
                                      minWidth: "118px",
                                      // marginTop: "15px",
                                      marginLeft: "9px",
                                      position: "relative",
                                      right: "0px",
                                    }}
                                  >
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      onChange={(e) => {
                                        setRoomType(e.target.value);
                                      }}
                                      value={roomtype}
                                    >
                                      <MenuItem value={"Twin Sharing"}>
                                        Twin Sharing
                                      </MenuItem>
                                      <MenuItem
                                        value={"Single AC Private Room"}
                                      >
                                        Single AC Private Room
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                )}

                                  {/* ------------------------------ */}

                                { item.code == "AD03" && plan_id == 2868 && (
                                  <FormControl
                                    variant="standard"
                                    sx={{
                                      minWidth: "118px",
                                      // marginTop: "15px",
                                      marginLeft: "9px",
                                      position: "relative",
                                      right: "0px",
                                    }}
                                  >
                                    {/* <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                onChange={(e) => {
                                                  setRoomType(
                                                    e.target.value
                                                  );
                                                }}
                                                value={roomtype}
                                              >
                                                {
                                                  apiResponse.request.sum_insured == (3 || 5) &&
                                                  <MenuItem value={"Single AC Private Room"}>
                                                Single AC Private Room
                                                </MenuItem>}


                                               {apiResponse.request.sum_insured ==(3 || 5 ||10 ||15 || 20) && <MenuItem value={"Twin Sharing"}>
                                                Twin Sharing	
                                                </MenuItem>}
                                               {apiResponse.request.sum_insured ==(3 || 5 ||10 ||15 || 20 || 25 ||30 ||50||100) && 
                                               <MenuItem value={"Actuals"}>
                                                Actuals
                                                </MenuItem>}
                                              </Select> */}

                                    {apiResponse &&
                                      ([3, 5].includes(
                                        apiResponse.request.sum_insured
                                      ) ? (
                                        <Select
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          onChange={(e) => {
                                            setRoomType(e.target.value);
                                          }}
                                          value={roomtype}
                                        >
                                          <MenuItem value={"Twin Sharing"}>
                                            Twin Sharing
                                          </MenuItem>
                                        </Select>
                                      ) : [10, 15, 20].includes(
                                          apiResponse.request.sum_insured
                                        ) ? (
                                        <Select
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          onChange={(e) => {
                                            setRoomType(e.target.value);
                                          }}
                                          value={roomtype}
                                        >
                                          <MenuItem value={"Twin Sharing"}>
                                            Twin Sharing
                                          </MenuItem>
                                          <MenuItem value={"Actuals"}>
                                            Actuals
                                          </MenuItem>
                                        </Select>
                                      ) : [25, 30, 50, 100].includes(
                                          apiResponse.request.sum_insured
                                        ) ? (
                                        <Select
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          onChange={(e) => {
                                            setRoomType(e.target.value);
                                          }}
                                          value={roomtype}
                                        >
                                          <MenuItem
                                            value={"Single AC Private Room"}
                                          >
                                            Single AC Private Room
                                          </MenuItem>
                                        </Select>
                                      ) : null)}
                                  </FormControl>
                                )}

                                {/* {item.name == "Hospital Cash Limit" && */}
                                {item.code == "AD07"&&
                                  plan_id == 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setHosDeductible(e.target.value);
                                        }}
                                        value={hosDeductible}
                                      >
                                        <MenuItem value={1000}>1000</MenuItem>
                                        <MenuItem value={1500}>1500</MenuItem>
                                        <MenuItem value={2000}>2000</MenuItem>
                                        <MenuItem value={2500}>2500</MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}

                                { item.code== "AD09"&&
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setRedIll(e.target.value);
                                        }}
                                        value={redIll}
                                      >
                                        <MenuItem value={"6 Months"}>
                                          6 Months
                                        </MenuItem>
                                        <MenuItem value={"12 Months"}>
                                          12 Months
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}

                                {item.code == "AD04" && 
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                     {
                                      apiResponse.request.sum_insured < 10 && 
                                      <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      onChange={(e) => {
                                        setPlanChoose(e.target.value);
                                        if(e.target.value == "Plan A" ){
                                          setSiChoose(10000)

                                        }
                                        else if(e.target.value == "Plan B"){
                                          setSiChoose(25000)

                                        }
                                      }}
                                      value={planChoose}
                                    >
                                      <MenuItem value={"select plan"}>
                                      select plan
                                      </MenuItem>
                                      <MenuItem value={"Plan A"}>
                                       Plan A
                                      </MenuItem>
                                      <MenuItem value={"Plan B"}>
                                        Plan B
                                      </MenuItem>
                                    </Select>
                                     }
                                     {
                                      apiResponse.request.sum_insured >= 10 && 
                                      <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      onChange={(e) => {
                                        setPlanChoose(e.target.value);
                                        if(e.target.value == "Plan A" ){
                                          setSiChoose(10000)

                                        }
                                        else if(e.target.value == "Plan B"){
                                          setSiChoose(25000)

                                        }
                                      }}
                                      value={planChoose}
                                    >
                                      <MenuItem value={"select plan"}>
                                      select plan
                                      </MenuItem>
                                      <MenuItem value={"Plan A"}>
                                       Plan A
                                      </MenuItem>
                                      <MenuItem value={"Plan B"}>
                                        Plan B
                                      </MenuItem>
                                    </Select>
                                     }
                                     
                                    </FormControl>
                                  )} 

                              {item.code == "AD04" && 
                                  plan_id != 2868 && planChoose != "select plan" && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                       {
                                        planChoose == "Plan A" &&
                                        <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setSiChoose(e.target.value);
                                        }}
                                        value={siChoose}
                                      >
                                        <MenuItem value={10000}>
                                         10000
                                        </MenuItem>
                                        <MenuItem value={20000}>
                                          20000
                                        </MenuItem>
                                      </Select>
                                      }
                                      {
                                        planChoose == "Plan B" && 
                                        <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setSiChoose(e.target.value);
                                        }}
                                        value={siChoose}
                                      >
                                        
                                        <MenuItem value={25000}>
                                          25000
                                        </MenuItem>
                                        <MenuItem value={50000}>
                                          50000
                                        </MenuItem>
                                      </Select>
                                      }
                                     
                                    </FormControl>
                                  )} 

                                {item.code == "AD02" &&
                                  plan_id != 2868 && (
                                    <FormControl
                                      variant="standard"
                                      sx={{
                                        minWidth: "118px",
                                        // marginTop: "15px",
                                        marginLeft: "9px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                    >
                                     
                                        <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        onChange={(e) => {
                                          setSmartData(e.target.value);
                                        }}
                                        value={smartData}
                                      >
                                        <MenuItem value={"Option 1"}>
                                          Option 1
                                        </MenuItem>
                                        <MenuItem value={"Option 2"}>
                                          Option 2
                                        </MenuItem>
                                      </Select>
                                  
                                    
                                     
                                    </FormControl>
                                  )} 

                              <div className="col align-self-center">
                                {finalAddonList?.addonlist &&
                                finalAddonList?.addonlist.some(
                                  (data) => data.addonCode == item.code
                                ) ? (
                                  <button className="recbtn" disabled={true}>
                                    Added
                                  </button>
                                ) : (
                                  <button
                                    className="recbtn"
                                    onClick={() => {

                                      if(c_id == "reliance" && plan_id != 2868 ){
                                        if( item.code == "AD04" &&
                                          planChoose != "select plan"  ){
                                            putAddon(item.code);
        
                                          }
                                         else if(item.code != "AD04" ){
                                            putAddon(item.code);
        
        
                                         }
                                      }
                                      else{
                                        putAddon(item.code);

                                      }
                                      // putAddon(item.code);
                                       
                                    
                                  
                             
                                    }}
                                  >
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth={0}
                                      viewBox="0 0 448 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ marginTop: "-2px", height: 13 }}
                                    >
                                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="addon-extra-hdfc">
                              <p className="reccard-des">
                                Description:{" "}
                                <span className="des-span">
                                  {item.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}

                  {addonlist &&
                    addonlist.map((item, index) => {
                      if (item.code == "HDCRiderSI" && showhdc == true) {
                        return maxAge < item.age ? null : (
                          <div className="reccard" key={item.code}>
                            <div className="row text-center py-0 reccardline">
                              <div className="col align-self-center reccard-img">
                                <div className="nextimg">
                                  <img
                                    src={apiResponse.response.insurerLogo}
                                    alt="Image"
                                    className="recimg"
                                  />
                                </div>
                              </div>
                              <div className="col align-self-center">
                                <div
                                  className="nexthead"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h1 className="nfh1 addonname">
                                    {item.name}
                                  </h1>
                                  {item.code === "HDCRiderSI" && (
                                    <div
                                      className="discriptionse"
                                      style={{ marginRight: "-23px" }}
                                    >
                                      <span className="insurtext">
                                        {/* {loading && <Loader />} */}

                                        {/* <select
                                      onChange={(e) => {
                                        setHdcVal(e.target.value);
                                      }}
                                      value={hdcVal}
                                    >
                                      <option value="1000">1000</option>
                                      <option value="2000">2000</option>
                                    </select> */}

                                        <FormControl
                                          variant="standard"
                                          sx={{ minWidth: "100%" }}
                                        >
                                          <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            onChange={(e) => {
                                              setHdcVal(e.target.value);
                                            }}
                                            value={hdcVal}
                                          >
                                            <MenuItem value={1000}>
                                              1000
                                            </MenuItem>
                                            <MenuItem value={2000}>
                                              2000
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col align-self-center">
                                {console.log("addonArr new", addonArr)}
                                {finalAddonList?.addonlist &&
                                finalAddonList?.addonlist.some(
                                  (data) => data.addonCode == item.code
                                ) ? (
                                  <button className="recbtn">Added</button>
                                ) : (
                                  <button
                                    className="recbtn"
                                    onClick={() => {
                                      putAddon(item.code);
                                    }}
                                  >
                                    <FaPlus
                                      style={{
                                        marginTop: "-2px",
                                        height: "13px",
                                      }}
                                    />
                                    Add
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="addon-extra-hdfc">
                              <p className="reccard-des">
                                Description:{" "}
                                <span className="des-span">
                                  {item.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="col-lg-4">
                <Summary
                  nextPage="proposal"
                  setOneData={setOneData}
                  setTwoData={setTwoData}
                  setThreeData={setThreeData}
                  planInfo={apiResponse}
                  addonsArray={addonArr}
                  setAddonArr={setAddonArr}
                  setApiResponse={setApiResponse}
                  apiResponse={apiResponse}
                  setBeforeAddon={setBeforeAddon}
                  addonPrev={addonPrev}
                  setAddonPrev={setAddonPrev}
                  addonlist={addonlist}
                  addondataList={addonDataList}
                  setAddonDataList={setAddonDataList}
                  setApiData={setApiData}
                  apidata={apidata}
                  socketManager={socketManager}
                  loading={loading}
                  setLoading={setLoading}
                  finalAddonList={finalAddonList}
                  setFinalAddonList={setFinalAddonList}
                  setAddonJson={setAddonJson}
                  proposerDataAfter = {proposerDataAfter}
                  setPreviousAddon={setPreviousAddon}
                  previousAddon={previousAddon}
                  addonJson={addonJson}
                  settingDataAfter={settingData}
                  extData={extData}
                  setExtData={setExtData}

               
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Afterplanfatch;
